import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Chart from "react-apexcharts";
import { Button } from "@material-ui/core";
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import { translations } from '../../../translate.js';
let Strings = localStorage.getItem('LANG') == 1 ? translations.Ar : translations.En;
var today = new Date();
var currentDate = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);

var date = new Date();
var today = new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000));
var dateSevenBefore = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalCost: '',
            marginTotal: '',
            bestSellerCard: '',
            leastSealingCard: '',
            error: '',
            categoryList: [],
            cardList: [],
            selectedCategory: [],
            selectedCard: [],
            cardClassList: [],
            selectedCardClass: [],
            distPosList: [],
            selectedDistPos: [],
            selectedDistributor: [],
            distributorList: [],
            fromDate: dateSevenBefore,
            toDate: currentDate,
            numberOfDays: 7,
            view_1: {
                series: [],
                xaxis: {
                    categories: [],
                },
                colors: ['#f7b84b', '#49691f'],
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    dashArray: [0, 20],
                    width: [4, 4],
                },
                legend: {
                    tooltipHoverFormatter: function (val, opts) {
                        return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                    }
                },
                markers: {
                    size: 3,
                    hover: {
                        sizeOffset: 6
                    }
                },
                tooltip: {
                    y: [
                        {
                            title: {
                                formatter: function (val) {
                                    return val
                                }
                            }
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val
                                }
                            }
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                }
                            }
                        }
                    ]
                },
                grid: {
                    borderColor: '#f1f1f1',
                },
            },

            view_2: {
                series: [],
                labels: [],
                colors: ['#006600', '#8c9966', '#4d9933', '#00664d', '#669966', '#4d6600', '#669980'],
                chart: {
                    type: 'donut',
                    width: 600,
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270
                    }
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'gradient',
                },
                legend: {
                    formatter: function (val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex]
                    },
                    position: 'bottom'
                },
                title: {
                    text: '',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }

        }
    }
    handleRefresh = () => {
        // clear all filter data
        this.setState({
            totalCost: '',
            marginTotal: '',
            bestSellerCard: '',
            leastSealingCard: '',
            selectedCategory: [],
            selectedCard: [],
            selectedCardClass: [],
            selectedDistPos: [],
            selectedDistributor: [],
            numberOfDays: 7,
            fromDate: dateSevenBefore,
            toDate: currentDate,
            error: '',
        }, function () {
            this.getCostAndMarginChartPos()
            this.getCardsTotalPerWeekPos()
        });
    }
    componentDidMount() {
        if (localStorage.getItem('PERM_TYPE') == 2) {
            this.getCostAndMarginChart()
            this.getCardCategory();

            // this.getCardsTotalPerWeek()
        } else {
            this.getCardCategory();
            this.getCostAndMarginChartPos()
            this.getCardsTotalPerWeekPos()
            if (localStorage.getItem('USER_LEVEL') == 1) {
                this.getAllDist()
            } else {
                this.getDistPos();
            }
        }
    }
    getAllDist = () => {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllDist',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'getType': 'all'
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({ distributorList: data['data'] })
                }
            }
        })
    }
    getDistPos = () => {
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDistPos',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'distId': localStorage.getItem('USER_LEVEL') == 1 ? this.state.selectedDistributor.map(data => data.userId) : "",
                'token': localStorage.getItem('USER_TOKEN'),
                'allPos': '2',
                'withoutCount': '0',
                'getType': 'all'
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    this.setState({ distPosList: data['data'] })
                }
            }
        })

    }
    getCardCategory = () => {
        var type = localStorage.getItem('USER_LEVEL') == 1 ? 'getCardCategory' : "getCategories";
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': type,
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    var res = localStorage.getItem('USER_LEVEL') == 1 ? data['data'] : data;
                    this.setState({ categoryList: res });
                }
            }
        })
    }
    getCards = () => {
        if (this.state.selectedCategory.length > 0) {
            var type = localStorage.getItem("USER_LEVEL") == 1 ? "getCardsAdmin" : "getCards"
            fetch(window.API_PERM_USER, {
                method: 'POST',
                body: JSON.stringify({
                    'type': type,
                    'catId': this.state.selectedCategory.map(data => data.id),
                    'lang': localStorage.getItem('LANG'),
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                }),
                headers: {
                    'Accept': 'application/json',
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (data !== "Nothing found") {
                    if (data['logout'] == 1) {
                        localStorage.setItem('USER_ID', '');
                        localStorage.setItem('USER_TOKEN', '');
                        window.location.href = "/logout";
                    } else {
                        var res = localStorage.getItem('USER_LEVEL') == 1 ? data['data'] : data;
                        this.setState({ cardList: res });
                    }
                }
            })
        }
    }
    getCardClassDirectCharge = () => {
        if (this.state.selectedCard.length > 0) {
            var type = localStorage.getItem('USER_LEVEL') == 1 ? 'getCardClassDirectCharge' : "get_dist_card_class";
            fetch(window.API_PERM_USER, {
                method: 'POST',
                body: JSON.stringify({
                    'type': type,
                    'cardId': this.state.selectedCard.map(data => data.cardId),
                    'lang': localStorage.getItem('LANG'),
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                }),
                headers: {
                    'Accept': 'application/json',
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (data !== "Nothing found") {
                    if (data['logout'] == 1) {
                        localStorage.setItem('USER_ID', '');
                        localStorage.setItem('USER_TOKEN', '');
                        window.location.href = "/logout";
                    } else {
                        this.setState({ cardClassList: data['data'] });
                    }
                }
            })
        }
    }
    getCostAndMarginChartPos() {

        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCostAndProfitCardReportForDistPos',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'dateFrom': this.state.fromDate,
                'dateTo': this.state.toDate,
                'distId': localStorage.getItem('USER_LEVEL') == 1 ? this.state.selectedDistributor.map(data => data.userId) : '',
                'posId': this.state.selectedDistPos.map(data => data.userId),
                'cardId': this.state.selectedCard.map(data => data.cardId),
                'catId': this.state.selectedCategory.map(data => data.id),
                'classId': this.state.selectedCardClass.map(data => data.classId),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'undefined') {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {

                    var categories = []
                    var margin = []
                    var cost = []
                    var indexCost = localStorage.getItem("USER_LEVEL") == 1 ? "costPriceForAdmin" : "costPriceForDist"
                    Object.keys(data['data']).map((info, index) => {
                        margin.push(parseFloat(data['data'][index]['profit']))
                        cost.push(parseFloat(data['data'][index][indexCost]))
                        categories.push(data['data'][index]['entryDate'])
                    })
                    var marginTotal2 = localStorage.getItem("USER_LEVEL") == 1 ? data["totals"]['profit2'] : 0
                    this.setState({
                        totalCost: data["totals"][indexCost],
                        marginTotal: data["totals"]['profit'],
                        marginTotal2: marginTotal2,
                        view_1: {
                            xaxis: {
                                categories: categories,
                            },
                            series: [{
                                name: Strings['Margin'],
                                data: margin,
                            },
                            {
                                name: Strings['Cost'],
                                data: cost
                            }],
                        }
                    })
                }
            }
        });
    }
    getCardsTotalPerWeekPos = () => {
        // console.log("getCardsTotalPerWeekPos");
        // console.log(this.state.fromDate);
        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'TopFiveSellingCardsForDist',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'dateFrom': this.state.fromDate,
                'dateTo': this.state.toDate,
                'distId': localStorage.getItem('USER_LEVEL') == 1 ? this.state.selectedDistributor.map(data => data.userId) : '',
                'posId': this.state.selectedDistPos.map(data => data.userId),
                'cardId': this.state.selectedCard.map(data => data.cardId),
                'catId': this.state.selectedCategory.map(data => data.id),
                'classId': this.state.selectedCardClass.map(data => data.classId),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'undefined') {
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {
                    var categories = []
                    var counter = []
                    Object.keys(data['data']).map((info, index) => {
                        counter.push(parseInt(data['data'][info]['counter']))
                        categories.push(data['data'][info]['name'])
                    })

                    this.setState({
                        bestSellerCard: Object.keys(data['max']).length > 0 ? data['max']['name'] : '-',
                        leastSealingCard: Object.keys(data['min']).length > 0 ? data['min']['name'] : '-',
                        view_2: {
                            series: counter,
                            labels: categories
                        }
                    })
                }
            }
        });


    }
    getCostAndMarginChart() {

        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCostAndProfitCardReport',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'undefined') {
                // console.log('===============================');
                // console.log(data);
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {

                    var categories = []
                    var margin = []
                    var cost = []
                    Object.keys(data['priceData']).map((info, index) => {
                        margin.push(parseFloat(data['priceData'][info]['data']['distPrice']))
                        cost.push(parseFloat(data['priceData'][info]['data']['costVal']))
                        categories.push(data['priceData'][info]['date'])
                    })

                    this.setState({
                        totalCost: data['totalCost'],
                        marginTotal: data['totalSellPrice'],
                        view_1: {
                            xaxis: {
                                categories: categories,
                            },
                            series: [{
                                name: Strings['Margin'],
                                data: margin,
                            },
                            {
                                name: Strings['Cost'],
                                data: cost
                            }],
                        }
                    })
                }
            }
        });
    }
    getCardsTotalPerWeek() {

        fetch(window.API_PERM_USER, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCardCountReport',
                'lang': localStorage.getItem('LANG'),
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'undefined') {
                // console.log('===============================');
                // console.log(data);
                if (data['logout'] == 1) {
                    localStorage.setItem('USER_ID', '');
                    localStorage.setItem('USER_TOKEN', '');
                    window.location.href = "/logout";
                } else {

                    var categories = []
                    var counter = []
                    Object.keys(data['data']).map((info, index) => {
                        counter.push(parseInt(data['data'][info]['counter']))
                        categories.push(data['data'][info]['nameAr'])
                    })

                    this.setState({
                        bestSellerCard: Object.keys(data['max']).length > 0 ? data['max']['nameAr'] : '-',
                        leastSealingCard: Object.keys(data['min']).length > 0 ? data['min']['nameAr'] : '-',
                        view_2: {
                            series: counter,
                            labels: categories
                        }
                    })
                }
            }
        });


    }
    handleChangeSelect = (type, option) => {
        this.setState(state => {
            return {
                [type]: option
            }
        }, function () {
            if (type == 'selectedCategory') {
                this.setState({
                    selectedCard: [],
                    selectedCardClass: [],
                    cardList: [],
                    cardClassList: []
                }, function () {
                    this.getCards()
                    this.getCostAndMarginChartPos()
                    this.getCardsTotalPerWeekPos()
                })
            } else if (type == 'selectedCard') {
                this.setState({
                    selectedCardClass: [],
                    cardClassList: []
                }, function () {
                    this.getCardClassDirectCharge()
                    this.getCostAndMarginChartPos()
                    this.getCardsTotalPerWeekPos()
                })
            } else if (type == 'selectedDistributor') {
                this.setState({
                    selectedDistPos: [],
                    distPosList: []
                }, function () {
                    this.getDistPos()
                    this.getCostAndMarginChartPos()
                    this.getCardsTotalPerWeekPos()
                })
            } else {
                this.getCostAndMarginChartPos()
                this.getCardsTotalPerWeekPos()
            }



        })

    }
    checkValidationDate(name, value) {
        Difference_In_Days = 0;
        if (name == "fromDate") {
            var date1 = new Date(value);
            var date2 = new Date(this.state.toDate);
            // To calculate the time difference of two dates
            var Difference_In_Time = date2.getTime() - date1.getTime();

            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        }
        if (name == "toDate") {
            var date1 = new Date(this.state.fromDate);
            var date2 = new Date(value);
            // To calculate the time difference of two dates
            var Difference_In_Time = date2.getTime() - date1.getTime();

            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        }
        if (Difference_In_Days <= 30 && Difference_In_Days > 0) {
            this.setState({ numberOfDays: Difference_In_Days })
        }
        return Difference_In_Days > 30 ? 0 : 1;
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if (this.checkValidationDate(name, value)) {
            this.setState({
                [name]: value,
                error: ''

            }, function () {
                this.getCostAndMarginChartPos()
                this.getCardsTotalPerWeekPos()
            })
        } else {
            this.setState({
                "error": 1
            })
        }
    }
    displayFilter() {
        return (<Row className='page-filter-view'>
            <Col xl={{ size: 5, order: 1 }} lg={{ size: 7, order: 1, }} md={{ size: 7, order: 1, }} sm={{ size: 10, order: 1, }} xs={{ size: 12, order: 12, }}>
                <AvForm ref={c => (this.form = c)} >
                    <Row className='filter-form'>
                        {localStorage.getItem("USER_LEVEL") == 1 &&
                            <Col lg={10} md={10} sm={10} xs={12}>
                                <div className='member-searchable-list mb-2'>
                                    <Picky
                                        value={this.state.selectedDistributor}
                                        options={this.state.distributorList}
                                        onChange={this.handleChangeSelect.bind(this, 'selectedDistributor')}
                                        open={false}
                                        valueKey="userId"
                                        labelKey='nameFullReact'
                                        multiple={true}
                                        includeSelectAll={true}
                                        includeFilter={true}
                                        dropdownHeight={200}
                                        placeholder={Strings['Distributor Name']}
                                        filterPlaceholder={Strings['Select Distributor Name']}
                                        // className={this.state.msgError == 1 ? 'border border-danger rounded' : ''}
                                        selectAllText={Strings["Select All"]}
                                        allSelectedPlaceholder={Strings["%s selected"]}
                                        manySelectedPlaceholder={Strings["%s selected"]}
                                        clearFilterOnClose={true}

                                    />

                                </div>
                            </Col>
                        }
                        {localStorage.getItem("USER_LEVEL") == 1 &&
                            <Col xs={12} lg={2} md={2} sm={2}>
                                <Button variant="contained" type="button" className='refresh-action-btn mb-2 mb-md-0' onClick={this.handleRefresh} title={Strings['Refresh']}>
                                    <i className='flaticon-refresh reset-filter-btn'></i>
                                </Button>
                            </Col>
                        }
                       {localStorage.getItem('PERM_TYPE')!=2&& <Col lg={6} md={6} sm={6} xs={12}>
                            <div className='member-searchable-list mb-2'>
                                <Picky
                                    value={this.state.selectedDistPos}
                                    options={this.state.distPosList}
                                    onChange={this.handleChangeSelect.bind(this, 'selectedDistPos')}
                                    open={false}
                                    valueKey="userId"
                                    labelKey={localStorage.getItem("USER_LEVEL") == 1 ? 'name' : "nameFullReact"}
                                    multiple={true}
                                    includeSelectAll={true}
                                    includeFilter={true}
                                    dropdownHeight={200}
                                    placeholder={Strings['Select POS']}
                                    filterPlaceholder={Strings['Select POS']}
                                    selectAllText={Strings["Select All"]}
                                    allSelectedPlaceholder={Strings["%s selected"]}
                                    manySelectedPlaceholder={Strings["%s selected"]}
                                    clearFilterOnClose={true}
                                />
                            </div>
                        </Col>}
                        <Col lg={localStorage.getItem("USER_LEVEL") == 1 ? 6:localStorage.getItem('PERM_TYPE')==5?4: 10} md={localStorage.getItem("USER_LEVEL") == 1 ? 6 :localStorage.getItem('PERM_TYPE')==5?4: 10} sm={localStorage.getItem("USER_LEVEL") == 1 ? 6:localStorage.getItem('PERM_TYPE')==5?4 : 10} xs={12}>
                            <div className='member-searchable-list mb-2'>
                                <Picky
                                    value={this.state.selectedCategory}
                                    options={this.state.categoryList}
                                    onChange={this.handleChangeSelect.bind(this, 'selectedCategory')}
                                    open={false}
                                    valueKey="id"
                                    labelKey={localStorage.getItem('LANG') == 1 ? 'name' : 'name_en'}
                                    multiple={true}
                                    includeSelectAll={true}
                                    includeFilter={true}
                                    dropdownHeight={200}
                                    placeholder={Strings['CategoryMain']}
                                    filterPlaceholder={Strings['Select Category']}
                                    selectAllText={Strings["Select All"]}
                                    allSelectedPlaceholder={Strings["%s selected"]}
                                    manySelectedPlaceholder={Strings["%s selected"]}
                                    clearFilterOnClose={true}
                                />
                            </div>
                        </Col>
                        {localStorage.getItem("USER_LEVEL") != 1 &&
                            <Col xs={12} lg={2} md={2} sm={2}>
                                <Button variant="contained" type="button" className='refresh-action-btn mb-2 mb-md-0' onClick={this.handleRefresh} title={Strings['Refresh']}>
                                    <i className='flaticon-refresh reset-filter-btn'></i>
                                </Button>
                            </Col>
                        }
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <div className='member-searchable-list mb-2'>
                                <Picky
                                    value={this.state.selectedCard}
                                    options={this.state.cardList}
                                    onChange={this.handleChangeSelect.bind(this, 'selectedCard')}
                                    open={false}
                                    valueKey="cardId"
                                    labelKey={localStorage.getItem('LANG') == 1 ? 'name' : 'name_en'}
                                    multiple={true}
                                    includeSelectAll={true}
                                    includeFilter={true}
                                    dropdownHeight={200}
                                    placeholder={Strings['Select Card']}
                                    filterPlaceholder={Strings['Select Card']}
                                    selectAllText={Strings["Select All"]}
                                    allSelectedPlaceholder={Strings["%s selected"]}
                                    manySelectedPlaceholder={Strings["%s selected"]}
                                    clearFilterOnClose={true}
                                />
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <div className='member-searchable-list mb-2'>
                                <Picky
                                    value={this.state.selectedCardClass}
                                    options={this.state.cardClassList}
                                    onChange={this.handleChangeSelect.bind(this, 'selectedCardClass')}
                                    open={false}
                                    valueKey={localStorage.getItem('USER_LEVEL') == 1 ? "id" : "classId"}
                                    labelKey={localStorage.getItem('USER_LEVEL') == 1 ?
                                        (localStorage.getItem('LANG') == 1 ? 'name' : 'name_en') :
                                        (localStorage.getItem('LANG') == 1 ? 'cardClassName' : 'cardClassName_en')}
                                    multiple={true}
                                    includeSelectAll={true}
                                    includeFilter={true}
                                    dropdownHeight={200}
                                    placeholder={Strings['Select Class']}
                                    filterPlaceholder={Strings['Select Class']}
                                    selectAllText={Strings["Select All"]}
                                    allSelectedPlaceholder={Strings["%s selected"]}
                                    manySelectedPlaceholder={Strings["%s selected"]}
                                    clearFilterOnClose={true}
                                />
                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={6} xs={12}>
                            <AvField name="fromDate" type='date' label={Strings['From Date']} value={this.state.fromDate} onChange={this.handleInputChange} />
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <AvField name="toDate" type='date' label={Strings['To Date']} value={this.state.toDate} onChange={this.handleInputChange} />
                        </Col>
                    </Row>
                </AvForm>
                {this.state.error == 1 &&
                    <div className='msg-error'>{Strings["Sorry, you can't search for more than 30 days"]}</div>
                }
            </Col>
            <Col xl={{ size: 7, order: 1 }} lg={{ size: 5, order: 12, }} md={{ size: 5, order: 12, }} sm={{ size: 2, order: 12, }} xs={{ size: 12, order: 1, }} className='text-right mb-3 '>

            </Col>
        </Row>)
    }

    render() {
        return (
            <div>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className='main-color-1 inv-class-view-title mb-4'>
                        <div>{Strings['Dashboard']}</div>
                        <hr className='inv-hr-view-2' />
                    </Col>
                </Row>

                {(localStorage.getItem('PERM_TYPE') == 5 || localStorage.getItem('USER_LEVEL') == 1|| localStorage.getItem('PERM_TYPE') == 2) && this.displayFilter()}

                <Row className='mt-3 mb-4'>
                    <Col lg={9} md={9} sm={12} xs={12} >
                        <div className='report-chart-box'>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className='main-color-1 font-w-600 f-15 mb-3'>
                                    {localStorage.getItem('PERM_TYPE') == 2 && Strings['Total cost and margin within 7 days']}
                                    {(localStorage.getItem('PERM_TYPE') == 5 || localStorage.getItem('USER_LEVEL') == 1) && Strings['Total cost and margin within ']}
                                    {(localStorage.getItem('PERM_TYPE') == 5 || localStorage.getItem('USER_LEVEL') == 1) && this.state.numberOfDays}
                                    {(localStorage.getItem('PERM_TYPE') == 5 || localStorage.getItem('USER_LEVEL') == 1) && Strings['days']}
                                </div>
                            </Col>
                            <Chart
                                options={this.state.view_1}
                                series={this.state.view_1.series}
                                type="line"
                                height={400}
                            />
                        </div>
                    </Col>
                    <Col lg={3} md={3} sm={12} xs={12} className={localStorage.getItem("USER_LEVEL") == 1 ? 'report-total-main-view-admin' : 'report-total-main-view'}>
                        <div className='report-total-box'>
                            <div className='report-total-box-color-view'></div>
                            <div className='report-total-box-white-view'></div>
                            <div className='report-total-box-data-view'>
                                <div className='font-w-600 f-16'>{Strings['Cost Total']}</div>
                                <div className='main-color-1 font-w-600 f-20 pt-2'>{this.state.totalCost}</div>
                            </div>
                        </div>

                        <div className='report-total-box mt-4'>
                            <div className='report-total-box-color-2-view'></div>
                            <div className='report-total-box-white-view'></div>
                            <div className='report-total-box-data-view'>
                                <div className='font-w-600 f-16'>{Strings['Margin Total']}</div>
                                <div className='main-color-2 font-w-600 f-20 pt-2'>{this.state.marginTotal}</div>
                            </div>
                        </div>
                        {localStorage.getItem("USER_LEVEL") == 1 &&
                            <div className='report-total-box mt-4'>
                                <div className='report-total-box-color-2-view'></div>
                                <div className='report-total-box-white-view'></div>
                                <div className='report-total-box-data-view'>
                                    <div className='font-w-600 f-16'>{Strings['Margin Total'] + " " + 2}</div>
                                    <div className='main-color-2 font-w-600 f-20 pt-2'>{this.state.marginTotal2}</div>
                                </div>
                            </div>
                        }
                    </Col>
                </Row>

                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <hr className='filter-hr hr-dashboard-view' />
                    </Col>
                </Row>

                <Row className='mt-3 mb-4'>
                    <Col lg={3} md={3} sm={12} xs={12}>
                        <div className='report-count-main-view'>
                            <div className='chart-2-max-view mb-3'>
                                <div>{Strings['Best seller']}</div>
                                <div className='main-color-1 font-w-600 f-17'>{this.state.bestSellerCard}</div>
                            </div>

                            <div className='chart-2-min-view'>
                                <div>{Strings['Least selling']}</div>
                                <div className='main-color-2 font-w-600 f-17'>{this.state.leastSealingCard}</div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={9} md={9} sm={12} xs={12}>
                        <div className='report-chart-box report-bar-chart-box'>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className='main-color-1 font-w-600 f-15 mb-3'>
                                    {localStorage.getItem('PERM_TYPE') == 2 && Strings['The best selling cards within 7 days']}
                                    {(localStorage.getItem('PERM_TYPE') == 5 || localStorage.getItem('USER_LEVEL') == 1) && Strings['The best selling cards within ']}
                                    {(localStorage.getItem('PERM_TYPE') == 5 || localStorage.getItem('USER_LEVEL') == 1) && this.state.numberOfDays}
                                    {(localStorage.getItem('PERM_TYPE') == 5 || localStorage.getItem('USER_LEVEL') == 1) && Strings['days']}
                                </div>
                            </Col>
                            <Chart
                                options={this.state.view_2}
                                series={this.state.view_2.series}
                                type="pie"
                                height={400}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Dashboard;