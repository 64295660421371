import React, { Component } from "react";
import { translations } from "../../../translate.js";
import {
  UncontrolledDropdown,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
} from "reactstrap";
import { Button, Drawer } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Pagination from "react-js-pagination";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import SearchIcon from "@material-ui/icons/Search";
import { toAbsoluteUrl } from "../../../_metronic/index.js";
import { Link } from "react-router-dom";
import FormatLineSpacingIcon from "@material-ui/icons/FormatLineSpacing";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import AddBillForm from "./forms/AddBillForm.jsx";
import SendPayRequestForm from "../PayManagement/forms/SendPayRequestForm.jsx";
import BillDetails from "./forms/BillDetails.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "rc-tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
let today = new Date();
let currentDate =
  today.getFullYear() +
  "-" +
  ("0" + (today.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + today.getDate()).slice(-2);

export default class PosBillsRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      loadingSave: 0,
      limit: 10,
      activePage: 1,
      dataCount: 0,
      errorMsg: "",
      fromDate: currentDate,
      toDate: currentDate,
      data: [],
      distributorList: [],
      distId: [],
      subervisorId: [],
      posId: [],
      billState: "",
      billType: "",
      billTypes: [],
      BillStates: [],
      debitDateFrom: currentDate,
      debitDateTo: currentDate,
      lastPayDateFrom: currentDate,
      lastPayDateTo: currentDate,
      openleftModal: false,
      modal: false,
      posCode: "",
      posCodeError: "",
      modalData: null,
      serialNumber: "",
    };
  }
  openSidebarModal = () => {
    this.setState({ openleftModal: !this.state.openleftModal });
  };
  closeSidebarModal = () => {
    this.setState({ openleftModal: false }, () => {
      this.handleSearch();
    });
  };
  openSidebarModal2 = (modalData = null) => {
    this.setState({ openModal: !this.state.openModal, modalData });
  };
  closeSidebarModal2 = () => {
    this.setState({ openModal: false, modalData: null }, () => {});
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        activePage: 1,
      },
      function() {
        if (name == "filterMode") {
          this.handleRefresh();
        }
      }
    );
  };
  cancelBill = (event, id, balance = false) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>
              {balance
                ? Strings["Do u want to also remove the pos balance?"]
                : Strings["You want to delete this ?"]}
            </p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2 mb-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn ml-2 mr-2 mb-2"
              onClick={() => {
                this.cancelBillHandler(id, balance ? 1 : undefined);
                onClose();
              }}
            >
              {balance ? Strings["Cancel and remove POS balance"] : Strings.Yes}
            </button>
            {balance && (
              <button
                type="button"
                className="btn form-save-btn mb-2"
                onClick={() => {
                  this.cancelBillHandler(id, 2);
                  onClose();
                }}
              >
                {Strings["Cancel but keep POS balance"]}
              </button>
            )}
          </div>
        );
      },
    });
  };
  rejectBill = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to reject this ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.rejectBillHandler(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };
  confirmBill = (event, modalData) => {
    event.preventDefault();
    this.setState({ modal: true, modalData });
  };
  errorModal = (msg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{msg}</h5>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
          </div>
        );
      },
    });
  };
  confirmBill2 = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-Succsess-icon">!</span>
            </div>
            <h5>{Strings["Are you sure ?"]}</h5>
            <p>{Strings["You want to confirm this ?"]}</p>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
            <button
              type="button"
              className="btn form-save-btn"
              onClick={() => {
                this.confirmBillHandler2(id);
                onClose();
              }}
            >
              {Strings.Yes}
            </button>
          </div>
        );
      },
    });
  };
  confirmBillHandler = async (billId) => {
    this.setState({
      loadingSave: 1,
      posCodeError: "",
      modalData: null,
    });
    try {
      let response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "confirmBillRequest",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          billId,
        }),
        headers: {
          Accept: "application/json",
        },
      });
      let res = await response.json();
      if (res.logout == 1) {
        localStorage.setItem("USER_ID", "");
        localStorage.setItem("USER_TOKEN", "");
        window.location.href = "/logout";
      }
      if (res.error == 1) {
        this.errorModal(res["errorMsg"]);
      } else {
        this.handleSearch();
        this.props.getSupervisorBalance();
        this.errorModal(
          res["errorMsg"].split("\\n").join("\n") || Strings.doneSucessfully
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ openHeader: false, modal: false, loadingSave: 0 });
    }
  };
  confirmBillHandler2 = async (billId) => {
    try {
      let response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "closeInsuranceBill",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          billId,
        }),
        headers: {
          Accept: "application/json",
        },
      });
      let res = await response.json();
      if (res.logout == 1) {
        localStorage.setItem("USER_ID", "");
        localStorage.setItem("USER_TOKEN", "");
        window.location.href = "/logout";
      }
      this.handleSearch();
      this.props.getSupervisorBalance();
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ openHeader: false });
    }
  };
  rejectBillHandler = async (billId) => {
    try {
      let response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "rejectBillRequest",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          billId,
        }),
        headers: {
          Accept: "application/json",
        },
      });
      let res = await response.json();
      if (res.logout == 1) {
        localStorage.setItem("USER_ID", "");
        localStorage.setItem("USER_TOKEN", "");
        window.location.href = "/logout";
      }
      if (res.error == 1) {
        this.errorModal(res["errorMsg"]);
      } else {
        this.handleSearch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ openHeader: false });
    }
  };
  cancelBillHandler = async (billId, withBalance = undefined) => {
    try {
      this.setState({ loading: 1 });
      let response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "deleteBills",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          billId,
          withBalance,
        }),
        headers: {
          Accept: "application/json",
        },
      });
      let res = await response.json();
      if (res.logout == 1) {
        localStorage.setItem("USER_ID", "");
        localStorage.setItem("USER_TOKEN", "");
        window.location.href = "/logout";
      }
      this.noteModal(res["errorMsg"]);
      this.setState({ loading: 0 }, () => {
        this.handleSearch();
      });
    } catch (error) {
      this.setState({ loading: 0 });
      console.log(error);
    } finally {
      this.setState({ openHeader: false });
    }
  };
  handleChangeSelect = (type, option) => {
    console.log(option);
    this.setState(
      (state) => {
        return {
          [type]: option,
          activePage: 1,
          errorMsg: 0,
        };
      },
      () => {
        if (localStorage.getItem("USER_LEVEL") == 1 && type == "distId") {
          this.getPos();
          this.getSuperVisors();
        }
      }
    );
  };
  noteModal = (msg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3"></div>
            <h5>{msg}</h5>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
          </div>
        );
      },
    });
  };
  handleRefresh = () => {
    this.setState(
      {
        loading: 0,
        loadingSave: 0,
        limit: 25,
        activePage: 1,
        dataCount: 0,
        errorMsg: "",
        fromDate: currentDate,
        toDate: currentDate,
        data: [],
        distributorList: [],
        distId: [],
        subervisorId: [],
        posId: [],
        billState: "",
        billType: "",
        billTypes: [],
        BillStates: [],
        debitDateFrom: currentDate,
        debitDateTo: currentDate,
        lastPayDateFrom: currentDate,
        lastPayDateTo: currentDate,
        openleftModal: false,
        modal: false,
        posCode: "",
        posCodeError: "",
        modalData: null,
        serialNumber: "",
      },
      function() {
        this.getBillsType();
        this.getPos();
        this.getBillsState();
        if (
          localStorage.getItem("USER_LEVEL") == 1 ||
          localStorage.getItem("PERM_TYPE") == 5
        ) {
          this.getSuperVisors();
        }
        if (localStorage.getItem("USER_LEVEL") == 1) {
          this.getAllDist();
        }
        this.handleSearch();
      }
    );
  };
  openHeaderMenu = (event, id) => {
    event.preventDefault();
    if (
      event.target.classList[1] != undefined &&
      event.target.classList[1] == "menu-dots-vertical" &&
      id != undefined
    ) {
      var val = "";
      if (this.state.openHeader == id) {
        val = "";
      } else if (id != undefined) {
        val = id;
      }
      this.setState({
        openHeader: val,
      });
    } else if (
      id == undefined &&
      event.target.classList[1] != "menu-dots-vertical"
    ) {
      this.setState({
        openHeader: "",
      });
    }
  };
  getBillsType() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getBillsType",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              billTypes: data["billsType"],
            });
          }
        }
      });
  }
  getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  getMinDate = (POS_DAYS_NUM) => {
    const today = new Date();
    // Adjust the date by subtracting POS_DAYS_NUM days
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - POS_DAYS_NUM);

    // Ensure proper formatting
    const pastYear = pastDate.getFullYear();
    const pastMonth = String(pastDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const pastDay = String(pastDate.getDate()).padStart(2, "0");

    return `${pastYear}-${pastMonth}-${pastDay}`;
  };
  handleSearch = async () => {
    try {
      this.setState({ loading: 1 });
      const body = JSON.stringify({
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        type: "getBillsInfo",
        serialNumber: this.state.serialNumber,
        distId:
          localStorage.getItem("PERM_TYPE") == 5
            ? [localStorage.getItem("USER_ID")]
            : this.state.distId
            ? this.state.distId.map((e) => e.userId)
            : [],
        subervisorId: this.state.subervisorId
          ? this.state.subervisorId.map((e) =>
              localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("PERM_TYPE") == 5
                ? e.userId
                : e.permId
            )
          : [],
        posId: this.state.posId
          ? this.state.posId.map((e) =>
              localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("PERM_TYPE") == 5
                ? e.userId
                : e.permId
            )
          : [],
        billState: this.state.billState,
        billType: this.props.balance || this.state.billType,
        debitDateFrom: this.state.debitDateFrom,
        debitDateTo: this.state.debitDateTo,
        lastPayDateFrom: this.state.lastPayDateFrom,
        lastPayDateTo: this.state.lastPayDateTo,
        page: this.state.activePage,
        limit: this.state.limit,
        export: 0,
        pageName: this.props.balance ? "balanceReq" : "bills",
      });
      const response = await fetch(window.API_PERM_USER, {
        body,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data.error == 1) {
            this.setState({ errorMsg: data["errorMsg"] });
          } else if (data.error == 0) {
            this.setState({
              data: data["bills"],
              dataCount: data.totalCount,
              status: data.status,
              openBills: data.openBills,
            });
          }
        }
      }
    } catch (error) {
      this.setState({ errorMsg: error.message, data: [], activePage: 1 });
    } finally {
      this.setState({ loading: 0 });
    }
  };
  componentDidMount() {
    console.log(this.props.balance, "balancnec");

    this.getBillsType();
    this.getPos();
    this.getBillsState();
    if (
      localStorage.getItem("USER_LEVEL") == 1 ||
      localStorage.getItem("PERM_TYPE") == 5
    ) {
      this.getSuperVisors();
    }
    if (localStorage.getItem("USER_LEVEL") == 1) {
      this.getAllDist();
    }
    this.handleSearch();
  }
  getAllDist = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getAllDist",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        getType: "all",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ distributorList: data["data"] }, function() {});
          }
        }
      });
  };
  getPos() {
    if (
      localStorage.getItem("USER_LEVEL") == 1 ||
      localStorage.getItem("PERM_TYPE") == 5
    ) {
      if (
        this.state.distId?.length == 0 &&
        localStorage.getItem("USER_LEVEL") == 1
      )
        return;
      else {
        fetch(window.API_PERM_USER, {
          method: "POST",
          body: JSON.stringify({
            type: "getDistPos",
            lang: localStorage.getItem("LANG"),
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            getType: "all",
            accountType: 7,
            distId: this.state.distId
              ? this.state.distId.map((e) => e.userId)
              : "",
          }),
          headers: {
            Accept: "application/json",
          },
        })
          .then(function(response) {
            return response.json();
          })
          .then((data) => {
            if (data !== "Nothing found") {
              if (data["logout"] == 1) {
                localStorage.setItem("USER_ID", "");
                localStorage.setItem("USER_TOKEN", "");
                window.location.href = "/logout";
              } else {
                this.setState(
                  {
                    posList: data["data"],
                  },
                  function() {}
                );
              }
            }
          });
      }
    } else if (
      localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
      localStorage.getItem("USER_LEVEL") == 18 ||
      localStorage.getItem("USER_LEVEL") == 19
    ) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getPerm",
          permType: localStorage.getItem("USER_LEVEL") == 18 ? 1 : 7,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          lang: localStorage.getItem("LANG"),
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
            return;
          }
          this.setState({ posList: data });
        });
    }
  }
  getSuperVisors() {
    if (
      localStorage.getItem("USER_LEVEL") == 1 ||
      localStorage.getItem("PERM_TYPE") == 5
    ) {
      if (
        this.state.distId?.length == 0 &&
        localStorage.getItem("USER_LEVEL") == 1
      )
        return;

      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getDistPos",
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          getType: "all",
          accountType: 17,
          distId: this.state.distId
            ? this.state.distId.map((e) => e.userId)
            : "",
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else {
              this.setState(
                {
                  supervisorsList: data["data"],
                },
                function() {}
              );
            }
          }
        });
    } else if (
      localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
      localStorage.getItem("USER_LEVEL") == 18 ||
      localStorage.getItem("USER_LEVEL") == 19
    ) {
      fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getPerm",
          permType: 8,
          userId: localStorage.getItem("USER_ID"),
          lang: localStorage.getItem("LANG"),

          token: localStorage.getItem("USER_TOKEN"),
        }),
        headers: {
          Accept: "application/json",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
            return;
          }
          this.setState({ supervisorsList: data });
        });
    }
  }
  getBillsState = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getBillsState",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        lang: localStorage.getItem("LANG"),
        pageName: this.props.balance ? "balanceReq" : "bills",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data["logout"] == 1) {
          localStorage.setItem("USER_ID", "");
          localStorage.setItem("USER_TOKEN", "");
          window.location.href = "/logout";
          return;
        }
        this.setState({ BillStates: data.billsType });
      });
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.handleSearch();
    });
  };
  exportHandler = () => {
    const params = {
      lang: localStorage.getItem("LANG"),
      userId: localStorage.getItem("USER_ID"),
      token: localStorage.getItem("USER_TOKEN"),
      type: "getBillsInfo",
      distId: this.state.distId ? this.state.distId.map((e) => e.userId) : [],
      subervisorId: this.state.subervisorId
        ? this.state.subervisorId.map((e) =>
            localStorage.getItem("USER_LEVEL") == 1 ||
            localStorage.getItem("PERM_TYPE") == 5
              ? e.userId
              : e.permId
          )
        : [],
      posId: this.state.posId
        ? this.state.posId.map((e) =>
            localStorage.getItem("USER_LEVEL") == 1 ||
            localStorage.getItem("PERM_TYPE") == 5
              ? e.userId
              : e.permId
          )
        : [],
      billState: this.state.billState,
      billType: this.props.balance || this.state.billType,
      debitDateFrom: this.state.debitDateFrom,
      debitDateTo: this.state.debitDateTo,
      lastPayDateFrom: this.state.lastPayDateFrom,
      lastPayDateTo: this.state.lastPayDateTo,
      page: this.state.activePage,
      limit: this.state.limit,
      serialNumber: this.state.serialNumber,
      export: 1, // set export to 1 for export functionality
      pageName: this.props.balance ? "balanceReq" : "bills",
    };
    const queryString = new URLSearchParams(params).toString();
    window.location = `${window.API_PERM_USER_EXPORT}?${queryString}`;
  };
  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          centered={true}
          className={"requestdataModal"}
        >
          {this.state.loadingSave == 0 && (
            <div
              className="close-modal-btn "
              onClick={() => {
                this.setState({ posCode: "", modal: false, modalData: null });
              }}
            >
              X
            </div>
          )}
          <ModalBody>
            <AvForm>
              <div className="d-flex flex-column justify-content-between h-100">
                <h5 className="text-center">{`${
                  Strings["enter confirm amount"]
                } (${this.state.modalData?.noZeroAmount || 0})`}</h5>
                <div>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <AvField
                        name="posCode"
                        type="text"
                        value={this.state.posCode}
                        onChange={this.handleInputChange}
                        errorMessage={Strings["This field is required"]}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                {this.state.loadingSave == 1 ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    type="button"
                    className="btn form-save-btn mr-1 ml-1 task-action-btn-loading"
                  >
                    <CircularProgress className="SaveCircularProgressColor" />{" "}
                    {Strings["Save"]}
                  </button>
                ) : (
                  <button
                    type={"button"}
                    className={
                      "btn gradient-button-view gradient-button gradient-button-font-12 ml-1 f-15 gradient-button-view-mobile "
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      if (
                        this.state.posCode == this.state.modalData?.noZeroAmount
                      ) {
                        this.confirmBillHandler(this.state.modalData.billId);
                      } else {
                        this.setState({ posCodeError: Strings.wrongAmount });
                      }
                    }}
                  >
                    {Strings["Confirm"]}
                  </button>
                )}

                {this.state.posCodeError != "" &&
                  this.state.posCodeError != undefined && (
                    <div className="error-text-red font-size-15 text-center mt-3">
                      {this.state.posCodeError}
                    </div>
                  )}
              </div>
            </AvForm>
          </ModalBody>
        </Modal>
        <Row>
          <Col lg={12} className="main-color-1 inv-class-view-title mb-4">
            <div>
              {this.props.balance
                ? Strings["Balance Recharge Requests"]
                : Strings["Bills requests"]}
            </div>
            <hr className="inv-hr-view-2" />
          </Col>
        </Row>
        <Row className="page-filter-view">
          <Col
            xl={{ size: 5, order: 1 }}
            lg={{ size: 7, order: 1 }}
            md={{ size: 7, order: 1 }}
            sm={{ size: 10, order: 1 }}
            xs={{ size: 12, order: 1 }}
          >
            <AvForm ref={(c) => (this.form = c)}>
              <Row className="filter-form">
                <Col lg={6} md={6} sm={6} xs={12}>
                  <AvField
                    name="debitDateFrom"
                    type="date"
                    label={`${Strings["From Date"]} (${Strings["bill open"]})`}
                    value={this.state.debitDateFrom}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                    // max={this.getTodayDate()}
                    // min={this.getMinDate(
                    //   Number(localStorage.getItem("BILLS_DAYS_NUM"))
                    // )}
                  />
                </Col>
                <Col lg={6} md={6} sm={6} xs={12}>
                  <AvField
                    name="debitDateTo"
                    type="date"
                    label={`${Strings["To Date"]} (${Strings["bill open"]})`}
                    value={this.state.debitDateTo}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                    // max={this.getTodayDate()}
                    // min={this.getMinDate(
                    //   Number(localStorage.getItem("BILLS_DAYS_NUM"))
                    // )}
                  />
                </Col>
                {/* <Col lg={6} md={6} sm={6} xs={12}>
                  <AvField
                    name="lastPayDateFrom"
                    type="date"
                    label={`${Strings["From Date"]} (${Strings["last pay"]})`}
                    value={this.state.lastPayDateFrom}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </Col>
                <Col lg={6} md={6} sm={6} xs={12}>
                  <AvField
                    name="lastPayDateTo"
                    type="date"
                    label={`${Strings["To Date"]} (${Strings["last pay"]})`}
                    value={this.state.lastPayDateTo}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </Col> */}
                <Col lg={6} md={6} sm={6} xs={12}>
                  {" "}
                  <AvField
                    name="serialNumber"
                    type="text"
                    // label={Strings["Serial Number"]}
                    placeholder={Strings["Invoice Number"]}
                    value={this.state.amount}
                    className="p-1 pl-3 mt-2"
                    onChange={this.handleInputChange}
                  />
                </Col>

                <Col lg={6} md={6} sm={6} xs={12}>
                  <AvField
                    type="select"
                    name="billState"
                    // label={Strings["Invoice Type"] + " *"}
                    value={this.state.billState}
                    onChange={this.handleInputChange}
                    className="p-1 pl-3 mt-2"
                  >
                    <option
                      style={{
                        display: "none",
                      }}
                      value=""
                      selected="selected"
                    >
                      {Strings["Bill status"]}
                    </option>
                    {this.state.BillStates?.map((data, index) => (
                      <option value={data?.key} key={index}>
                        {data?.value}
                      </option>
                    ))}
                  </AvField>
                </Col>
                {!this.props.balance && (
                  <Col lg={6} md={6} sm={6} xs={12}>
                    <AvField
                      type="select"
                      name="billType"
                      // label={Strings["Invoice Type"] + " *"}
                      value={this.state.billType}
                      onChange={this.handleInputChange}
                      className="p-1 pl-3 mt-2"
                    >
                      <option
                        style={{
                          display: "none",
                        }}
                        value=""
                        selected="selected"
                      >
                        {Strings["Invoice Type"]}
                      </option>
                      {this.state.billTypes?.map((data, index) => (
                        <option value={data?.key} key={index}>
                          {data?.value}
                        </option>
                      ))}
                    </AvField>
                  </Col>
                )}

                {localStorage.getItem("USER_LEVEL") == 1 && (
                  <Col lg={6} md={6} sm={6} xs={12}>
                    <div className="member-searchable-list mb-2 mt-2">
                      <Picky
                        value={this.state.distId}
                        options={this.state.distributorList}
                        onChange={this.handleChangeSelect.bind(this, "distId")}
                        open={false}
                        valueKey="userId"
                        labelKey="fname"
                        multiple={true}
                        includeSelectAll={true}
                        includeFilter={true}
                        dropdownHeight={200}
                        placeholder={Strings["Distributor Name"]}
                        filterPlaceholder={Strings["Select Distributor Name"]}
                      />
                    </div>
                  </Col>
                )}

                {(localStorage.getItem("USER_LEVEL") == 1 ||
                  localStorage.getItem("PERM_TYPE") == 5) && (
                  <Col lg={6} md={6} sm={6} xs={12}>
                    <div className="member-searchable-list mb-2 mt-2">
                      <Picky
                        value={this.state.subervisorId}
                        options={this.state.supervisorsList}
                        onChange={this.handleChangeSelect.bind(
                          this,
                          "subervisorId"
                        )}
                        open={false}
                        valueKey={
                          localStorage.getItem("USER_LEVEL") == 1 ||
                          localStorage.getItem("PERM_TYPE") == 5
                            ? "userId"
                            : "permId"
                        }
                        labelKey={
                          localStorage.getItem("USER_LEVEL") == 1 ||
                          localStorage.getItem("PERM_TYPE") == 5
                            ? "fullName"
                            : "name"
                        }
                        multiple={true}
                        includeSelectAll={true}
                        includeFilter={true}
                        dropdownHeight={200}
                        placeholder={Strings["POS Supervisor"]}
                        filterPlaceholder={Strings["Select POS Supervisor"]}
                      />
                    </div>
                  </Col>
                )}
                {localStorage.getItem("USER_LEVEL") != 3 && (
                  <Col lg={6} md={6} sm={6} xs={12}>
                    <div className="member-searchable-list mb-2">
                      <Picky
                        value={this.state.posId}
                        options={this.state.posList}
                        onChange={this.handleChangeSelect.bind(this, "posId")}
                        open={false}
                        valueKey={
                          localStorage.getItem("USER_LEVEL") == 1 ||
                          localStorage.getItem("PERM_TYPE") == 5
                            ? "userId"
                            : "permId"
                        }
                        labelKey={
                          localStorage.getItem("USER_LEVEL") == 1 ||
                          localStorage.getItem("PERM_TYPE") == 5
                            ? "fullName"
                            : "name"
                        }
                        multiple={true}
                        includeSelectAll={true}
                        includeFilter={true}
                        dropdownHeight={200}
                        placeholder={Strings["POS"]}
                        filterPlaceholder={Strings["Select POS"]}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </AvForm>
          </Col>
          <Col
            xl={{ size: 7, order: 12 }}
            lg={{ size: 5, order: 12 }}
            md={{ size: 5, order: 12 }}
            sm={{ size: 2, order: 12 }}
            xs={{ size: 12, order: 12 }}
            className="text-right mb-3 "
          >
            <div className="d-flex flex-wrap flex-column align-items-end gap-3 w-100">
              {(localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                localStorage.getItem("USER_LEVEL") == 18 ||
                localStorage.getItem("USER_LEVEL") == 19) &&
                this.state.openBills == "1" &&
                !this.props.balance && (
                  <button
                    variant="contained"
                    className="btn form-save-btn ml-1 mr-1 mb-2"
                    onClick={this.openSidebarModal}
                  >
                    {Strings["Add a Bill"]}
                  </button>
                )}
              <button
                className="btn page-action-btn ml-1 mb-2"
                onClick={(event) => this.exportHandler(event)}
              >
                {Strings["Export"]}
              </button>
              <button
                variant="contained"
                className="btn form-save-btn ml-1 mr-1 mb-2"
                onClick={this.handleSearch}
              >
                {Strings["Search"]}{" "}
                <SearchIcon className="font-size-15  main-color-2" />
              </button>

              <Col xs={12} lg={1} md={1} sm={12}>
                <Button
                  variant="contained"
                  type="button"
                  className="refresh-action-btn mb-2 mb-md-0"
                  onClick={this.handleRefresh}
                  title={Strings["Refresh"]}
                >
                  <i className="flaticon-refresh reset-filter-btn"></i>
                </Button>
              </Col>
              {this.state.status && (
                <div className="w-100 tooltipRequestStyle1 report-tooltip-span p-2 c-card-2">
                  <Row className="buttom-border-tool-tip ">
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      title={
                        this.props.balance
                          ? Strings["Number of Requests"]
                          : Strings["InvoicesQty"]
                      }
                      className="overflow-text-length"
                    >
                      {this.props.balance
                        ? Strings["Number of Requests"]
                        : Strings["InvoicesQty"]}
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      title={
                        this.props.balance
                          ? Strings.accepted
                          : Strings["totalInvoicesAmount"]
                      }
                      className="overflow-text-length"
                    >
                      {this.props.balance
                        ? Strings.accepted
                        : Strings["totalInvoicesAmount"]}
                    </Col>
                  </Row>
                  <Row className="table-body-row">
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      title={this.state.status.totalCount}
                      className="overflow-text-length main-color-1 font-w-400"
                    >
                      {this.state.status.totalCount}
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      title={this.state.status.sumAmount}
                      className="overflow-text-length"
                    >
                      {this.state.status.sumAmount}
                    </Col>
                  </Row>
                  <Row className="buttom-border-tool-tip ">
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      title={
                        this.props.balance
                          ? Strings.waiting
                          : Strings["totalInvoicesRemaningAmount"]
                      }
                      className="overflow-text-length"
                    >
                      {this.props.balance
                        ? Strings.waiting
                        : Strings["totalInvoicesRemaningAmount"]}
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      title={
                        this.props.balance
                          ? Strings.rejected
                          : Strings["Value of payments"]
                      }
                      className="overflow-text-length"
                    >
                      {this.props.balance
                        ? Strings.rejected
                        : Strings["Value of payments"]}
                    </Col>
                  </Row>
                  <Row className="table-body-row">
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      title={this.state.status.sumRemainingAmount}
                      className="overflow-text-length"
                    >
                      {this.state.status.sumRemainingAmount}
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      title={this.state.status.sumPaid}
                      className="overflow-text-length"
                    >
                      {this.state.status.sumPaid}
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </Col>
        </Row>
        {this.state.loading == 0 && this.state.errorMsg != "" && (
          <Row>
            <div className="inv-cards-view pb-5 width-100">
              <div
                className="text-center"
                style={{ marginTop: "15%", marginBottom: "15%" }}
              >
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(
                    "/media/ezpay/EZPAY_LOGO_GRAY" + window.MAIN_IMAGE + ".png"
                  )}
                  className="img-fluid no-data-img"
                />
                <div className="no-data-text">{this.state.errorMsg}</div>
              </div>
            </div>
          </Row>
        )}
        {this.state.loading == 0 &&
          this.state.data.length <= 0 &&
          this.state.errorMsg == "" && (
            <Row>
              <div className="inv-cards-view pb-5 width-100">
                <div
                  className="text-center"
                  style={{ marginTop: "15%", marginBottom: "15%" }}
                >
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(
                      "/media/ezpay/EZPAY_LOGO_GRAY" +
                        window.MAIN_IMAGE +
                        ".png"
                    )}
                    className="img-fluid no-data-img"
                  />
                  <div className="no-data-text">
                    {Strings["There is no data to display"]}
                  </div>
                </div>
              </div>
            </Row>
          )}
        {this.state.loading == 1 && (
          <Row>
            <Col lg={12} style={{ marginTop: "7%" }} className="text-center">
              <div className="card-body">
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-1"
                  role="status"
                ></div>
                <div
                  className="spinner-grow text-dark m-2 main-color-2"
                  role="status"
                ></div>
              </div>
            </Col>
          </Row>
        )}
        {this.state.loading == 0 && this.state.data.length > 0 && (
          <Row>
            <div className="inv-cards-view width-100">
              <Row className="table-header-row d-none d-lg-flex d-md-flex">
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Serial Number"]}
                >
                  {Strings["Serial Number"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Invoice Id"]}
                >
                  {Strings["Invoice Id"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Amount"]}
                >
                  {Strings["Amount"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["debit date"]}
                >
                  {Strings["debit date"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["lastPayDate"]}
                >
                  {Strings["lastPayDate"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["remainingAmount"]}
                >
                  {Strings["remainingAmount"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["POS Name"]}
                >
                  {Strings["POS Name"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["POS Supervisor"]}
                >
                  {Strings["POS Supervisor"]}
                </Col>
                <Col
                  lg={1}
                  md={12}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Invoice Type"]}
                >
                  {Strings["Invoice Type"]}
                </Col>

                <Col
                  lg={1}
                  md={1}
                  sm={12}
                  xs={12}
                  className="overflow-text-length"
                  title={Strings["Bill status"]}
                >
                  {Strings["Bill status"]}
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={12}
                  xs={12}
                  className="overflow-text-length d-lg-none d-md-none"
                  title={Strings["Notes"]}
                >
                  {Strings["Notes"]}
                </Col>
                {!this.props.balance && (
                  <Col
                    lg={1}
                    md={1}
                    sm={12}
                    xs={12}
                    className="overflow-text-length "
                    title={Strings["Invoice Creator"]}
                  >
                    {Strings["Invoice Creator"]}
                  </Col>
                )}

                {
                  <Col
                    lg={1}
                    md={12}
                    sm={12}
                    xs={12}
                    className="overflow-text-length text-right"
                    title={Strings["Action"]}
                  >
                    {Strings["Action"]}
                  </Col>
                }
              </Row>
              {this.state.data.map((rowData, index) => {
                return (
                  <Row key={index} className="table-body-row">
                    <Col
                      lg={1}
                      md={12}
                      sm={1}
                      xs={12}
                      className="overflow-text-length  order-2 order-md-1 order-lg-1 "
                      title={Strings["Serial Number"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Serial Number"]} :{" "}
                      </span>
                      {rowData.serialNumber}
                    </Col>
                    <Col
                      lg={1}
                      md={12}
                      sm={1}
                      xs={12}
                      className="overflow-text-length order-3 order-md-2 order-lg-2"
                      title={Strings["Invoice Id"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Invoice Id"]} :{" "}
                      </span>
                      {rowData.billId}
                    </Col>
                    <Col
                      lg={1}
                      md={12}
                      sm={1}
                      xs={12}
                      className="overflow-text-length order-1 order-md-3 order-lg-3 text-bold-ssm"
                      title={Strings["Amount"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Amount"]} :{" "}
                      </span>
                      {rowData.amount}
                    </Col>
                    <Col
                      lg={1}
                      md={12}
                      sm={1}
                      xs={12}
                      className="overflow-text-length order-5 order-md-4 order-lg-4"
                      title={Strings["debit date"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["debit date"]} :{" "}
                      </span>
                      {rowData.debitDate}
                    </Col>
                    <Col
                      lg={1}
                      md={12}
                      sm={1}
                      xs={12}
                      className="overflow-text-length order-6 order-md-5 order-lg-5"
                      title={Strings["lastPayDate"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["lastPayDate"]} :{" "}
                      </span>
                      {rowData.lastPayDate}
                    </Col>
                    <Col
                      lg={1}
                      md={12}
                      sm={1}
                      xs={12}
                      className="overflow-text-length order-7 order-md-6 order-lg-6"
                      title={Strings["remainingAmount"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["remainingAmount"]} :{" "}
                      </span>
                      {rowData.remainingAmount}
                    </Col>
                    <Col
                      lg={1}
                      md={12}
                      sm={1}
                      xs={12}
                      className="overflow-text-length order-1 order-md-7 order-lg-7 text-bold-ssm"
                      title={Strings["POS Name"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["POS Name"]} :{" "}
                      </span>
                      {rowData.posName}
                    </Col>
                    <Col
                      lg={1}
                      md={12}
                      sm={1}
                      xs={12}
                      className="overflow-text-length order-1 order-md-8 order-lg-8 text-bold-ssm"
                      title={Strings["POS Supervisor"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["POS Supervisor"]} :{" "}
                      </span>
                      {rowData.subervisorName}
                    </Col>

                    <Col
                      lg={1}
                      md={12}
                      sm={1}
                      xs={12}
                      className="overflow-text-length order-9 order-md-8 order-lg-8"
                      title={Strings["Invoice Type"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Invoice Type"]} :{" "}
                      </span>
                      {rowData.billType}
                    </Col>

                    <Col
                      lg={1}
                      md={12}
                      sm={1}
                      xs={12}
                      className="overflow-text-length order-10 order-md-9 order-lg-9"
                      title={Strings["Bill status"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Bill status"]} :{" "}
                      </span>
                      <span
                        className={`text-bold 
                          ${
                            this.props.balance != undefined
                              ? rowData.billState == "2"
                                ? "profit-color"
                                : rowData.billState === "1"
                                ? "profit-color"
                                : rowData.billState === "0"
                                ? "warning-color"
                                : rowData.billState === "+1"
                                ? "profit-color"
                                : rowData.billState == "-2"
                                ? "delete-color"
                                : ""
                              : rowData.billState == "2"
                              ? "profit-color"
                              : rowData.billState === "1"
                              ? "warning-color"
                              : rowData.billState === "0"
                              ? "status-9-color"
                              : rowData.billState === "+1"
                              ? "warning-color"
                              : rowData.billState == "-2"
                              ? "delete-color"
                              : ""
                          }`}
                      >
                        {rowData.billStateTitle}
                        {rowData.createByName != "" && this.props.balance && (
                          <Tooltip
                            title={rowData.createByName}
                            visible={
                              rowData.billId == this.state.tooltipDisplay
                            }
                            animation="zoom"
                            onVisibleChange={(event) =>
                              this.setState({
                                tooltipDisplay:
                                  rowData.billId == this.state.tooltipDisplay
                                    ? null
                                    : rowData.billId,
                              })
                            }
                            trigger="click"
                            overlay={
                              <span className="report-tooltip-span">
                                <p className="mb-0 mr-1 tooltipRequestStyle break-words">
                                  {`${Strings["Invoice Creator"]}: ${rowData.createByName}`}
                                </p>
                              </span>
                            }
                          >
                            <InfoOutlinedIcon
                              title={rowData.createByName}
                              className="reset-filter-btn mt-1 m-1 hover-green"
                            />
                          </Tooltip>
                        )}
                      </span>
                    </Col>
                    <Col
                      lg={1}
                      md={12}
                      sm={1}
                      xs={12}
                      className="overflow-text-length d-lg-none d-md-none order-11 order-md-10 order-lg-10"
                      title={Strings["Notes"]}
                    >
                      <span className="d-inline d-lg-none d-md-none text-bold">
                        {Strings["Notes"]} :{" "}
                      </span>
                      {rowData.notes?.length > 0 && (
                        <a
                          className="link-color-sample"
                          onClick={(e) => {
                            e.preventDefault();
                            this.noteModal(rowData.notes);
                          }}
                        >
                          {Strings["View Details"]}
                        </a>
                      )}
                    </Col>
                    {!this.props.balance && (
                      <Col
                        lg={1}
                        md={12}
                        sm={1}
                        xs={12}
                        className="overflow-text-length  order-11 order-md-10 order-lg-10"
                        title={Strings["Invoice Creator"]}
                      >
                        <span className="d-inline d-lg-none d-md-none text-bold">
                          {Strings["Invoice Creator"]} :{" "}
                        </span>
                        {rowData.createByName}
                      </Col>
                    )}
                    {(((localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                      localStorage.getItem("USER_LEVEL") == 18 ||
                      localStorage.getItem("USER_LEVEL") == 19) &&
                      rowData.billState === "0") ||
                      (localStorage.getItem("USER_LEVEL") == 3 &&
                        localStorage.getItem("ACCOUNT_TYPE") == 7 &&
                        rowData.billState === "-3")) && (
                      <Col
                        lg={1}
                        md={12}
                        sm={1}
                        xs={12}
                        className="overflow-text-length order-1 order-md-11 order-lg-11 d-lg-none d-md-none d-block"
                        title={Strings["accept"]}
                      >
                        <a
                          className="link-color-sample text-bold"
                          onClick={(e) => {
                            e.preventDefault();
                            this.confirmBill(e, rowData);
                          }}
                        >
                          {Strings["accept"]}
                        </a>
                      </Col>
                    )}
                    {(localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                      localStorage.getItem("USER_LEVEL") == 18 ||
                      localStorage.getItem("USER_LEVEL") == 19) &&
                      rowData.billState === "0" && (
                        <Col
                          lg={1}
                          md={12}
                          sm={1}
                          xs={12}
                          className="overflow-text-length order-12 order-md-11 order-lg-11 d-lg-none d-md-none d-block"
                          title={Strings["Reject"]}
                        >
                          <a
                            className="link-color-sample text-bold"
                            onClick={(e) => {
                              e.preventDefault();
                              this.rejectBill(e, rowData.billId);
                            }}
                          >
                            {Strings["Reject"]}
                          </a>
                        </Col>
                      )}
                    {(localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                      localStorage.getItem("USER_LEVEL") == 18 ||
                      localStorage.getItem("USER_LEVEL") == 19) &&
                      this.props.balance == undefined &&
                      (rowData.billState === "1" ||
                        rowData.billState === "2" ||
                        rowData.billState === "-4") && (
                        <Col
                          lg={1}
                          md={12}
                          sm={1}
                          xs={12}
                          className="overflow-text-length order-13 d-lg-none d-md-none d-block"
                          title={Strings["details"]}
                        >
                          <a
                            className="link-color-sample text-bold"
                            onClick={(e) => {
                              e.preventDefault();
                              this.openSidebarModal2(rowData);
                            }}
                          >
                            {Strings["details"]}
                          </a>
                        </Col>
                      )}
                    {(localStorage.getItem("PERM_TYPE") == 5 ||
                      localStorage.getItem("USER_LEVEL") == 1) &&
                      rowData.billState != 0 &&
                      !this.props.balance && (
                        <Col
                          lg={1}
                          md={12}
                          sm={1}
                          xs={12}
                          className="overflow-text-length order-14 d-lg-none d-md-none d-block"
                          title={Strings["Cancel"]}
                        >
                          <a
                            className="link-color-sample text-bold"
                            onClick={(e) => {
                              e.preventDefault();
                              this.cancelBill(
                                e,
                                rowData.billId,
                                rowData.billTypeId === "balance"
                              );
                            }}
                          >
                            {Strings["Cancel"]}
                          </a>
                        </Col>
                      )}
                    {
                      <Col
                        lg={1}
                        md={12}
                        sm={1}
                        xs={12}
                        className={
                          "action-icon-view actionDropdownList actionDropdownListPadding-0 order-1 order-md-12 order-lg-12 d-lg-flex d-md-flex d-none flex-row-reverse"
                        }
                      >
                        <Dropdown
                          isOpen={
                            this.state.openHeader ==
                            `${index}-${this.state.activePage}`
                              ? true
                              : false
                          }
                          className="font-size-13 actionDropdownList actionDropdownListPadding-0 orderlistActionlist"
                        >
                          <DropdownToggle
                            tag="a"
                            className="nav-link pt-0 actionDropdownList  actionDropdownListPadding-0"
                            caret
                          >
                            <MoreVertIcon
                              className="menu-dots-vertical actionDropdownList"
                              onClick={(event) =>
                                this.openHeaderMenu(
                                  event,
                                  `${index}-${this.state.activePage}`
                                )
                              }
                            />
                          </DropdownToggle>
                          <DropdownMenu className="font-size-13 modal-left-0 actionDropdownList ">
                            <div
                              className="actionDropdownList"
                              style={{
                                maxHeight: "250px",
                                overflowY: "scroll",
                              }}
                            >
                              {(localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                                localStorage.getItem("USER_LEVEL") == 18 ||
                                localStorage.getItem("USER_LEVEL") == 19) &&
                                rowData.billState === "0" && (
                                  <DropdownItem
                                    className="actionDropdownList"
                                    onClick={(event) => {
                                      this.rejectBill(event, rowData.billId);
                                    }}
                                  >
                                    {Strings["Reject"]}
                                  </DropdownItem>
                                )}
                              {(localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                                localStorage.getItem("USER_LEVEL") == 18 ||
                                localStorage.getItem("USER_LEVEL") == 19) &&
                                this.props.balance == undefined &&
                                (rowData.billState === "1" ||
                                  rowData.billState === "2" ||
                                  rowData.billState === "-4") && (
                                  <DropdownItem
                                    className="actionDropdownList"
                                    onClick={(event) => {
                                      this.openSidebarModal2(rowData);
                                    }}
                                  >
                                    {Strings["details"]}
                                  </DropdownItem>
                                )}

                              {(localStorage.getItem("PERM_TYPE") == 5 ||
                                localStorage.getItem("USER_LEVEL") == 1) &&
                                rowData.billState != 0 &&
                                !this.props.balance && (
                                  <DropdownItem
                                    className="actionDropdownList"
                                    onClick={(event) => {
                                      this.cancelBill(
                                        event,
                                        rowData.billId,
                                        rowData.billTypeId === "balance"
                                      );
                                    }}
                                  >
                                    {Strings["Cancel"]}
                                  </DropdownItem>
                                )}

                              {(((localStorage.getItem("POS_MGR_EMP_NEW") ==
                                1 ||
                                localStorage.getItem("USER_LEVEL") == 18 ||
                                localStorage.getItem("USER_LEVEL") == 19) &&
                                rowData.billState === "0") ||
                                (localStorage.getItem("USER_LEVEL") == 3 &&
                                  localStorage.getItem("ACCOUNT_TYPE") == 7 &&
                                  rowData.billState === "-3")) && (
                                <DropdownItem
                                  className="actionDropdownList"
                                  onClick={(event) => {
                                    this.confirmBill(event, rowData);
                                  }}
                                >
                                  {Strings["accept"]}
                                </DropdownItem>
                              )}
                              {/* {localStorage.getItem("USER_LEVEL") == 3 &&
                                  localStorage.getItem("ACCOUNT_TYPE") == 7 &&
                                  rowData.billTypeId === "insurance" && (
                                    <DropdownItem
                                      className="actionDropdownList"
                                      onClick={(event) => {
                                        this.confirmBill2(
                                          event,
                                          rowData.billId
                                        );
                                      }}
                                    >
                                      {Strings["closeInsuranceBill"]}
                                    </DropdownItem>
                                  )} */}
                            </div>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    }
                  </Row>
                );
              })}
              {this.state.loading == 0 && this.state.data?.length > 0 && (
                <Col md={12}>
                  <Row className="pagenation-view mt-3 mb-4">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-next"
                              : "flaticon2-back"
                          }
                        ></i>
                      }
                      nextPageText={
                        <i
                          className={
                            localStorage.getItem("LANG") == 1
                              ? "flaticon2-back"
                              : "flaticon2-next"
                          }
                        ></i>
                      }
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded justify-content-end pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Row>
                </Col>
              )}
            </div>
          </Row>
        )}

        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openleftModal}
          className={
            this.state.actionType == "notification"
              ? "sidebar-modal-large-view"
              : "sidebar-modal-view"
          }
        >
          {this.state.openleftModal && (
            <AddBillForm
              getSupervisorBalance={this.props.getSupervisorBalance}
              balance={this.props.balance}
              billTypes={this.state.billTypes}
              posList={this.state.posList}
              close={this.closeSidebarModal}
            />
          )}
        </Drawer>
        <Drawer
          anchor={localStorage.getItem("LANG") == 1 ? "left" : "right"}
          open={this.state.openModal}
          className={"sidebar-modal-large-view"}
        >
          {this.state.openModal && (
            <BillDetails
              {...this.state.modalData}
              close={this.closeSidebarModal2}
            />
          )}
        </Drawer>
      </div>
    );
  }
}
