export const getTranslation = (lang, text) => {
  return translations[lang][text];
};

export const translations = {
  En: {
    "Audit Type": "Audit Type",
    "Select Audit": "Select Audit",
    Audit: "Audit",
    AuditingPOS: "Auditing POS cards",
    apiKey: "API key",
    "Add Category": "Add Category",
    "Login to your account": "Login to your account",
    "Confirm Code": "Confirm Code",
    Email: "Email",
    "Add Problem": "Add Problem",
    Administration: "Administration",
    Column: "Column",
    "Show Filter": "Show Filter",
    "Hide Filter": "Hide Filter",
    "Its location in the file": "Its location in the file",
    "Arrangement the columns in the form":
      "Arrangement the columns in the form",
    Password: "Password",
    "Processing Method": "Processing Method",
    "Select Processing Method": "Select Processing Method",
    "Select Processed": "Select Processed",
    Processed: "Processed",
    "Enter your email": "Enter your email",
    "Enter password": "Enter password",
    "This field is required": "This field is required",
    "Enter Qr code": "Enter Qr code",
    "Qr Code": "Qr Code",
    "App Status": "App Log",
    Login: "Login",
    Status: "Status",
    "Select Status": "Select Status",
    "Please enter a valid period !": "Please enter a valid period !",
    "Network Operator": "Network Operator",
    Confirm: "Confirm",
    enterCode: "Enter Code",
    "Istanbul Information Technology": "Istanbul Information Technology",
    Dashboard: "Dashboard",
    Distributors: "Distributors",
    Clients: "Clients",
    "Select Clients": "Select Clients",
    Inventory: "Inventory",
    "Client Orders": "Client Orders",
    "Mobile Orders": "Mobile Orders",
    "Web Orders": "Web Orders",
    "Client Services": "Client Services",
    Search: "Search",
    Edit: "Edit",
    Delete: "Delete",
    "Sign Out": "Sign Out",
    "My Profile": "My Profile",
    "Account settings and more": "Account settings and more",
    Category: "Category",
    Sender: "Sender",
    "Update Cards": "Update Cards",
    Value: "Value",
    Balance: "Balance",
    "QR Code Types": "QR Code Types",
    "The card class has no supplier API": "The card class has no supplier API",
    "This card class has no Prices for suppliers API":
      "This card class has no Prices for suppliers API",
    "This card class has no Prices for suppliers":
      "This card class has no Prices for suppliers",
    "Minimum number of cards": "Min # of cards",
    Status: "Status",
    Serial: "Serial",
    "Edit Status": "Edit Status",
    "Edit problem status": "Edit problem status",
    Action: "Action",
    "Add Supplier": "Add Supplier",
    "Add Suppliers API": "Add Suppliers API",
    "Selection of API Suppliers": "Selection of API Suppliers",
    "Add Balance": "Add Balance",
    "Add Numny Balance": "Add Numny Balance",
    "Add Egifter Balance": "Add Egifter Balance",
    "Account Statement": "Account Statement",
    "View Cards": "View Cards",
    "Eazy Pay": "Easypay",
    Add: "Add",
    wrongAmount: "Wrong Amount",
    "Maximum Amount Of Payment": "Maximum Amount Of Payment",
    "Enter Maximum Amount Of Payment": "Enter Maximum Amount Of Payment",
    "Category Name": "Category Name",
    "Enter Category Name": "Enter Category Name",
    "Category Name In English": "Category Name In English",
    "Show the order number to the points of sale":
      "Show the order number to the points of sale",
    Unlinked: "Unlinked",
    Linked: "Linked",
    "Serial status": "Serial status",
    Unlink: "Unlink",
    "Edit Link": "Edit Link",
    theLink: "Link",
    Link: "Link",
    "Edit Serial": "Edit Serial",
    Cheque: "Cheque",
    Cash: "Cash",
    "Serial Link": "Serial Link",
    "Edit Serial Link": "Edit Serial Link",
    "Enter Category Name In English": "Enter Category Name In English",
    Save: "Save",
    Cancel: "Cancel",
    "Category Type": "Category Type",
    "Select category type": "Select category type",
    "Fixed Price": "Fixed Price",
    "Non Fixed Price": "Non Fixed Price",
    isMaster: "Master",
    "Educational Cards": "Educational Cards",
    "Category Icon": "Category Icon",
    "Upload Image only": "Upload Image only",
    Upload: "Upload",
    "Get Codes By Send Email": "Get Codes By Send Email",
    "From which information was chosen to take this copy from the repository?":
      "From which information was chosen to take this copy from the repository?",
    "Add Card": "Add Card",
    "Card Name": "Card Name",
    "Enter Card Name": "Enter Card Name",
    "Tax distributor": "Tax distributor",
    "Card Name In English": "Card Name In English",
    "Enter Card Name In English": "Enter Card Name In English",
    "Card Icon": "Card Icon",
    "Card Binding Type": "Card Binding Type",
    "Select card binding type": "Select card binding type",
    Image: "Image",
    "Orders Problems": "Orders Problems",
    "Excel File": "Excel File",
    "Images File": "Images File",
    "Buy Price": "Buy Price",
    "Select buy price": "Select buy price",
    "Fixed Price": "Fixed Price",
    Percentage: "Percentage",
    "Invoice Type": "Invoice Type",
    "Select invoice Type": "Select invoice Type",
    Custom: "Custom",
    Public: "Public",
    Currency: "Currency",
    "Select currency": "Select Currency",
    "Activate Notifications": "Activate Notifications",
    "Best Seller": "Best Seller",
    Notes: "Notes",
    "Enter Notes": "Enter notes",
    "Change currency": "Change currency",
    "Arabic Desription": "Arabic Desription",
    "Enter arabic desription": "Enter arabic desription",
    "English Desription": "English Desription",
    "Enter english desription": "Enter english desription",
    "MintRout Cards": "MintRout Cards",
    "Enter Percentage": "Enter Percentage",
    "Points Of Sales": "Points Of Sales",
    Name: "Name",
    "Subscription Date": "Subscription Date",
    Phone: "Phone",
    Region: "Region",
    "Account Information": "Account Information",
    "Financial Report": "Financial Report",
    "Account Statement": "Account Statement",
    "Add / Get Amount": "Add /  Amount Correction",
    invalidNumber: "This number is invalid",
    "Add Amount": "Add Amount",
    "Reset Authentication": "Reset Authentication",
    "All Cards": "All Cards",
    "There are no files": "There are no files",
    "There is no data to display": "There is no data to display",
    Card: "Card",
    "Are you sure ?": "Are you sure ?",
    "You want to delete this category ?": "You want to delete this category ?",
    "You want to delete this number ?": "You want to delete this number ?",
    Yes: "Yes",
    Categories: "Categories",
    "You want to delete this card ?": "You want to delete this card ?",
    "You want to delete this form ?": "You want to delete this form ?",
    "You want to delete this discount ?": "You want to delete this discount ?",
    "Add Class": "Add Class",
    "Card Description": "Card Description",
    "Invoice message text": "Invoice message text",
    "Class Name": "Class Name",
    "Invoice message notes": "Invoice message notes",
    "Enter Class Name": "Enter Class Name",
    "Class Name In English": "Class Name In English",
    "Enter Class Name In English": "Enter Class Name In English",
    "Card Value": "Card Value",
    "Enter Card Value": "Enter Card Value",
    "Card Value In USD": "Card Value In USD",
    "Enter Card Value In USD": "Enter Card Value In USD",
    "Cost Value": "Cost Value",
    "Enter Cost Value": "Enter Cost Value",
    "Number Of Codes": "Number Of Codes",
    "Enter Number Of Codes": "Enter Number Of Codes",
    "Minimum Number Of Cards": "Minimum Number Of Cards",
    "Enter Minimum Number Of Cards": "Enter Minimum Number Of Cards",
    paymentStatus: "Payment Status",
    Price: "Price",
    Checked: "Checked",
    Pending: "Pending",
    "Enter Price": "Enter Price",
    "Selling Price": "Selling Price",
    "Enter Selling Price": "Enter Selling Price",
    Type: "Type",
    "Only numbers are allowed": "Only numbers are allowed",
    "Enter Type": "Enter Type",
    "Select type": "Select type",
    "Class Icon": "Class Icon",
    Class: "Class",
    "User Name": "User Name",
    "Enter User Name": "Enter User Name",
    "Product Id": "Product Id",
    "Enter Product Id": "Enter Product Id",
    "Enter Value": "Enter Value",
    "Country Code": "Country Code",
    "Enter Country Code": "Enter Country Code",
    "Card Currency": "Card Currency",
    "Enter Card Currency": "Enter Card Currency",
    "You want to delete this class ?": "You want to delete this class ?",
    "Make sure that the image width is not more than 200":
      "Make sure that the image width is not more than 200",
    Title: "Title",
    "Enter Title": "Enter Title",
    Description: "Description",
    "Enter Description": "Enter Description",
    Id: "Id",
    "Allow release of order information": "Allow release of order information",
    "You want to delete this description ?":
      "You want to delete this description ?",
    Sort: "Sort",
    "Sort Card Description": "Sort Card Description",
    "Buy & Sell Price": "Buy & Sell Price",
    "Select supplier": "Select supplier",
    "Create a new Distributer": "Create a new Distributer",
    "Add API": "Add API",
    Suppliers: "Suppliers",
    "Reasons for transfer": "Reasons for transfer",
    "Purchase Orders": "Purchase Orders",
    "Select purchase order": "Select purchase order",
    "Upload Excel File only": "Upload Excel File only",
    "An error occurred while processing your request.":
      "An error occurred while processing your request.",
    "Cheque Image": "Cheque's Image",
    "Upload CSV File only": "Upload CSV File only",
    Example: "Example",
    "Download Example": "Download Example",
    "Upload Excel File": "Upload Excel File",
    "Upload CSV File": "Upload CSV File",
    "These Cards Already Exist": "These Cards Already Exist",
    "Check Number Of Codes For These Cards":
      "Check Number Of Codes For These Cards",
    Quantity: "Quantity",
    "Enter Quantity": "Enter Quantity",
    "Points Of Sales Orders": "Points Of Sales Orders",
    Date: "Date",
    Count: "Count",
    Order: "Order",
    token: "Token",
    "Voucher Number": "Voucher Number",
    "Entry Date": "Entry Date",
    Used: "Used",
    "Not Used": "Not Used",
    "Search For Code": "Search For Code",
    "Search For Serial No.": "Search For Serial No.",
    Settings: "Settings",
    "Purchase Orders": "Purchase Orders",
    "Bank Name": "Bank Name",
    "From Date": "From Date",
    "To Date": "To Date",
    "Add Order": "Add Order",
    Export: "Export",
    Drawer: "Drawer",
    Canceled: "Canceled",
    copy: "Copy",
    Completed: "Completed",
    "Balance Recharge": "Balance Recharge",
    "Direct Charging Type": "Direct Charging Type",
    "Select Direct Charging Type": "Select Direct Charging Type",
    "Not Completed": "Not Completed",
    "Order #": "Order #",
    "My Orders": "My Orders",
    Refresh: "Refresh",
    "Free Cards": "Free Cards",
    "Direct Charge Cards API": "Direct Charge Cards API",
    CategoryMain: "Category",
    "Select Category": "Select Category",
    "Card Type": "Card Type",
    "Select Card Type": "Select Card Type",
    "Order Number": "Order Number",
    "Client Name": "Client Name",
    "Distributor Name": "Distributor Name",
    Time: "Time",
    "Export All Type": "Export All Type",
    "App Logo": "App Logo",
    Denomination: "Denomination",
    "Cards Count": "Cards Count",
    "Reference #": "Reference #",
    "Not Received": "Not Received",
    "In Proccess": "Waite",
    Complete: "Received",
    Rejected: "Reject",
    Reject: "Reject",
    "File Name": "File Name",
    "Cards Restored To Inventory": "Cancel Cards Type",
    "Not Canceled": "Not Canceled",
    "Some Cards Have Been Recovered": "Some Cards Have Been Recovered",
    "EcoVoucher Cards Have Been Restored": "Cards Recived To Inv.",
    "Select Status": "Select Status",
    "Cost Price": "Cost Price",
    "Cost Price In USD": "Cost Price In USD",
    "Supplier Cost": "Supplier Cost",
    "Edit Selling Price": "Edit Selling Price",
    "Display a logo for points of sale": "Display a logo for points of sale",
    "Insert Price Less Than Or Equal Cost Price":
      "Insert Price Less Than Or Equal Cost Price",
    "Insert Price Greater Than Or Equal Cost Price":
      "Insert Price Greater Than Or Equal Cost Price",
    "Some Card Used": "Some Card Used",
    "Select Bank Name": "Select Bank Name",
    "Supplier Name": "Supplier Name",
    "Select Supplier Name": "Select Supplier Name",
    "Request By": "Request By",
    "Number Of Card Classes": "Number Of Card Classes",
    "Payment Method": "Payment Method",
    "Order Date": "Order Date",
    "Print QR Code": "Print QR Code",
    Note: "Note",
    "Subject of Email": "Subject of Email",
    "Qr Code Type": "Qr Code Type",
    View: "View",
    "View Purchase Orders": "View Purchase Orders",
    "Edit Purchase Orders": "Edit Purchase Orders",
    "Qty Price": "Qty Price",
    "Purchase Orders Number": "Purchase Orders Number",
    Qty: "Qty",
    "Total Price": "Total Price",
    "Allow modification of purchase prices":
      "Allow modification of purchase prices",
    "Total Price After Dicount": "Total Price After Dicount",
    Uploaded: "Uploaded",
    "Not Uploaded": "Not Uploaded",
    "Add Employee": "Add Employee",
    "Add Denomination": "Add Denomination",
    "Add Notification": "Add Notification",
    Denominations: "Denominations",
    "You want to delete this denomination ?":
      "You want to delete this denomination ?",
    "Assign ID": "Assign ID",
    Employee: "Employee",
    "Total Cards": "Total Cards",
    "Total cards required": "Total cards required",
    "Total cards drawn": "Total cards drawn",
    "First Name": "First Name",
    "Enter First Name": "Enter First Name",
    "Last Name": "Last Name",
    "Enter Last Name": "Enter Last Name",
    "Enter Subscription Date": "Enter Subscription Date",
    "Enter Phone": "Enter Phone",
    "Enter Email": "Enter Email",
    "Enter Region": "Enter Region",
    Location: "Location",
    "The billing number is the billing number,subscriber number, phone number, or university number":
      "The billing number is the billing number,subscriber number, phone number, or university number",
    longitude: "longitude",
    Latitude: "Latitude",
    "Tax Number": "Tax Number",
    "Enter Tax Number": "Enter Tax Number",
    "Image (1)": "Image (1)",
    "Image (2)": "Image (2)",
    "Qr Image": "Qr Image",
    "Create distribution points": "Create distribution points",
    "Confirm Password": "Confirm Password",
    "Request Balance Charge": "Request Balance Charge",
    "Allow Use Efawtercom": "Allow Use Efawtercom",
    Banks: "Banks",
    "Select card class to display its API suppliers":
      "Select card class to display its API suppliers",
    "Bank Name": "Bank Name",
    Balance: "Balance",
    "Wallet Type": "Wallet Type",
    "Add Payment": "Add Payment",
    Transfer: "Transfer",
    Transactions: "Transactions",
    "Purchase Order": "Purchase Order",
    Supplier: "Supplier",
    "Txn Id": "Txn Id",
    "From Bank": "From Bank",
    "You want to delete this purchase order ?":
      "You want to delete this purchase order ?",
    "You want to close financial audit this purchase order ?":
      "You want to close financial audit this purchase order ?",
    Address: "Address",
    "Amount of payment": "Amount of payment",
    "Transfer From": "Transfer From",
    "Transfer To": "Transfer To",
    "Amount (Dollar)": "Amount (Dollar)",
    "Type Of Operation": "Type Of Operation",
    Get: "Correction",
    "Type of international calls": "Type of international calls",
    "Select type of international calls": "Select type of international calls",
    "Select Type Of Operation": "Select Type Of Operation",
    Amount: "Amount",
    "debit date": "debit date",
    "Enter Amount": "Enter Amount",
    "The supplier has no cards": "The supplier has no cards",
    "Enter Qty": "Enter Qty",
    "Bank balance is not enough": "Bank balance is not enough",
    "Select Supplier And Bank To Display Cards":
      "Select Supplier And Bank To Display Cards",
    "My Wallet": "My Wallet",
    "Other Wallet": "Other Wallet",
    "Please enter a valid value": "Please enter a valid value",
    "Please select a valid value": "Please select a valid value",
    "Enter Name": "Enter Name",
    "Enter Address": "Enter Address",
    "You want to delete this supplier ?": "You want to delete this supplier ?",
    "Transaction #": "Transaction #",
    "Orginal Amount": "Orginal Amount",
    "Orginal Amount Type": "Orginal Amount Type",
    "Balance Before": "Balance Before",
    "Balance After": "Balance After",
    "Tansaction Type": "Tansaction Type",
    "Tansaction Status": "Tansaction Status",
    "Tansaction Date": "Tansaction Date",
    "Transactions On Bank": "Transactions On Bank",
    "Commission Value": "Commission Value",
    "Enter Commission Value": "Enter Commission Value",
    "Currency Type": "Currency Type",
    "Select Currency Type": "Select Currency Type",
    "Select Wallet Type": "Select Wallet Type",
    "Original Value": "Original Value",
    "Original Value Type": "Original Value Type",
    "Balance Before Transaction": "Balance Before Transaction",
    "Balance After Transaction": "Balance After Transaction",
    "Print Status": "Print Status",

    "Transfer Type": "Transfer Type",
    "Purchase Order From": "Purchase Order From",
    "Payment From Bank": "Payment From Bank",
    "Direct Purchase Order": "Direct Purchase Order",
    "Card Inventory": "Card Inventory",
    Active: "Active",
    Finished: "Finished",
    "Number of cards": "Number of cards",
    "Add Cards": "Add Cards",
    "Authentication Reset": "Authentication Reset",
    "Sort Categories": "Sort Categories",
    codeSent: "Please enter the code that was sent via e-mail or SMS",

    Employees: "Employees",
    chooseEmployee: "Choose Employee",
    Cards: "Cards",
    Notifications: "Notifications",
    "POS Notifications": "POS Notifications",
    "Add Balance to POS": "Add Balance to POS",
    "Edit Account Information": "Edit Account Information",
    "You want to  Reset Authentication ?":
      "You want to  Reset Authentication ?",
    "POS Status": "POS Status",
    "Select POS Status": "Select POS Status",
    "Select Point Of Sales": "Select Point Of Sales",
    "Operation Number": "Operation Number",
    "Operation Date": "Operation Date",
    "Operation Type": "Operation Type",
    "Total amounts that added": "Total amounts that added",
    "Total amount that pulled": "The total value of corrections",
    "Orders total amount": "Orders total amount",
    "Total balance": "Total balance",
    "Balance Type": "Balance Type",
    "You want to delete this pos ?": "You want to delete this pos ?",
    "You want to delete this employee ?": "You want to delete this employee ?",
    Classes: "Classes",
    "You want to delete this notification ?":
      "You want to delete this notification ?",
    POS: "POS",
    Addition: "Addition",
    Withdraw: "Withdraw",
    "Payment Fees": "Payment Fees",
    "WhatsApp Notifications": "WhatsApp Notifications",
    "Select POS": "Select POS",
    "Select POS Manager": "Select POS Manager",
    "Pos Code": "Pos Code",
    errPosCode: "Wrong POS code",
    "Enter Pos Code": "Enter Pos Code",
    "Please enter a 6-digit code": "Please enter a 6-digit code",
    "Pos Class": "Pos Class",
    "Select Pos Class": "Select Pos Class",
    "Password Not Match": "Password Not Match",
    "Sell Price": "Sell Price",
    "Edit Classes Price": "Edit Classes Price",
    "Edit Price": "Edit Price",
    "selling price must be greater or equal to the buy price":
      "selling price must be greater or equal to the buy price",
    "POS Name": "POS Name",
    paymentUrl: "Payment URL",
    clickHere: "Click Here",
    buy: "Buy",
    "POS Manager Name": "POS Manager Name",
    "POS Manager": "POS Manager",
    "Pos Manager Trans #": "Trans #(Pos Manager)",
    "Pos Trans #": "Trans #(Pos)",
    "Agent Name": "Agent Name",
    "No. Device": "No. Device",
    "App Version": "App Version",
    "Last Login": "Last Login",
    "Last Logout": "Last Logout",
    "Number of logins": "Number of logins",
    "Number of logouts": "Number of logouts",
    "Transaction Value": "Transaction Value",
    "Cards Value": "Cards Value",
    "You want to send sms ?": "You want to send sms ?",
    "You want to cancel order ?": "You want to cancel order ?",
    "Invoice Password": "Invoice Password",
    "Number of days to see order history":
      "Number of days to see order history",
    "View Order": "View Order",
    "My Account": "My Account",
    "Done Successfully": "Done Successfully",
    Reports: "Reports",
    ok: "Ok",
    "POS Cards Profit": "POS Cards Profit",
    Total: "Total",
    "Number Of Products": "Number Of Products",
    "Total Of Cost Price / Sale Price": "Total Of Cost Price / Sale Price",
    resultTitle: "Profit",
    "General Settings": "General Settings",
    "Classes Name": "Classes Name",

    "Total Profit For Each Class": "Total Profit For Each Class",
    "Net Profit For All Classes": "Net Profit For All Classes",
    "Balance Recharge Invoice": "Balance Recharge Invoice",
    charge: "Charge",
    Invoices: "Invoices",
    "Withdrawal Invoices": "Withdrawal Invoices",
    "Arabic Name": "Arabic Name",
    "Enter Arabic Name": "Enter Arabic Name",
    "English Name": "English Name",
    "Enter English Name": "Enter English Name",
    Coupons: "Coupons",
    "Number of coupons": "Number of coupons",
    "Enter Number of coupons": "Number of coupons",
    "One time use": "One time use",
    Code: "Code",
    "Qty remaining": "Qty remaining",
    "Discount rate": "Discount rate",
    "Enter discount rate": "Enter discount rate",
    "Coupon type": "Coupon type",
    "Use several times": "Use several times",
    "Download codes": "Download codes",
    "Company Name": "Company Name",
    "Enter Company Name": "Enter Company Name",
    "Sale Currency": "Sale Currency",
    Country: "Country",
    "Type Num": "Type Num.",
    City: "City",
    "Enter City": "Enter City",
    District: "District",
    "Enter District": "Enter District",
    "Zip Code": "Zip Code",
    "Enter Zip Code": "Enter Zip Code",
    "Commercial Registration No.": "Commercial Registration No.",
    "Enter Commercial Registration No.": "Enter Commercial Registration No.",
    Mobile: "Mobile",
    "Time Zone": "Time Zone",
    "Enter Time Zone": "Enter Time Zone",

    Logo: "Logo",
    "Minimum Stock": "Minimum Stock",
    "Enter Minimum Stock": "Enter Minimum Stock",
    "When the stock reaches this number, it will be filled from the buffer":
      "When the stock reaches this number, it will be filled from the buffer",
    Invoice: "Invoice",
    "Transfer Image": "Transfer Image",
    Download: "Download",
    Preview: "Preview",
    "Correction Type": "Correction Type",
    "The payment was approved": "The payment was approved",
    "Financial Operations Report": "Financial Operations Report",
    "Financial operations": "Financial operations",
    "Select Operation Type": "Select Operation Type",
    Loan: "Loan",
    Retrieval: "Retrieval",
    "Distributor Orders": "Distributor Orders",
    Payment: "Payment",
    Emails: "Emails",
    "Financial Audit Status": "Financial Audit Status",
    "Codes File": "Codes File",
    "Upload File": "Upload File",
    "Uploaded File": "Upload File",
    "Upload File Templates": "Upload File Templates",
    "Upload File Template": "Upload File Template",
    "Send By Email": "Send By Email",
    "Value of total payments": "Value of total payments",
    "total payments": "total payments",
    paymentsQty: "Payments Count",
    "POS balance": "POS balance",
    "Total profits": "Total profits",
    "Change POS Code": "Change POS Code",
    "You want to Hide Cards Prices?": "You want to Hide Cards Prices?",
    "You want to Display Cards Prices?": "You want to Display Cards Prices?",
    Attachments: "Attachments",
    "Invoice Number": "Invoice Number",
    "Final balance": "Final balance",
    "Debt Value": "Debt Value",
    "Debt Total": "Debt Total",
    "Cards Profit Inventory": "Cards Profit Inventory",
    "Total Sale Price": "Total Sale Price",
    "Unit Price": "Unit Price",
    Recovery: "Recovery",
    Barcode: "Barcode",
    "Select Upload File Templates": "Select Upload File Templates",
    "Select Upload File Template": "Select Upload File Template",
    "WhatsApp Number": "WhatsApp Number",
    "We will send you this report by email":
      "We will send you this report by email",
    "Repayment of loan": "Repayment of loan",
    "New Password": "New Password",
    "Number Of Operations": "Number Of Operations",
    "Total cost and margin within 7 days":
      "Total cost and margin within 7 days",
    visaPay: "can use VISA",
    "The best selling cards within 7 days":
      "The best selling cards within 7 days",
    "Total cost and margin within ": "Total cost and margin within ",
    "The best selling cards within ": "The best selling cards within ",
    days: "days",
    Selected: "Selected",
    "Least selling": "Least selling",
    "Best seller": "Best seller",
    "Cost Total": "Cost Total",
    "Margin Total": "Margin Total",
    Margin: "Margin",
    Cost: "Cost",
    "Fixed All Orders": "Fixed All Orders",
    "Fix it": "Fix it",
    "Cards Sales": "Cards Sales",
    "loans Total": "loans Total",
    "Card Class": "Card Class",
    "Operation #": "Operation #",
    "Card Class Value": "Card Class Value",
    "Enter Card Class Value": "Enter Card Class Value",
    "Notification Email [ add classes ]": "Notification Email [ add classes ]",
    "Enter Notification Email [ add classes ]":
      "Enter Notification Email [ add classes ]",
    "Buy Cards": "Buy Cards",
    Done: "Done",
    "This location is reserved for another column":
      "This location is reserved for another column",
    "Location is reserved for this column":
      "Location is reserved for this column",
    "API Order": "API Order",
    Next: "Next",
    "The quantity will be requested from all API suppliers via Buffer":
      "The quantity will be requested from all API suppliers via Buffer",
    "Select Cards Category": "Select Cards Category",
    "Please Select Card Category": "Please Select Card Category",
    "Select Card": "Select Card",
    Back: "Back",
    "Order Has Been Successfully Added": "Order Has Been Successfully Added",
    Home: "Home",
    "Determine the value of the cards": "Determine the value of the cards",
    Alert: "Alert",
    "Select Domain To": "Select Domain",
    "Select Domain From": "Select Domain",
    "Transfer Type": "Transfer Type",
    "Select Transfer Type": "Select Transfer Type",
    "Cards Transfer Report": "Cards Transfer Report",
    "Tax Value": "Tax Value",
    "Deduction Value": "Deduction Value",
    "Bank Account": "Bank Account",
    "Select Bank Account": "Select Bank Account",
    "Show Password": "Show Password",
    "Actual Value": "Actual Value",
    "Remittance Value": "Transaction Value",
    "Transaction Amount": "Transaction Amount",
    "Enter Transaction Amount": "Enter Transaction Amount",
    "Conversion Ratio": "Conversion Ratio",
    "Please select the required quantity":
      "Please select the required quantity",
    "Sorry, the required quantity is not available":
      "Sorry, the required quantity is not available",
    "Tax percentage": "Tax percentage",
    Tax: "Tax",
    "face Amount": "Face Amount",
    amount: "Amount",
    "Uncompleted Orders": "My Request",
    "Request #": "Request #",
    accept: "Accept",
    "You want to accept request?": "You want to accept request?",
    "Your request is in processed": "Your request is in processed",
    Close: "Close",
    "Export purchase orders and their complete reference number information":
      "Export purchase orders and their complete reference number information",
    "Re-order": "Re-Order",
    "You want to Re-Order?": "You want to Re-Order?",
    "Re-order is successfully": "Re-order is successfully",
    Tools: "Tools",
    "Sales Account Report": "Sales Account Report",
    request: "Request",
    responseData: "Response Data",
    update: "Update",
    "update status": "Update status",
    "please select the status of request":
      "Please select the status of request",
    "select distributor": "Select distributor",
    "subscribe #": "Subscribe #",

    "Resend SMS": "Resend SMS",
    "Add POS": "Add POS",
    "Price Type": "Price Type",
    "is not a valid ": "Is not a valid ",
    message: "message",
    "info ": "Info",
    "Signature Image ": "Signature Image",
    "All card classes following this card do not have an API supplier":
      "All card classes following this card do not have an API supplier",
    daysCountError: "The search period should be a week or less",
    "Hide Cards Prices": "Hide Cards Prices",
    "Display Cards Prices": "Display Cards Prices",
    "Request Data": "Request Data",
    "phone with country code": "Phone with country code",
    "number of order": "Order #",
    "number of request": "Rq #",
    "Request Information": "Request Information",
    copied: "copied",
    "Edit phone": "Edit phone",
    "Select requestType": "Select Request Type",
    requestType: "Request Type",
    apiPassword: "API Password",
    invoiceAmount: "Invoice Amount",
    "Select Card Category": "Select Card Category",
    "Select Card": "Select Card",
    "Select Card Class": "Select Card Class",
    oldest: "Oldest",
    Newest: "Newest",
    "Clear Filter": "Refersh Filter",
    "sort by": "Sort by",
    "Sort Type": "Sort Type",
    ///Add in 20-01-2022
    "Product Name": "Product Name",
    "Product Price": "Product Price",
    "Product Quantity": "Product Quantity",
    "Search For Name": "Search For Name",
    "Search In": "Search In",
    "Enter Id": "Enter Id",
    "Select Search In": "Select Search In",
    Product: "Product",
    "Select Product": "Select Product",
    "Invoice Id": "Invoice Id",
    Receipt: "Receipt",
    "Search for Number": "Search for Number",
    "Select All": "Select all",
    "%s selected": "%s selected",
    payment_method: "Payment Method",
    JSC: "JSC",
    "Correction View": "Correction View",
    Permissions: "Permissions",
    "Pages Permissions": "Pages Permissions",
    "Add Permission": "Add Page Permission",
    Permission: "Permission",
    "Page Permission": "Page Permission",
    Pages: "Pages",
    "Select Page": "Select Page",
    "Select Actions": "Select Actions",
    "You want to delete this Page ?": "You want to delete this Page ?",
    "Cards Profit inventory report": "Cards Profit Inventory report",
    "Send profits to distributors by email": "Send profits to distributors",
    "Select Distributor Name": "Select Distributor Name",
    "Select Distributor": "Select Distributor",
    "Products Number": "Products Number",
    Net: "Net",
    "Permission Types": "Permission Types",
    "Permission Type": "Permission Type",
    "Modification Date": "Modification Date",
    "in Arabic": "in Arabic",
    "in English": "in English",
    "Enter Permission Type": "Enter Permission Type",
    "You want to delete this Type ?": "You want to delete this Type ?",
    "Page Action": "Page Action",
    All: "All",
    "Mobile Number": "Mobile Number",
    "SEARCH TO ADD A NOTE": "SEARCH TO ADD A NOTE",
    None: "None",
    "Processing Stage": "Processing Stage",
    "Processing By": "Processing By",
    Custome: "Custome",
    "Apply to all": "Apply to all type",
    "Managers List": "Managers List",
    "Add Manager": "Add Manager",
    "Join Date": "Join Date",
    "Select Admin Type": "Select Admin Type",
    Manager: "Manager",
    "Add Message": "Add Message",
    "API Suppliers Cards": "API Suppliers Cards",
    "Type Name": "Type Name",
    "Account Type": "Account Type",
    "Select Account Type": "Select Account Type",
    "Select Report": "Select Report",
    "Enter API Key": "Enter API Key",
    "You want to delete this manager ?": "You want to delete this manager ?",
    "Password does not match": "Password does not match",
    "Select Manager": "Select Manager",
    "Wholesale Orders": "Wholesale Orders",
    "Cart Name": "Cart Name",
    "Select Cart Name": "Select Cart Name",
    "Add Cart": "Add cart",
    "Cart #": "Cart #",
    Review: "Review",
    "Problem Title": "Problem Title",
    "Problem Type": "Problem Type",
    "Move copy from the repository": "Move copy from the repository",
    referenceNumber: "Reference Number",
    playerId: "Player ID",

    Buying: "Buying",
    "Domain To": "Domain To",
    "Domain From": "Domain From",
    "Order Creator": "Order Creator",
    "Account Type of Creator": "Account Type of Creator",
    "Type of Order": "Type of Order",
    "Enter Cart Name": "Enter Cart Name",
    "You want to delete this cart ?": "You want to delete this cart ?",
    "Confirm adding orders to distributor":
      "Confirm adding orders to distributor",
    "Confirm adding orders to": "Confirm adding orders to",
    "from the cart": "from the cart",
    number: "Number",
    "Purchase order number": "Purchase order number",
    "Purchase order date": "Purchase order date",
    "Review purchase orders": "Review purchase order",
    "Review Order": "Review Order",
    Cart: "Cart",
    "Purchase Order Information": "Purchase Order Information",
    "Purchase order value": "Purchase order value",
    "Private API Key": "Private API Key",
    "Public API Key": "Public API Key",
    "Actual Value": "Actual Value",
    "Without Tax": "Without Tax",
    "With Tax": "With Tax",
    totalAmountVisa: "Credit card total amount",
    Runtime: "Runtime",
    "Invalid Qty": "Invalid Qty",
    "Price With Tax": "Price With Tax",
    "Transaction Value": "Transaction Value",
    "Card Rating": "Card Rating",
    "Card Category": "Card Category",
    "Invoice password": "Invoice password",
    "Payment type": "Payment type",
    "Only one card category must be selected for the copy to be taken":
      "Only one card category must be selected for the copy to be taken",
    "Only one card must be selected for the copy to be taken":
      "Only one card must be selected for the copy to be taken",
    "Bank account": "Bank account",
    "Total discount value": "Total discount value",
    "Invoice date": "Invoice date",
    "Select Country": "Select Country",
    "Beneficiary Name": "Beneficiary Name",
    "Select Beneficiary Name": "Select Beneficiary Name",
    "Company Account": "Company Account",
    "Select Company Account": "Select Company Account",
    "Invoice Password": "Invoice Password",
    "Reset Password": "Reset Password",
    "Orders Total After Discount": "Orders Total After Discount",
    "Discount Type": "Discount Type",
    "Discount Value": "Discount Value",
    "Select Discount Type": "Select Discount Type",
    "Select Discount": "Select Discount",
    "Card Rating": "Card Rating",
    "Select Card Rating": "Select Card Rating",
    "Send Invoice by email": "Send Invoice by email",
    Company: "Company",
    "Close the financial audit": "Close the financial audit",
    "Reference # of (Wholesale  or API Order)":
      "Reference # of (Wholesale  or API Order)",
    "Select Company": "Select Company",
    "Have Discount": "Have Discount",
    "Invoice Report": "Invoices Report",
    "Refernce Number": "Refernce Number",
    "Serial Number": "Serial Number",
    "View Invoice": "View Invoice",
    "Invoice Total": "Invoice Total",
    "Review Request Data": "Review Request Data",
    Distributor: "Distributor",
    "The number must be decimal": "The number must be a decimal",
    "The number must be Integer": "The number must be an Integer",
    "The number must be between 0 and ": "The number must be between 0 and ",
    "The number must be between 0.0 and 0.99":
      "The number must be between 0.0 and 0.99",
    "Class #": "Class #",
    "First Note": "First Note",
    "Second Note": "Second Note",
    "Cost price in warehouse": "Cost price in warehouse",
    "Buying price at distributor": "Buying price at distributor",
    "Point selling price": "Point selling price",
    Profit: "Profit",
    "Select Provider Name": "Select Provider Name",
    "Point name": "Point name",
    "The service is not currently supported by this supplier.":
      "The service is not currently supported by this supplier.",
    "Select Filter Mode": "Select Filter Mode",
    "Select Type Insert": "Select Type Insert",
    "Select Supplier": "Select Supplier",
    "Select Class": "Select Class",
    Class: "Class",
    "Cards Number": "Cards Number",
    "Buy Price": "Buy Price",
    "Sale Price": "Sale Price",
    "Currency Code": "Currency Code",
    "Currency Number": "Currency Number",
    "Currency Price": "Currency Price",
    "Currencies List": "Currencies List",
    "Add Currency": "Add Currency",
    Enter: "Enter",
    Files: "Files",
    "Export Cards Files": "Export the Wrong Cards Files",
    "Export the wrong cards": "Export the wrong cards",
    "Create a purchase order": "Create purchase order",
    "Pull Cards": "Pull Cards",
    "You want to delete this currency ?": "You want to delete this currency ?",
    Attached: "Attached",
    "You want to delete this attached ?": "You want to delete this attached ?",
    Attachments: "Attachments",
    "Add Attached": "Add Attached",
    "Attachments List": "Attachments List",
    "Account Information": "Account Information",
    "Bank Account Information": "Bank Account Information",
    Branch: "Branch",
    "Account Name": "Account Name",
    "Swift Code": "Swift Code",
    "IBAN Id": "IBAN Id",
    "Select Day": "Select Day",
    "Account Number": "Account Number",
    "Company Information": "Company Information",
    "Add Account": "Add Account",
    "Trade License Number": "Trade License Number",
    "More Data": "More Data",
    "Purchase Tax": "Purchase Tax",
    "You want to delete this company ?": "You want to delete this company ?",
    "Seal Image": "Seal Image",
    "Logo Image": "Logo Image",
    Account: "Account",
    "Admin Name": "Admin Name",
    "To Card Type": "To Card Type",
    "To Card": "To Card",
    "To Card Class": "To Card Class",
    "From Card Type": "From Card Type",
    "From Card": "From Card",
    "From Card Class": "From Card Class",
    "CIF Id": "CIF Id",
    "Required card classes": "Required card classes",
    "Opening balance": "Opening balance",
    Regions: "Regions",
    open: "Open",
    "The amount withheld for tax": "The amount withheld for tax",
    "Regions List": "Regions List",
    "Regions In Arabic": "Regions In Arabic",
    "Regions In English": "Regions In English",
    "You want to delete this bank ?": "You want to delete this bank ?",
    "You want to delete this region ?": "You want to delete this region ?",
    Region: "Region",
    "Change Password": "Change Password",
    "WhatsApp Client Number": "WhatsApp Client Number",
    "WhatsApp Channel Number": "WhatsApp Channel Number",
    "Direct Charge Countries": "Direct Charge Countries",
    "Select Direct Charge Countries": "Select Direct Charge Countries",
    "Countries List": "Countries List",
    Countries: "Countries",
    "Orders Creator Phone": "Orders Creator Phone",
    "Orders Creator Email": "Orders Creator Email",
    "Direct Charge": "Direct Charge",
    "Direct Charge Type": "Direct Charge Type",
    "Excel type": "Excel",
    "Incomplete Orders": "Incomplete Orders",
    "Country Status": "Country Status",
    "Number Length": "Number Length",
    "Country Key": "Country Key",
    "Country Name": "Country Name",
    Deleted: "Deleted",
    "It is not deleted": "It is not deleted",
    "Select Country Name": "Select Country Name",
    "Country name in English": "Country name in English",
    "Country name in Arabic": "Country name in Arabic",
    "Note: Number length must be without country code and zero":
      "Note: Number length must be without country code and zero",
    Trash: "Trash",
    "Select Deleted Types": "Select Type",
    "Types of Deleted": "Types of Deleted",
    ID: "ID",
    "The quantity should not less than ": "The quantity should not less than ",
    "The quantity should not be more than ":
      "The quantity should not be more than ",
    "Form Type": "Form Type",
    "The Amount Paid": "The Amount Paid",
    "You want to delete all days ?": "You want to delete all days ?",
    "You want to delete this day ?": "You want to delete this day ?",
    "You want to delete all days from this card and every classes that  under its ?":
      "You want to delete all days from this card and every classes that  under its ?",
    "You want to delete this day from this card and every classes that  under its ?":
      "You want to delete this day from this card and every classes that  under its ?",
    "Delete All Days": "Delete All Days",
    "Entry Date at Stock": "Entry Date at Stock",
    "Creation Date": "Creation Date",
    "Used Date at Stock": "Used Date at Stock",
    "Used Date": "Used Date",
    "Correction Info": "Correction Info",
    "Correction Date": "Correction Date",
    "Corrector Name": "Corrector Name",
    "View Order Problems": "View Order Problems",
    Number: "Number",
    Domain: "Domain",
    "Move copy with pictures": "Move copy with pictures",
    "Move copy with the same number": "Move copy with the same number",
    "Select the bank to upload the file": "Select the bank to upload the file",
    "Select the cards to upload the file":
      "Select the cards to upload the file",
    "Select file template and supplier to display cards":
      "Select file template and supplier to display cards",
    Dollar: "Dollar",
    "Total Qty": "Total Qty",
    "You want to restore this item ?": "You want to restore this item ?",
    "Supplier Block Time": "Supplier Block Time",
    "Please enter the time in minutes": "Please enter the time in minutes",
    "Ding Countries": "Ding Countries",
    Update: "Update",
    "Distributor Commission & POS E-fawateercom":
      "Distributor Commission & POS E-fawateercom",
    "Commission List": "Commission List",
    Commission: "Commission",
    "Status Note": "Status Note",
    "Edit the Classes Buffer": "Edit the Classes Buffer",
    "API Type": "API Type",
    "Profit Percentage": "Profit Percentage",
    "Distributor Commission": "Distributor Commission",
    "POS Commission": "POS Commission",
    "Invoice Information": "Invoice Information",
    Invoice: "Invoice",
    "Distributor Information": "Distributor Information",
    margin: "Margin",
    ") ?": ") ?",
    "Conversion Reasons": "Conversion Reasons",
    Reason: "Reason",
    "You want to deactivate this card class ":
      "You want to deactivate this card class ",
    "You want to deactivate this card ": "You want to deactivate this card ",
    "You want to deactivate this category ":
      "You want to deactivate this category ",

    "Get POS code": "Get code for the POS app",
    "You want to activate this card class ":
      "You want to activate this card class ",
    "You want to activate this card ": "You want to activate this card ",
    "You want to activate this category ":
      "You want to activate this category ",
    "Search By Name": "Search By Name",
    "Reason In Arabic": "Reason In Arabic",
    "Reason In English": "Reason In English",
    "You want to delete this reason ?": "You want to delete this reason ?",
    "Account Statement Dist And Pos": "Account Statement Distributors and Pos",
    "Balance in distributor currency": "Balance in distributor currency",
    "Amount in distributor currency": "Amount in distributor currency",
    "Terms and Conditions": "Terms and Conditions",
    "How to use": "How to use",
    "Inquiries about POS": "Inquiries about POS",
    "Distributor Id": "Distributor Id",
    "Point Id": "Point Id",
    "Employee Name": "Employee Name",
    "Employee Number": "Employee Number",
    "Price After Discount": "Price After Discount",
    "Point Name": "Point Name",
    "Financial operations for all distributors":
      "Financial operations for all distributors",
    "Distributor Balance": "Distributor Balance",
    "Bank Number": "Bank Number",
    "Required shipping amount": "Required shipping amount",
    "All financial operations": "All financial operations",
    "Balance Recharge Requests": "Balance Recharge Requests",
    "Order Value": "Order Value",
    Totals: "Totals",
    "Balance recharge order number": "Balance recharge order number",
    "Transfer currency": "Transfer Currency",
    Select: "Select",
    "File Upload Forms": "File Upload Forms",
    "From Card": "From Card",
    "To Card": "To Card",
    "Correction completed successfully": "Correction completed successfully",
    "Addition completed successfully": "Addition completed successfully",
    "Prices will be updated for all cards and all POS pricing categories except for the cards you choose from the search filters":
      "Prices will be updated for all cards and all POS pricing categories except for the cards you choose from the search filters",
    "Prices will be updated for all cards for the cards you choose from the search filters":
      "Prices will be updated for all cards except for the cards you choose from the search filters",
    "Prices will be updated for all card POS pricing categories except for the cards you choose from the search filters":
      "Prices will be updated for all cards POS pricing categories except for the cards you choose from the search filters",
    "In conversion rate is equal : ": "Conversion rate is equal : ",
    "Transaction Amount": "Transaction Amount",
    "Conversion Reason": "Conversion Reason",
    "Receiver Bank": "Receiver Bank",
    receiverName: "Receiver Name",
    receive: "receive",
    "Add tax value": "Add Tax Value",
    "Sending Bank": "Sending Bank",
    "Current Account": "Current Account",
    "Another Account": "Another Account",
    "Transfer Image": "Transfer Image",
    "You want to delete this order ?": "You want to delete this order ?",
    "Shipping order information": "Shipping order information",
    "Request Date": "Request Date",
    "Modify prices by conversion rate": "Modify prices by conversion rate",
    Accept: "Accept",
    "Final Amount": "Final Amount",
    "Total POS Balances": "Total POS Balances",
    "PayThem Cards": "PayThem Cards",
    "Update PayThem Cards": "Update PayThem Cards",
    "Ezpin Cards": "Ezpin Cards",
    "Update Ezpin Cards": "Update Ezpin Cards",
    "PrePaidForge Cards": "PrePaidForge Cards",
    "Update PrePaidForge Cards": "Update PrePaidForge Cards",
    "Joker Cards": "Joker Cards",
    "Update Joker Cards": "Update Joker Cards",
    "Gift2Game Cards": "Gift2Game Cards",
    "Update Gift2Game Cards": "Update Gift2Game Cards",
    "OneCard Cards": "OneCard Cards",
    "Update OneCard Cards": "Update OneCard Cards",
    "Ding Cards": "Ding Cards",
    "Update Ding Cards": "Update Ding Cards",
    "Egifter Cards": "Egifter Cards",
    "Available Classes": "Available Classes",
    "Card Id": "Card Id",
    "You want to create this purchase order ?":
      "You want to create this purchase order ?",
    General: "General",
    "Cards available to the supplier": "Cards available to the supplier",
    "Allow work with Ding": "Allow work with Ding",
    "Cards are not available to the supplier":
      "Cards are not available to the supplier",
    "Invalid codes": "Invalid codes",
    "Cards not found in the database": "Cards not registered in the warehouse",
    "Number Print Times": "Number Print Times",
    "Processing Reserved Cards": "Processing Reserved Cards",
    CardsProfitInventoryReportAPI: "Cards Profit Inventory Report API",
    "All Categories Sort": "All Categories",
    "Card Code": "Card Code",
    closed: "Closed",
    pending: "Pending",
    "Card Inquiry": "Card Inquiry",
    "Provider Code": "Provider Code",
    "Type of services": "Type of services",
    "Po Number": "Po Number",
    "Alternative Name": "Alternative Name",
    "Enter Alternative Name": "Enter Alternative Name",
    "Alternative Names": "Alternative Names",
    "Enter Alternative Names": "Enter Alternative Names",
    "Po Number": "Po Number",
    "Quantity Per Day": "Quantity Per Day",
    "Enter Quantity Per Day": "Enter Quantity Per Day",
    "Print Times": "Print Times",
    "Direct Shipping Report": "Direct Shipping Report",
    "Usage time": "Usage time",
    "Activate Distributors Cards": "Activate Distributors Cards",
    Actions: "Actions",
    "Not available to the supplier": "Not available to the supplier",
    "Not registered in the warehouse": "Not registered in the warehouse",
    Deactive: "Deactive",
    Activate: "Activate",
    "Requested Qty": "Requested Qty",
    "The number of cards in the file : ": "The number of cards in the file : ",
    Deactivated: "Deactivate",
    "Data Type": "Data Type",
    "Are you want to activate all cards for all POS ?":
      "Are you want to activate all cards for all POS ?",
    "Are you want to deactivate all cards for all POS ?":
      "Are you want to deactivate all cards for all POS ?",
    "They are used to raise cards to the inventory from more than one class":
      "They are used to raise cards to the inventory from more than one class",
    Deactivate: "Deactivate",
    "Sending Date": "Sending Date",
    "Ban Date": "Ban Date",
    "There are no cards available": "There are no cards available",
    "Cancellation Date": "Cancellation Date",
    referenceId: "Reference ID",
    "Cancellation Time": "Cancellation Time",
    "POS Supervisor Name": "POS Supervisor Name",
    "Select POS Supervisor": "Select POS Supervisor",
    "Class Id": "Class Id",
    "Transfer Price": "Transfer Price",
    "Number Of Cards Requested From Supplier":
      "Number Of Cards Requested From Supplier",
    "Enter Number Of Cards Requested From Supplier":
      "Enter Number Of Cards Requested From Supplier",
    "Unlock All Request Suppliers": "Unlock All Request Suppliers",
    "Supplier Code": "Supplier Code",
    "Number of Requests": "Number of Requests",
    "Total Revolving Balance": "Total Revolving Balance",
    "Status in Buffer": "Status in Buffer",
    "Distributors Warehouse": "Distributors Warehouse",
    "You want to unlock this supplier request ?":
      "You want to unlock this supplier request ?",
    "You want to unlock all suppliers request ?":
      "You want to unlock all suppliers request ?",
    "Supplier Cards Held In Buffer": "Supplier Cards Held In Buffer",
    "Account Balances": "Account Balances",
    "Sorry, you can't search for more than 30 days":
      "Sorry, you can't search for more than 30 days",
    "Account Balances Report": "Account Balances Report",
    "Your Total Balance": "Your Total Balance ",
    "Total Balance": "Total Balance",
    "Card Status": "Card Status",
    "View Details": "View Details",
    "Job Name": "Job Name",
    "Form Name": "Form Name",
    "Form Name In English": "Form Name In English",
    "Enter Form Name": "Enter Form Name",
    "Enter Form Name In English": "Enter Form Name In English",
    Form: "Form",
    "Problem Chat": "Problem Chat",
    "View Chat": "View Chat",
    "You cannot select this domain. It has already been selected":
      "You cannot select this domain. It has already been selected",
    "Discount Name": "Discount Name",
    "Discount Name In English": "Discount Name In English",
    "Enter Discount Name": "Enter Discount Name",
    "Enter Discount Name In English": "Enter Discount Name In English",
    Discount: "Discount",
    "You want to delete this order problem ?":
      "You want to delete this order problem ?",
    "Discount Add Balance": "Discount Add Balance",
    "Upload Complete": "Upload Complete",
    "Select Reference #": "Select Reference #",
    "Add Form": "Add Form",
    "Add Discount": "Add Discount",
    "Edit Form": "Edit Form",
    "Bank Transfer Image": "Bank Transfer Image",
    "Net Transfer": "Net Transfer",
    "Correction Confirmation": "Correction Confirmation",
    "Correction/return to the warehouse": "Correction/return to the warehouse",
    "Correction/return to the distributor":
      "Correction/return to the distributor",
    "Return to the warehouse": "Return to the warehouse",
    "Return to the distributor": "Return to the distributor",
    "Use in orders": "Use in orders",
    "Active for use in orders": "Active for use in orders",
    "Not effective for orders": "Not effective for orders",
    "Reprint POS Orders": "Reprint POS Orders",
    "SEARCH TO ALLOW REPRINTING": "SEARCH TO ALLOW REPRINTING",
    "Startup date/time": "Startup date/time",
    "Operation end date/time": "Operation end date/time",
    "Admin Id": "Admin Id",
    closedStatus: "Closed status",
    "Conversion In Multiplication": "convert prices up",
    "Conversion In Divide": "Convert prices down",
    "Conversion Rate": "Conversion Rate",
    "Enter Conversion Rate": "Enter Conversion Rate",
    "Suppliers Api": "Suppliers Api",
    "View API Suppliers": "View API Suppliers",
    Commitments: "Commitments",
    "Files Forms": "Files Forms",
    "Modify All Prices": "Modify All Prices",
    "Modify Buy Prices": "Modify Buy Prices",
    "Modify POS Classes Prices": "Modify POS Classes Prices",
    "Total Balances": "Total Balances",
    "Other Obligations": "Other Obligations",
    "Ding percentage": "Ding percentage",
    "The sum of other obligations": "The sum of other obligations",
    "View Other Obligations": "View Other Obligations",
    "List Of Special Numbers": "List Of Special Numbers",
    "(Calculated Numbers)": "(Calculated Numbers)",
    Determine: "Determine",
    "Distributors Classes": "Distributors Classes",
    "Distributor Class Name": "Distributor Class Name",
    "Select Distributor Class": "Select Distributor Class",
    "Upload Excel": "Upload Excel",
    "Upload Excel Multiple": "Upload Excel Multiple",
    Error: "Error",
    "You cannot export more than one card file at a time":
      "You cannot export more than one card file at a time",
    "Duplicate codes": "The codes are duplicated",
    "Supplier Type": "Supplier Type",
    "Select Supplier Type": "Select Supplier Type",
    "Suppliers Prices": "Suppliers Prices",
    "Error Number": "Error Number",
    "All Countries": "All Countries",
    "All Services": "All Services",
    "All Providers": "All Providers",
    "All Products": "All Products",
    "All Services Classes": "All Services Classes",
    Services: "Services",
    Providers: "Providers",
    Products: "Products",
    Corrected: "Corrected",
    "Deactivate Cards": "Activate and Deactivate Cards",
    "Corrected using the method": "Corrected using the method",
    "The order will be corrected using a method":
      "The order will be corrected using a method",
    "Without Supplier": "Without Supplier",
    "Services Classes": "Services Classes",
    "Order Id": "Order Id",
    "Order Amount": "Order Amount",
    "Actual Order Amount": "Actual Order Amount",
    "Order Date/Time": "Order Date/Time",
    "Note Date/Time": "Note Date/Time",
    "Reprint Date/Time": "Reprint Date/Time",
    "Date/Time": "Date/Time",
    "Show only": "Show only",
    Model: "Model",
    "Cards Transfer": "Cards Transfer",
    "Direct Charge order information": "Direct Charge Order Information",
    "Add Note": "Add Note",
    debit: "Debit",
    "Edit Note": "Edit Note",
    "last pay": "Last pay",
    Unlock: "Unlock",
    "Tax Amount": "Tax Amount",
    "Trans Id": "Trans Id",
    "Activate Tax Categories": "Activate Tax Categories",
    "Activate Tax Categories for Distributors":
      "Activate Tax Categories for Distributors",
    "There's are no any distributor is taxed":
      "There's are no any distributor is taxed",
    "POS Id": "POS Id",
    "Prioritizing Suppliers": "Prioritizing Suppliers",
    "Prioritizing Suppliers for Classes": "Prioritizing Suppliers for Classes",
    "The POS has not been credited": "The POS has not been credited",
    "The distributor has been discounted":
      "The distributor has been discounted",
    "Payment received": "Payment received",
    "Waiting for the receipt": "Waiting for the receipt",
    "The payment was declined": "The payment was declined",
    "Select Order Type": "Select Order Type",
    "Order Type": "Order Type",
    "Duplicate Cards": "Duplicate Cards",
    "POS Supervisor": "POS Supervisor",
    "Telephone Number": "Telephone Number",
    "Enter Telephone Number": "Enter Telephone Number",
    "Daily max pay limit": "Daily max pay limit",
    "Enter Daily max pay limit": "Enter Daily max pay limit",
    "Postal Code": "Postal Code",
    "Enter Postal Code": "Postal Code",
    "Phone Number": "Phone Number",
    "Enter Phone Number": "Enter Phone Number",
    "Order Cards": "Order Cards",
    "Transaction Type": "Transaction Type",
    "Edit Inventory Supplier for Classes":
      "Edit Inventory Supplier for Classes",
    "Reason for withdrawing the cards": "Reason for withdrawing the cards",
    "Enter reason for withdrawing the cards":
      "Enter reason for withdrawing the cards",
    "Order Note": "Order Note",
    "Cards Profit Inventory Report With Bank Info":
      "Cards Profit Inventory Report With Bank Info",
    "Total Summation": "Total Summation",
    "Report Name": "Report Name",
    "Domain Name": "Domain Name",
    "Efawateercom percentage": "Efawateercom percentage",
    "Allow work with DTone": "Allow work with DTone",
    "All Categories": "All Categories",
    "Requesting Date": "Requesting Date",
    "Search Date": "Search Date",
    "Requests For Reports": "Requests For Reports",
    "API Suppliers Balances": "API Suppliers Balances",
    "Entry Number": "Entry Number",
    "Number Sold": "Number Sold",
    "Sale Date": "Sale Date",
    "Sale Info": "Sale Info",
    Link: "Link",
    "Purchase Request # (Class)": "Purchase Request # (Class)",
    "There are no cards to display": "There are no cards to display",
    "Selected category does not have any card":
      "Selected category does not have any card",
    "Selected card do not have any card class":
      "Selected card does not have any card class",
    "Activate and deactivate the class": "Activate and deactivate the class",
    "Disable it from orders using history":
      "Disable it from orders using history",
    "Disable it from orders using days": "Disable it from orders using days",
    "Balance Title": "Balance",
    "Select type disable": "Select type disable",
    "Enter Link": "Enter Link",
    "close problem status": "Are you sure that you want to close this ticket?",
    "Export Sales Details": "Export Sales Details",
    "Show Sold Number": "Show Sold Number",
    "Cards Inventory Report": "Cards Inventory Report",
    "Linked To Inventory": "Linked To Inventory",
    "Priority In Buffer": "Priority In Buffer",
    "API Suppliers": "API Suppliers",
    "View API Suppliers Balance": "View API Suppliers Balance",
    "View Sell Details": "View Sell Details",
    "API Suppliers For The Class": "API Suppliers For The Class",
    "Prices Saved Successfully": "Prices Saved Successfully",
    "Distributor Cards Prices": "Distributor Cards Prices",
    "Cards Prices": "Cards Prices",
    "POS Cards Profit Inventory Report": "POS Cards Profit Inventory Report",
    "View Other Obligations": "View Other Obligations",
    "Total Cost Price": "Total Cost Price",
    "Total Cost Prices": "Total Cost Price",
    "Total Cost": "Total Cost",
    Excel: "Excel",
    API: "API",
    "Excel Suppliers": "Excel Suppliers",
    "Notification Email": "Notification Email",
    Day: "Day",
    From: "From",
    "There are no cards in the inventory associated with this supplier and file template":
      "There are no cards in the inventory associated with this supplier and file template",
    "No financial transaction has been added to this order":
      "No financial transaction has been added to this order",
    compensation: "Balance compensation",
    "Bills requests": "Bills requests",
    replacing: "Replace card",
    cardCharge: "Charge card",
    problemSolved: "Problem solved",
    "Save code": "Save code",
    To: "To",
    "Main Supplier": "Main Supplier",
    "Get code": "Get code",
    "Select Type from Types of Deleted": "Select Type from Types of Deleted",
    "Cron Job Report": "Cron Job Report",
    "Total balance additions": "Total balance additions",
    "Total balance corrections": "Total balance corrections",
    "Total additions to POS": "Total additions to POS",
    "Total corrections to POS": "Total corrections to POS",
    "There is no cards available in stock":
      "There is no cards available in stock",
    "Ip Address": "Ip Address",
    "Add Credits via Points Manager": "Add Balance via POS Manager",
    "Add Credits": "Add Credit",
    "DTone Configrations": "DTone Configrations",
    "DTone Countries": "DTone Countries",
    "API identifier": "معرف API",

    pay: "Pay bills",
    "DTone Services": "DTone Services",
    "DTone Providers": "DTone Providers",
    "DTone Products": "DTone Products",
    "SEARCH BY CODE": "SEARCH BY CODE",
    "EXPORT BY REFERNCE #": "EXPORT BY REFERNCE #",
    "DTone Services Classes": "DTone Services Classes",
    "Service Id": "Service Id",
    "Orders Notes": "POS Orders Notes",
    "Employee Name": "Employee Name",
    remainingAmount: "Remaining Amount",
    "Select Employee": "Select Employee",
    "Service Name": "Service Name",
    "Select Service Name": "Select Service Name",
    "Balance withdrawal via POS Manager": "Amount correction via POS Manager",
    "Balance withdrawal": "Amount correction",
    "Provider Code": "Provider Code",
    "Provider Name": "Provider Name",
    "Service Status": "Service Status",
    "Service Class Name": "Service Class Name",
    "Receivables Value": "Receivables Value",
    Receivables: "Receivables",
    "Enter supplier data": "Enter supplier data",
    payFromDistMoney: "Pay from Distributer Money",
    deleteAction: "Are you sure that you want to do this action",
    deleteName: "Delete",
    noAction: "No actions",
    "Select Service Class Name": "Select Service Class Name",
    "Service Class Status": "Service Class Status",
    "enter confirm amount": "enter confirm amount",
    Request: "Request",
    "Add Credit To POS": "Add Credit To POS",
    "User level": "User level",
    Paid: "Paid",
    "Select Payment type": "Select Payment type",
    maxDebtBillsNum: "Max Debt Bills Number",
    maxCashInHand: "Max cash in  hand",
    maxBillValue: "Max bill value",
    "Card Name In File": "Card Name In File",
    "sent my pos and in audit": "sent my pos and in audit",
    "canceled by pos": "canceled by pos",
    "canceled by representative": "canceled by representative",
    "sent by representative": "sent by representative",
    "Add a Distributer": "Add a Distributer",
    "Upload the file Now": "Upload the file Now",
    "Select another class to transfer cards from, it is not possible to transfer to and from the same class":
      "Select another class to transfer cards from, it is not possible to transfer to and from the same class",
    "Select another class to transfer cards to, it is not possible to transfer to and from the same class":
      "Select another class to transfer cards to, it is not possible to transfer to and from the same class",
    "Wholesale Request #": "Wholesale Request #",
    "CP Reference #": "CP Reference #",
    "Export Cards": "Export Cards",
    "Setting Type": "Setting Type",
    "Point of sale application Email": "Point of sale application Email",
    "Number of codes already exist:": "Number of codes already exist:",
    "Total Buy Price": "Total Buy Price",
    "Update Your Password": "Update Your Password",
    "Total Sale Price": "Total Sale Price",
    "IP Address": "IP Address",
    totalInvoicesAmount: "Total Invoices Amount",
    totalInvoicesRemaningAmount: "Total Invoices Remaining Amount",
    ReceivablesFlow: "Receivables Flow",
    "Prices Adjustment": "Prices Adjustment",
    "transferable employees": "transferable employees",

    "Incomplete Credit Additions": "Incomplete Credit Additions",
    "Orders Processing": "Orders Processing",
    "Do u want to also remove the pos balance?":
      "Do u want to also remove the pos balance?",
    "Cancel and remove POS balance": "Cancel and remove POS balance",
    "Cancel but keep POS balance": "Cancel but keep POS balance",
    "Request Generator": "Request Generator",
    "The account number it belongs to": "The account number it belongs to",
    "Balance withdrawal From POS": "Amount correction to POS",
    "Profit From POS": "Profit From POS",
    "Repayment of loan-Paid": "Repayment of loan-Paid",
    "Add Bank Info": "Add Bank Info",
    "Value of payments": "Value of payments",
    "Login Type": "Login Type",
    "dist type": "Distributer Type",
    "Duplicate Orders Report": "Duplicate Orders Report",
    "Repeat Cards Report": "Repeat Cards Report",
    "The distributor has been discountedThe point has not been credited":
      "The distributor has been discountedThe point has not been credited",
    "Please select only one distributor to be able to export the invoice":
      "Please select only one distributor to be able to export the invoice",
    "You cannot export the invoice because the report does not contain data":
      "You cannot export the invoice because the report does not contain data",
    "Select a distributor in order to show the data":
      "Select a distributor in order to show the data",
    totalSent: "Total sent",
    totalReceived: "Total Received",
    "Select a distributor class in order to show the data":
      "Select a distributor class in order to show the data",
    "Passwords not match": "Passwords not match",
    totalDistBalance: "total Distributers Balance",
    "Old Password": "Old Password",
    "New Password": "New Password",
    "Confirm New Password": "Confirm New Password",
    accepted: "accepted",
    waiting: "waiting",
    rejected: "rejected",
    "Select Report Name": "Select Report Name",
    "Select Domain Name": "Select Domain Name",
    "Request Id": "Phone Number",
    "Recharge Id": "Recharge Id",
    "Recharge PIN": "Recharge PIN",
    "Point of sales Financial Report": "Employees Financial Report",
    "Assignment Date": "Assignment Date",
    "POS supervisors": "POS supervisors",
    "Allow openBills": "Allow billing system",
    "Note Admin": "Note Admin",
    "Enter the From date and the To date for orders to be sent via email":
      "Enter the From date and the To date for orders to be sent via email",
    "You need to update your password to be able to login":
      "You need to update your password to be able to login",
    "Enter a from and date for the orders to be exported":
      "Enter a from and date for the orders to be exported",
    "Entered by": "Entered by",
    "POS address": "POS address",
    "Type Title": "Type Title",
    "Status Title": "Status Title",
    "Select order type and Enter code to display data":
      "Select order type and Enter code to display data",
    "POS Application Email": "POS Application Email",
    "Enter POS Application Email": "Enter POS Application Email",
    "Email [Recharge POS]": "Email [Recharge POS]",
    "Enter Email [Recharge POS]": "Enter Email [Recharge POS]",
    "Buy Price Distributor": "Buy Price Distributor",
    "Requset Date": "Requset Date",
    formTitle: "Form",
    "POS supervisors & POS supervisors supervisors":
      "POS supervisors & POS supervisors supervisors",
    "Request Result Date": "Request Result Date",
    "Pos Categories Name": "Pos Categories Name",
    "Pos Price Categories": "Pos Price Categories",
    "Google Auth Key": "Google Auth Key",
    "Select Pos Categories": "Select Pos Categories",
    "Responsible staff": "Responsible staff",
    "Close Status": "Close ticket",
    "Request Result": "Request Result",
    "Acount Type": "Account Type",
    "Employee Name": "Employee Name",
    "Employee Account Type": "Employee Account Type",
    "Please enter the code or card serial number to be able to search":
      "Please enter the code or card serial number to be able to search",
    "Manger POS": "Manger POS",
    Accountant: "Accountant",
    "Not Corrected": "Not Corrected",
    Corrected: "Corrected",
    Sold: "Sold",
    doneSucessfully: "Done Successfully ",
    "Select Form Type": "Select Form Type",
    "Login Phone Number": "Login Phone Number",
    "Inserted By": "Inserted By",
    "Transaction Inserted By": "Transaction Inserted By",
    "Activate status to all classes": "Activate status to all classes",
    "Deactivate status to all classes": "Deactivate status to all classes",
    "Deactivate Type": "Deactivate Type",
    "Deactivation Days": "Deactivation Days",
    "Point of sale image": "Point of sale image",
    "Allow work with e-fawatercom": "Allow work with e-fawatercom",
    "Start Time": "Start Time",
    "End Time": "End Time",
    "Distributor Type": "Distributor Type",
    "Efawateercom precentage": "Efawateercom precentage",
    "Bill status": "Bill status",
    "Transactions type": "Transactions type",
    "Email address for charging points of sale balance":
      "Email address for charging points of sale balance",
    "Select Distributor Type": "Select Distributor Type",
    "Canceled Order": "Canceled Order",
    "Not Completed Order": "Not Completed Order",
    "Add a Bill": "Add a Bill",
    "Cards Value for Distributor": "Cards Value for Distributor",
    "Cards Value for POS": "Cards Value for POS",
    "Direct Charge Suppliers": "Direct Charge Suppliers",
    "Modified Successfully": "Modified Successfully",
    "Added Successfully": "Added Successfully",
    "Correct All Codes": "Correct All Codes",
    priceCat: "Price Category",
    "Update token": "Update token",
    "Inv #": "Code Id",
    "API identifier to distributor": "API identifier to distributor",
    "Select Date Type": "Select Date Type",
    "Date Type": "Date Type",
    "add/remove addresses": "add/remove addresses",
    "These operations have been performed correctly since the order was created":
      "These operations have been performed correctly since the order was created",
    "Operations performed to correct the order":
      "Operations performed to correct the order",
    "Add payment": "Add payment",
    tranDebit: "Debit transfer",

    "Allow Reprinting": "Allow Reprinting",
    "Main Code": "Main Code",
    "Enter Main Code": "Enter Main Code",
    "Secondary Code": "Secondary Code",
    "Enter Secondary Code": "Enter Secondary Code",
    "With Hash": "With Hash",
    sentDate: "Sent Name",
    confirmDate: "Confirm Date",
    Neighborhood: "Neighborhood",
    "Select Neighborhood": "Select Neighborhood",
    "Are you want to fix order No. ": "Are you want to fix order No. ",
    "Are you want to fix all orders ?": "Are you want to fix all orders ?",
    "Do you want to allow the POS ": "Do you want to allow the POS ",
    "to re-print an order number ": "to re-print an order number ",
    "View Reserved Cards at Order": "View Reserved Cards at Order",
    "Actions Director": "Actions Director",
    "Charge inquiry type": "Charge inquiry type",
    "POS Supervisor Manger": " Supervisor Manger",
    "Select charge inquiry type": "Select charge inquiry type",
    "Number of codes that do not contain a PIN or that there was a problem when requesting it:":
      "Number of codes that do not contain a PIN or that there was a problem when requesting it:",
    "The maximum value that a POS can request through this category":
      "The maximum value that a POS can request through this category",
    "Select Acount Type": "Select Account Type",
    "This category will be assigned to the Ding property and removed from all other categories":
      "This category will be assigned to the Ding property and removed from all other categories",
    "This category will be assigned to the Dtone property and removed from all other categories":
      "This category will be assigned to the Dtone property and removed from all other categories",
    "Logs Requests API To Distributors": "Logs Requests API To Distributors",
    "Cards not available": "Cards not available",
    payAmount: "Pay Amount",
    "Do you want to return the card to the inventory to use it again?":
      "Do you want to return the code to the inventory to use it again?",
    maxCashDebit: "Max cash debit",
    totalPosCount: "Total POS Count",
    "You want to confirm this ?": "You want to confirm this ?",
    "Go Back Home": "Go Back Home",
    InvoicesQty: "Invoices count",
    operationclose: "operation close",
    operationportion: "operation portion",
    "Invoice Creator": "Invoice Creator",
    "OOPS! Something went wrong here": "OOPS! Something went wrong here",
    "Do you want to return all cards to the inventory to use them again ?":
      "Do you want to return all reserved cards from this order to the inventory for further use?",
    "Please enter a valid amount": "Please enter a valid amount",
    "Please select a type of operation": "Please select a type of operation",
    "Total balances of POS managers": "Total balances of POS managers",
    "Do you want to correct this order using the return-to-repository method ?":
      "Do you want to correct this order using the return-to-repository method ?",
    "Do you want to correct this order using the return-to-distributor method ?":
      "Do you want to correct this order using the return-to-distributor method ?",
    "You cannot search by reprint date because you want to allow reprints":
      "You cannot search by reprint date because you want to allow reprints",
    "Are you sure you want to update the prices for all card POS pricing categories except for the cards you choose from the search filters?":
      "Are you sure you want to update the prices for all cards POS pricing categories except for the cards you choose from the search filters?",
    "Are you sure you want to update the prices for all cards and all POS pricing categories except for the cards you choose from the search filters?":
      "Are you sure you want to update the prices for all cards and all POS pricing categories except for the cards you choose from the search filters?",
    "Are you sure you want to update the prices for all cards except for the cards you choose from the search filters?":
      "Are you sure you want to update the prices for all cards except for the cards you choose from the search filters?",
    "You cannot update rates by conversion because you did not enter the conversion rate correctly":
      "You cannot update rates by conversion because you did not enter the conversion rate correctly",
    "Please, choose the type of account you want for this employee":
      "Please, choose the type of account you want for this employee",
    "You cannot upload prices by excel file because you do not have POS price categories":
      "You cannot upload prices by excel file because you do not have POS price categories",
    errorTryAgain: "Sorry an error has occurred please try again later",
    "POS Limits": "POS Limits",
    "Remember me": "Remember me",
    closeInsuranceBill: "Close Insurance Bill",
    "Enable WhatsApp notifications": "Enable WhatsApp notifications",
    successWait:
      "You request is underway it might take sometime please be patient",
    "You want to delete this ?": "You want to delete this ?",
    send: "send",
    "Account Status": "Account Status",

    "Serials Information": "Serials Information",
    "Link a Serial": "Link a Serial",
    "WhatsApp Phone Number": "WhatsApp Phone Number",
    "Add Auditing Request": "Add Auditing Request",
    "By Serial Number": "By Serial Number",
    "By Code": "By Code",
    "By Reference": "By Reference",
    "Invoice form": "Invoice form",
    "Select Invoice form": "Select Invoice form",
    "Active Pos": "Active POS",
    "Not Active Pos": "Not Active POS",
    "Total POS": "Total POS",
    "FireBase Report": "Firebase Report",
    CRM_Id: "CRM_Id",
    "Emp Name": "Emp Name",
    Trans_Id: "Trans_Id",
    "Bank Transfer State": "Bank Transfer State",
    billingNumber: "Billing Number",
    "Incomplete bills": "Incomplete bills",
    "Charge from Api direct": "Charge from Api direct",
    Collection: "Collection",
    "Commission After": "Commission After",
    "Payment Amount": "Payment Amount",
    "Commission Before": "Commission Before",
    CRM_ID: "CRM_ID",

    CRM_ID: "CRM_ID",
    "Max Dept": "Max Dept",
    Dept: "Dept",
    Rate: "Rate",
    "Cash Before": "Cash Before",
    "Cash After": "Cash After",
    "Flow Date": "Flow Date",
    Operation: "Operation",
    "Flow Amount": "Flow Amount",
    "Pushed Date": "Pushed Date",
    "Transfer Count": "Transfer Count",
    lastPayDate: "last payment",
    "Note 1": "Note 1",
    "Resp Name": "Resp Name",
    "Sender Name": "Sender Name",
    "Sender Date": "Sender Date",
    "Tran ID": "Tran ID",
    Bank: "Bank",
    State: "State",
    "Select Collection": "Select Collection",
    Enter: "Enter",
    "New POS code": "New POS code",
    "Credit card": "Credit card",
    transReferenceId: "Transaction Reference Id",
    transAmount: "Transaction Amount",
    transEntryDate: "Transaction Entry Date",
    transErrorDesc: "Transaction Error Description",
    transTitle: "Transaction Title",
    transStatus: "Transaction Status",
    "User Id": "User Id",
    "Visa payment failed": "Visa payment failed",
    TransferTotal: "Transfer Total",
    totalDebtsSent: "Total Debts Sent",
    totalDebtsReceived: "Total Debts Received",
    confirmPayment: "are you sure about this invoice?",
    debitFlow: "Debit Flow",
    totalDebitAdded: "Total Debit Added",
    totalDebitSub: "Total Debit Subtract",
    totalReceivablesAdded: "Total Receivables Added",
    remainingAmount2: "Remaining Amount",

    totalReceivablesSub: "Total Receivables Subtract",
    receivablesAfterDebit: "Receivables After Debit",
    debitAfterAction: "Debit After operation",
    openBills: "Open Bills",
    details: "Details",
    totalLoan: "total Loan",
    totalLoanPayment: "total Loan Payment",
    totalPayment: "total Payment",
    "Enable WhatsApp notifications POS supervisor":
      "Enable WhatsApp notifications POS supervisor",
    "Activity log": "Activity log",
    "Sender Name": "Sender Name",
    numDaysToViewBills: "Number of days to view bills",
    "Acount Information": "Acount Information",
    "Point of sale identification": "Point of sale identification",
    "Purchase order permissions": "Purchase order permissions",
    "Point of sale permissions": "Point of sale permissions",
    "Area Information": "Area Information",
    "Other permissions": "Other permissions",
    "Order Permissions": "Order Permissions",
    "Bills permissions": "Bills permissions",
    "API Data": "API Data",
  },
  Ar: {
    details: "التفاصيل",
    debitAfterAction: "الذمم بعد العملية",
    receivablesAfterDebit: "الدين بعد العملية",
    totalReceivablesAdded: "مجموع المبالغ المضافة للذمم",
    totalReceivablesSub: "مجموع المبالغ المطروحة من الذمم",
    debitFlow: "كشف عمليات الدين",
    totalDebitAdded: "مجموع المبالغ المضافة للدين",
    totalDebitSub: "مجموع المبالغ المطروحة من الدين",
    Enter: "ادخل",
    "Charge from Api direct": "الشحن من API مباشرة",
    "Select Collection": "اختر المجموعة",
    "Sender Name": "اسم المرسل",
    "Sender Date": "تاريخ المرسل",
    "Tran ID": "معرف العملية",
    "User Id": "معرف المستخدم",
    Bank: "البنك",
    State: "الحالة",
    "Max Dept": "أقصى دين",
    CRM_ID: "معرف الCRM",
    Dept: "الدين",
    Rate: "التقييم",
    "Cash Before": "الرصيد قبل",
    "Cash After": "الرصيد بعد",
    "Flow Date": "تاريخ العملية",
    Operation: "العملية",
    "Flow Amount": "قيمة العملية",
    "Pushed Date": "تاريخ الدفع",
    "Transfer Count": "عدد التحويلات",
    TransferTotal: "مجموع التحويلات المقبولة",
    totalDebtsSent: "مجموع الذمم المرسلة المقبولة",
    totalDebtsReceived: "مجموع الذمم المستقبلة المقبولة",
    "Note 1": "ملاحظة 1",
    Trans_Id: "رقم الحواله",
    "Emp Name": "اسم الموظف",
    "Point Name": "اسم النقطة",
    "Commission Before": "عمولة قبل",
    "Payment Amount": "قيمة العملية",
    "Commission After": "عمولة بعد",
    "FireBase Report": "تقرير فايربيس",
    Collection: "مجموعة",
    "Not Active Pos": "نقاط البيع الغير مفعلة",
    "Active Pos": "نقاط البيع مفعلة",
    "Total POS": "عدد النقاط الكلي",
    "Link a Serial": "ربط سيريال",
    "Audit Type": "نوع التدقيق",
    "Select Audit": "اختر النوع",
    Audit: "التدقيق",
    "Add Category": "إضافة تصنيف",
    "By Serial Number": "عن طريق السيريال",
    "By Code": "عن طريق الكود",
    "Go Back Home": "العودة الى الرئيسية",
    "By Reference": "عن طريق الرقم المرجعي",
    "Account Status": "حالة الحساب",
    errorTryAgain: "نعتذر حدث خطأ يرجى المحاوله في وقت لاحق",
    successWait: "طلبك قيد التنفيذ، قد يتطلب ذلك بعض الوقت",
    "Date Type": "نوع التاريخ",
    "Inv #": "رقم الكود",
    Corrected: "تم التصحيح",
    Sold: "مباعة",
    "Appear Coupons": "عرض الكوبونات",
    "Not Corrected": "لم يتم التصحيح",
    "Correct All Codes": "تصحيح جميع الاكواد",
    "Select Date Type": "حدد نوع التاريخ",
    "View Reserved Cards at Order": "عرض البطاقات المحجوزة عند الطلب",
    "Actions Director": "اجراءات المدير",
    "Acount Information": "بيانات الحساب",
    "Select Acount Type": "أختار نوع الحساب",
    "Point of sale permissions": "صلاحيات نقاط البيع",
    "Area Information": "بيانات المنطقة",
    "Purchase order permissions": "صلاحيات طلبات الشراء",
    "Point of sale identification": "تحديد نقاط البيع",
    "Other permissions": "صلاحيات اخرى",
    "Order Permissions": "صلاحيات طلباتي",
    "Bills permissions": "صلاحيات فواتيري",
    "API Data": "بيانات API ",
    "Employee Name": "اسم الموظف",
    "Add Auditing Request": "اضافة طلب تدقيق",
    "Employee Account Type": "نوع حساب الموظف",
    "Login to your account": "تسجيل الدخول إلى حسابك",
    "Confirm Code": "تأكيد الرمز",
    Email: "البريد الالكتروني",
    "Hide Cards Prices": "اخفاء اسعار البطاقات",
    "Display Cards Prices": "عرض اسعار البطاقات",
    Password: "كلمة السر",
    "Enter your email": "أدخل البريد الالكتروني",
    "Enter password": "أدخل كلمة المرور",
    "This field is required": "هذا الحقل مطلوب",
    "Enter Qr code": "أدخل رمز التأكيد",
    "Qr Code": "رمز Qr",
    Login: "تسجيل الدخول",
    Confirm: "تأكيد",
    "Istanbul Information Technology": "اسطنبول لتكنولوجيا المعلومات",
    "Create distribution points": "انشاء نقاط توزيع",
    "Point of sale image": "صورة نقاط البيع",
    Dashboard: "لوحة التحكم",
    Distributors: "الموزعين",
    "Serials Information": "معلومات السيريال",
    Clients: "العملاء",
    "Select Clients": "اختر العملاء",
    Inventory: "المستودع",
    "Client Orders": "طلبات العملاء",
    "Mobile Orders": "طلبات الموبايل",
    "Web Orders": "طلبات الويب",
    "Client Services": "خدمات العملاء",
    Search: "بحث",
    Edit: "تعديل",
    Delete: "حذف",
    "Sign Out": "تسجيل الخروج",
    "My Profile": "حسابي",
    "Account settings and more": "إعدادات الحساب والمزيد",
    Category: "التصنيف", //الفئة
    Value: "القيمة",
    "Upload Complete": "اكتمل التحميل",
    Balance: "الرصيد",
    "Minimum number of cards": "الحد الادنى لعدد البطاقات",
    Status: "الحالة",
    maxDebtBillsNum: "العدد الاقصى للفواتير المسموحة",
    Action: "الإجراء",
    "Cards Transfer Report": "تقرير تحويل البطاقة",
    "Add Supplier": "إضافة مورد",
    "Distributor Type": "نوع الموزع",
    "Select Distributor Type": "اختر نوع الموزع",
    "Add Suppliers API": "إضافة الموردين API",
    "Selection of API Suppliers": "اختيار موردي API",
    "Add Balance": "إضافة رصيد",
    "Add Numny Balance": "إضافة رصيد Numny",
    "Add Egifter Balance": "إضافة رصيد Egifter",
    "Account Statement": "كشف حساب",
    "View Cards": "عرض البطاقات",
    "IP Address": "الاي بي",
    "Eazy Pay": "ايزي بي",
    Add: "إضافة",
    "Request Id": "رقم الهاتف",
    "Recharge Id": "رقم الاشتراك",
    "Recharge PIN": "رمز اعاده التعبئه",
    "Balance Title": "رصيد",
    "Network Operator": "مشغل الشبكة",
    "Category Name": "اسم التصنيف",
    "Enter Category Name": "أدخل اسم التصنيف",
    "Category Name In English": "اسم التصنيف بالانجليزي",
    "Enter Category Name In English": "أدخل اسم التصنيف بالانجليزي ",
    Save: "حفظ",
    Cancel: "إلغاء",
    "Category Type": "نوع التصنيف",
    "Select category type": "اختر نوع التصنيف",
    "Fixed Price": "محدد السعر",
    "Non Fixed Price": "متغير السعر",
    isMaster: "تصنيف Master",
    "Educational Cards": "بطاقات تعليمية",
    "debit date": "تاريخ الدين",
    remainingAmount: "الرصيد المتبقي",
    remainingAmount2: "القيمه المتبقيه",
    lastPayDate: "اخر عملية دفع",
    "Category Icon": "أيقونة التصنيف",
    "Upload Image only": "تحميل صورة فقط",
    tranDebit: "تحويل الذمم",
    Upload: "تحميل",
    "Get POS code": "الحصول على كود التطبيق",
    "Get code": "الحصول على الكود",

    "Add Card": "إضافة بطاقة",
    "Card Name": "إسم البطاقة",
    "Enter Card Name": "أدخل اسم البطاقة",
    "Card Name In English": "إسم البطاقة بالانجليزي",
    "Enter Card Name In English": "أدخل إسم البطاقة بالانجليزي",
    "Card Icon": "أيقونة البطاقة",
    "Card Binding Type": "نوع ربط البطاقة",
    "Select card binding type": "اختر نوع ربط البطاقة",
    Image: "صورة",
    "Excel File": "ملف Excel",
    "Images File": "ملف صور",
    invalidNumber: "غير مسموح استعمال هذا الرقم",
    send: "ارسال",
    "Buy Price": "سعر الشراء",
    "Select buy price": "اختر سعر الشراء",
    "Fixed Price": "سعر ثابت",
    Percentage: "نسبة مئوية",
    "Invoice Type": "نوع الفاتورة",
    "Select invoice Type": "اختر نوع الفاتورة",
    Custom: "مخصص",
    deleteName: "حذف",
    Neighborhood: "الحي",
    "Select Neighborhood": "اختر الحي",
    noAction: "لا يوجد اجراء",
    openBills: "فتح فواتير",
    "sent my pos and in audit": "تم ارسالها من طرف نقطة البيع وقيد المعالجة",
    "canceled by pos": "تم ألغائها من طرف نقطة البيع",
    "Enable WhatsApp notifications POS supervisor":
      "تفعيل اشعار واتس اب المندوب",
    "Enable WhatsApp notifications": "تفعيل اشعار واتس اب",
    "OOPS! Something went wrong here": "أوه! حدث خطأ هنا",
    "Allow openBills": "السماح بنظام الفواتير",
    "canceled by representative": "تم رفضها من المندوب",
    "sent by representative": "تم ارسالها من طرف المندوب",
    Public: "عام",
    deleteAction: "هل انت متاكد من انك تريد حذف هذا الطلب",
    confirmPayment: "متاكد من انك تريد فتح هذه الفاتورة/ذمة؟",
    Currency: "العملة",
    "Select currency": "اختر العملة",
    "Activate Notifications": "تفعيل الاشعارات",
    "Best Seller": "الأكثر مبيعا",
    payAmount: "المبلغ المسدد للفاتورة",
    maxBillValue: "القيمة القصوى للفاتوة",
    Notes: "ملاحظات",
    "Enter Notes": "أدخل الملاحظات",
    "Add a Bill": "اضافة فاتورة",
    "last pay": "اخر عملية دفع",
    "Subject of Email": "موضوع الايميل",
    "Arabic Desription": "الوصف بالعربي",
    "Enter arabic desription": "أدخل الوصف بالعربي",
    "English Desription": "الوصف بالانجليزي",
    "Enter english desription": "أدخل الوصف بالانجليزي",
    "Total Cards": "عدد البطاقات",
    "MintRout Cards": "بطاقات MintRout",
    "Enter Percentage": "أدخل النسبة المئوية",
    "Points Of Sales": "نقاط البيع",
    Name: "الاسم",
    "WhatsApp Phone Number": "رقم الهاتف للواتس اب",
    "Subscription Date": "تاريخ الاشتراك",
    Phone: "الهاتف",
    "Direct Charge order information": "معلومات طلب الشحن المباشر",
    "Select order type and Enter code to display data":
      "حدد نوع الطلب وأدخل الرمز لعرض البيانات",
    Region: "المنطقة",
    "Account Information": "معلومات الحساب",
    "Financial Report": "كشف العمليات المالية",
    "Point of sales Financial Report": "كشف العمليات المالية للموظفين",
    "Print QR Code": "طباعة QR",
    "Account Statement": "كشف حساب",
    "Add / Get Amount": "إضافة / تصحيح رصيد",
    "Add Amount": "إضافة رصيد",
    "Old Password": "كلمة السر القديمة",
    "New Password": "كلمة السر الجديدة",
    "Confirm New Password": "تاكيد كلمة السر الجديدة",
    "Update Your Password": "تحديث كلمة السر",
    "Reset Authentication": "إعادة ضبط المصادقة",
    "All Cards": "جميع البطاقات",
    "There is no data to display": "لا يوجد بيانات لعرضها",
    Card: "بطاقة",
    "Add Message": "اضافة رسالة",
    "Save code": "حفظ الكود",
    "Are you sure ?": "هل أنت متأكد ؟",
    "You want to delete this category ?": "هل تريد حذف هذا التصنيف ؟",
    "You want to delete this number ?": "هل تريد حذف هذا الرقم ؟",
    Yes: "نعم",
    Categories: "التصنيفات",
    "You want to delete this card ?": "هل تريد حذف هذه البطاقة ؟",
    "You want to delete this form ?": "هل تريد حذف هذه النموذج ؟",
    "You want to delete this discount ?": "هل تريد حذف هذه الخصم ؟",
    "Add Class": "إضافة فئة",
    closeInsuranceBill: "اغلاق فاتورة التاميين",
    "Upload File Templates": "نماذج الملفات الرفع",
    "Upload File Template": "نموذج ملف الرفع",

    "Card Description": "شرح البطاقة",
    "Class Name": "اسم الفئة",
    "Enter Class Name": "أدخل اسم الفئة",
    ok: "حسنا",
    "Passwords not match": "كلمه السر غير مطابقه",
    "Class Name In English": "اسم الفئة بالانجليزية",
    "Enter Class Name In English": "أدخل اسم الفئة بالانجليزية",
    "Card Value": "قيمة البطاقة",
    "Enter Card Value": "أدخل قيمة البطاقة",
    "Card Value In USD": "قيمة البطاقة بالدولار",
    "Enter Card Value In USD": "أدخل قيمة البطاقة بالدولار",
    "Cost Value": "سعر التكلفة",
    "Enter Cost Value": "أدخل سعر التكلفة",
    "Number Of Codes": "عدد الأكواد",
    "Enter Number Of Codes": "أدخل عدد الأكواد",
    "Minimum Number Of Cards": "الحد الادنى لعدد البطاقات",
    "Enter Minimum Number Of Cards": "أدخل الحد الادنى لعدد البطاقات",
    Price: "السعر",
    Dollar: "الدولار",
    receive: "استلام",
    "Enter Price": "أدخل السعر",
    "Selling Price": "سعر البيع",
    "Enter Selling Price": "أدخل سعر البيع",
    Type: "النوع",
    "Enter Type": "أدخل النوع",
    "Select type": "أختر النوع",
    "Class Icon": "أيقونة الفئة",
    Class: "فئة",
    "Orders Creator Phone": "هاتف منشئ الطلب",
    "Orders Creator Email": "البريد الإلكتروني لمنشئ الطلب",
    "User Name": "اسم المستخدم",
    "Enter User Name": "أدخل اسم المستخدم",
    "Product Id": "رقم المنتج",
    "Enter Product Id": "أدخل رقم المنتج",
    "Enter Value": "أدخل القيمة",
    "Country Code": "رمز الدولة",
    "Enter Country Code": "أدخل رمز الدولة",
    "Card Currency": "عملة البطاقة",
    "Enter Card Currency": "أدخل عملة البطاقة",
    "You want to delete this class ?": "هل تريد حذف هذه الفئة ؟",
    "Make sure that the image width is not more than 200":
      "الرجاء التأكد أن عرض الصورة لا يتجاوز 200",
    Title: "العنوان",
    "Enter Title": "أدخل العنوان",
    Description: "الوصف",
    "Enter Description": "أدخل الوصف",
    Id: "الرقم",
    "Reasons for transfer": "اسباب التحويل",
    "Entered by": "رقم المدخل",
    "Type Title": "الحالة",
    "Status Title": "الطريقة ",
    "You want to delete this ?": "هل انت متاكد من انك تريد حذف هذا؟",
    "You want to confirm this ?": "هل انت متاكد من انك تريد قبول هذا؟",
    "You want to reject this ?": "هل انت متاكد من انك تريد رفض هذا؟",
    "You want to delete this description ?": "هل تريد حذف هذا الوصف ؟",
    Sort: "ترتيب",
    "Sort Card Description": "ترتيب وصف البطاقة",
    "Buy & Sell Price": "سعر البيع والشراء",
    "Select supplier": "اختر المورد",
    "Add API": "إضافة API",
    Suppliers: "الموردين",
    "Purchase Orders": "طلبات الشراء",
    "Select purchase order": "اختر طلب الشراء",
    "Upload CSV File only": "حمل ملف CSV فقط",
    Example: "مثال",
    "Download Example": "تحميل المثال",
    "Upload Excel File": "ارفع ملف Excel",
    "Upload CSV File": "ارفع ملف CSV",
    "These Cards Already Exist": "هذه البطاقات موجودة",
    "Check Number Of Codes For These Cards":
      "تحقق من عدد الأكواد لهذه البطاقات ",
    Quantity: "الكمية",
    billingNumber: "رقم الفاتورة",

    "Enter Quantity": "أدخل الكمية",
    "Points Of Sales Orders": "طلبات نقاط البيع",
    Date: "التاريخ",
    accepted: "مقبول",
    waiting: "بالانتظار",
    rejected: "مرفوضة",
    Count: "العدد",
    "The amount withheld for tax": "المبلغ المقتطع للضريبة",
    payFromDistMoney: "دفع من رصيد الموزع",
    "POS supervisors": "المناديب الذين يمكن تحويل الذمم اليهم",
    "POS supervisors & POS supervisors supervisors":
      "المناديب والمشرفين الذين يمكن تحويل الذمم اليهم",
    "Pay state": "حالة الدفع",
    Order: "طلب",
    "Voucher Number": "رقم الوصل",
    "Entry Date": "تاريخ الادخال",
    Used: "مستخدم",
    "Total additions to POS": "إجمالي الإضافات إلى نقاط البيع",
    "Total corrections to POS": "إجمالي التصحيحات لنقاط البيع",
    "Not Used": "غير مستخدم",
    "Search For Code": "ابحث عن الكود",
    "Search For Serial No.": "ابحث عن الرقم التسلسلي",
    Settings: "إعدادات",
    "Purchase Orders": "طلبات الشراء",
    "Bank Name": "اسم البنك",
    "From Date": "من تاريخ",
    "To Date": "إلى تاريخ",
    "Add Order": "إضافة طلب",
    Export: "تصدير",
    "Print Status": "حالة الطباعة",
    Canceled: "ملغي",
    "Processing Stage": "مرحلة المعالجة",
    "Processing By": "المعالجة بواسطة",
    Completed: "مكتمل",
    "Not Completed": "غير مكتمل",
    "Order #": "رقم الطلب",
    "My Orders": "طلباتي",
    Refresh: "تحديث",
    CategoryMain: "التصنيف",
    closedStatus: "حاله الاغلاق",
    "Select Category": "اختر التصنيف",
    "Card Type": "نوع البطاقة",
    "Select Card Type": "اختر نوع البطاقة",
    "Order Number": "رقم الطلبية",
    "Client Name": "اسم العميل",
    "Distributor Name": "اسم الموزع",
    Time: "الوقت",
    Denomination: "الفئة",
    "Cards Count": "عدد البطاقات",
    "Reference #": "رقم المرجعي",
    "Not Received": "لم يستلم",
    "In Proccess": "قيد الاجراء",
    Complete: "مكتمل",
    Rejected: "مرفوض",
    Reject: "رفض",
    closed: "مغلقة",
    "Cards Restored To Inventory": "تم استعادة البطاقات للمستودع",
    "Not Canceled": "لم يتم الالغاء",
    "Some Cards Have Been Recovered": "تم استعادة بعض البطاقات",
    "EcoVoucher Cards Have Been Restored":
      "تم استعادة بطاقات ecoVoucher للمستودع",
    "Visa payment failed": "فشل الدفع عن طريق الفيزا",
    "Select Status": "اختر الحالة",
    "Cost Price": "سعر التكلفة",
    "Export Cards Files": "تصدير ملفات البطاقات",
    confirmDate: "تاريخ القبول",
    Files: "الملفات",
    "Cost Price In USD": "سعر التكلفة بالدولار",
    "Orders Problems": "تذاكر الطلبيات",
    "Supplier Cost": "تكلفة المورد",
    "Edit Selling Price": "تعديل سعر البيع",
    wrongAmount: "قيمة خاطئة",
    "Insert Price Less Than Or Equal Cost Price":
      "أدخل سعر أقل من أو يساوي سعر التكلفة",
    "Insert Price Greater Than Or Equal Cost Price":
      "أدخل سعر أكبر من أو يساوي سعر التكلفة",
    "Some Card Used": "لم يتم الالغاء",
    Suppliers: "الموردين",
    totalDistBalance: "مجموع ارصدة الموزعين",
    Address: "العنوان",
    "The quantity should not less than ": "يجب ألا تقل الكمية عن ",
    "The quantity should not be more than ": "يجب ألا تزيد الكمية عن ",
    "Quantity Per Day": "الكمية في اليوم",
    "Enter Quantity Per Day": "أدخل الكمية في اليوم",
    "Some Card Used": "لم يتم الالغاء",
    "Select Bank Name": "اختر اسم البنك",
    "Supplier Name": "اسم المورد",
    "Select Supplier Name": "اختر اسم المورد",
    "Request By": "منشىء الطلب",
    "Number Of Card Classes": "عدد الفئات",
    "Order Date": "تاريخ الطلب",
    AuditingPOS: "تدقيق البطاقات",
    receiverName: "اسم المستقبل",
    sentDate: "تاريخ الارسال",
    Note: "ملاحظة",
    "Select Upload File Templates": "اختر نماذج الملفات الرفع",
    "Select Upload File Template": "اختر نموذج ملف الرفع",
    "Note Admin": "ملاحظة الادمن",
    View: "عرض",
    open: "مفتوحة",
    "Invalid Qty": "الكمية غير صحيحة",
    "View Purchase Orders": "عرض طلب الشراء",
    "Edit Purchase Orders": "تعديل طلب الشراء",
    "Qty Price": "سعر الكمية",
    "Purchase Orders Number": "رقم طلب الشراء",
    Qty: "الكمية",
    "Total Qty": "إجمالي الكمية",
    "Total Price": "السعر الإجمالي",
    "Total Price After Dicount": "السعر الإجمالي بعد الخصم",
    Uploaded: "المرفوعة",
    "Not Uploaded": "الغير مرفوعه",
    "Add Employee": "إضافة موظف",
    "Add Denomination": "إضافة فئة",
    "Add Notification": "إضافة تنبيهات",
    Denominations: "الفئات",
    "Maximum Amount Of Payment": "القيمة القصوى للدفع",
    "You want to delete this denomination ?": "هل تريد حذف هذه الفئة ؟",
    Employee: "موظف",
    "Efawateercom precentage": "النسبة المئوية ل e-fwatercom",
    "First Name": "الاسم الأول",
    "Enter First Name": "أدخل الاسم الأول",
    "Last Name": "الاسم الأخير",
    "Enter Last Name": "أدخل الاسم الأخير",
    "Allow work with e-fawatercom": "السماح للتعامل مع e-fwatercom",
    "Enter Subscription Date": "أدخل تاريخ الاشتراك",
    "Enter Phone": "أدخل الهاتف",
    "Enter Email": "أدخل البريد اللإلكتروني",
    "Enter Region": "أدخل المنطقة",
    Location: "الموقع",
    "Signature Image": "صورة التوقيع",
    longitude: "خط الطول",
    Latitude: "خط العرض",
    Barcode: "الباركود",
    "Tax Number": "الرقم الضريبي",
    "Enter Tax Number": "أدخل الرقم الضريبي",
    "Image (1)": "صورة (1)",
    "Image (2)": "صورة (2)",
    "Add a Distributer": "انشاء موزع",
    "Qr Image": "صورة (Qr)",
    "Confirm Password": "تأكيد كلمة المرور",
    "Allow Use Efawtercom": "السماح باستخدام Efawtercom",
    Banks: "البنوك",
    "Ip Address": "عنوان IP",
    "Bank Name": "اسم البنك",
    Balance: "الرصيد",
    "Wallet Type": "نوع المحفظة",
    "Add Payment": "إضافة دفعة",
    Transfer: "تحويل",
    Transactions: "الحركات",
    "Transactions type": "نوع الحركات",
    "Purchase Order": "طلب شراء",
    "Txn Id": "Txn Id",
    Supplier: "المورد",
    "Close the financial audit": "إغلاق التدقيق المالي",
    "From Bank": "من بنك",
    "You want to delete this purchase order ?": "هل تريد حذف طلب الشراء ؟",
    "You want to close financial audit this purchase order ?":
      "هل تريد إغلاق التدقيق المالي لطلب الشراء هذا؟",
    "Amount of payment": "قيمة الدفعة",
    "Transfer From": "التحويل من",
    "Transfer To": "التحويل الى",
    "Amount (Dollar)": "القيمة (دولار)",
    "Type Of Operation": "نوع العملية",
    totalLoan: "إجمالي القروض",
    totalLoanPayment: "إجمالي سداد القروض",
    totalPayment: "إجمالي المدفوعات",
    "Request Balance Charge": "طلب شحن رصيد",
    "bill open": "فتح الفاتورة",
    Get: "تصحيح",
    "Select Type Of Operation": "اختر نوع العملية",
    Amount: "المبلغ",
    "Bank Transfer Image": "صورة التحويل المصرفي",
    "Enter Amount": "أدخل المبلغ",
    "The supplier has no cards": "المورد ليس لديه بطاقات",
    "Enter Qty": "أدخل الكمية",
    "Bank balance is not enough": "رصيد البنك لا يكفي",
    "Select Supplier And Bank To Display Cards":
      "اختر المورد والبنك لعرض البطاقات",
    "QR Code Types": "QR Code Types",
    "My Wallet": "محفظتي",
    "Type of international calls": "نوع المكالمات الدولية",
    "Select type of international calls": "إختر نوع المكالمات الدولية",
    "Other Wallet": "محافظ أخرى",
    "Please enter a valid value": "الرجاء ادخال قيمة صحيحة",
    "Please select a valid value": "الرجاء اختيار قيمة صحيحة",
    "Enter Name": "أدخل الاسم",
    pending: "قيد الانتظار",
    "Enter Address": "أدخل العنوان",
    "Select All": "أختر الكل",
    "%s selected": "%s تم اختيار",
    "You want to delete this supplier ?": "هل تريد حذف هذا المورد ?",
    "Transaction #": "الحركة #",
    "Entry Date at Stock": "تاريخ الادخال إلى المستودع",
    "Creation Date": "تاريخ الانشاء",
    "Used Date at Stock": "تاريخ الاستخدام ",
    "Used Date": "تاريخ الاستخدام ",
    "Correction Info": "معلومات التصحيح",
    "Orginal Amount": "القيمة الأصلية",
    "Orginal Amount Type": "نوع القيمة الأصلية",
    "Balance Before": "الرصيد قبل",
    "Balance After": "الرصيد بعد",
    "Tansaction Type": "نوع الحركة",
    "Tansaction Status": "حالة الحركة",
    "Tansaction Date": "تاريخ الحركة",
    "Transactions On Bank": "الحركات على بنك",
    "Commission Value": "قيمة العمولة",
    "Enter Commission Value": "أدخل قيمة العمولة",
    "Currency Type": "نوع العملة",
    "Select Currency Type": "اختر نوع العملة",
    "Select Wallet Type": "اختر نوع المحفظة",
    "Original Value": "القيمة الأصلية",
    "Original Value Type": "نوع القيمة الأصلية",
    "Balance Before Transaction": "الرصيد قبل الحركة",
    "Balance After Transaction": "الرصيد بعد الحركة",
    "Transaction Type": "نوع الحركة",
    "Purchase Order From": "طلب شراء من",
    "Payment From Bank": "دفعة من بنك",
    "Direct Purchase Order": "طلب شراء مباشر",
    "Card Inventory": "مستودع بطاقات",
    Active: "فعاله",
    "Export purchase orders and their complete reference number information":
      "تصدير طلبات الشراء ومعلوماتها الكاملة المتعلقة بالرقم المرجعي",
    "Orders Notes": "ملاحظات الطلبات نقاط البيع",
    "Order Note": "ملاحظة الطلب",
    Finished: "منتهية",
    "Number of cards": "عدد البطاقات",
    "unread Massage": "الرسائل غير المقروءة",
    "Add Cards": "اضافة بطاقات",
    "Authentication Reset": "إعادة ضبط المصادقة",
    "Sort Categories": "ترتيب التصنيفات",
    Employees: "الموظفين",
    numDaysToViewBills: "الايام المسموح فيها عرض الفواتير",
    chooseEmployee: "اختر الموظف",
    "Bank Number": "رقم البنك",
    Cards: "البطاقات",
    "View Order Problems": "عرض مشاكل الطلب",
    "Total cards required": "إجمالي البطاقات المطلوبة",
    "Total cards drawn": "إجمالي البطاقات المسحوبة",
    Notifications: "الاشعارات",
    "POS Notifications": "إشعارات نقاط البيع",
    "Add Balance to POS": "إضافة رصيد إلى نقاط البيع",
    "Total Buy Price": "إجمالي سعر الشراء",
    "Total Sale Price": "إجمالي سعر البيع",
    "Remember me": "تذكرني",
    "Edit Account Information": "تعديل معلومات الحساب",
    "You want to  Reset Authentication ?": "هل تريد إعادة ضبط المصادقة ؟",
    "Select Point Of Sales": "اختر نقطة البيع",
    "Operation Number": "رقم العملية",
    "Operation Date": "تاريخ العملية",
    "Operation Type": "نوع العملية",
    "Total amounts that added": "قيمة مجموع الاضافات",
    "Total amount that pulled": "القيمة الإجمالية للتصحيحات",
    "Orders total amount": "قيمة مجموع الطلبيات",
    "Total balance": "مجموع الرصيد",
    "add/remove addresses": "اضافة/حذف عنواين",
    "Balance Type": "نوع الرصيد",
    "You want to delete this pos ?": "هل تريد حذف نقطة البيع ؟",
    "You want to delete this employee ?": "هل تريد حذف الموظف ؟",
    Classes: "الفئات",
    "You want to delete this notification ?": "هل تريد حذف هذا الاشعار ؟",
    POS: "نقاط البيع",
    "App Status": "سجل التطبيق",
    Runtime: "وقت التشغيل",
    "Select POS": "اختر نقاط البيع",
    "Select POS Manager": "اختر مدير نقاط البيع",
    "Pos Code": "كود نقاط التوزيع",
    "Enter Pos Code": "أدخل كود نقاط البيع",
    "Please enter a 6-digit code": "الرجاء ادخال كود مكون من 6 أرقام",
    "Pos Class": "فئة نقاط التوزيع",
    priceCat: "فئة نقاط التوزيع",
    "Select Pos Class": "اختر فئة نقاط التوزيع",
    "Password Not Match": "كلمة السر غير متطابقة",
    "Sell Price": "سعر البيع",
    "Edit Classes Price": "تعديل سعر الفئات",
    "Enter Buy Price": "أدخل سعر الشراء",
    "Edit Price": "تعديل السعر",
    "selling price must be greater or equal to the buy price":
      "سعر البيع يجب أن يكون أكبر أو يساوي سعر الشراء",
    "POS Name": "اسم نقطة البيع",
    "POS Manager Name": "اسم مدير نقاط البيع",
    "POS Manager": "مسؤول نقاط البيع",
    "Pos Manager Trans #": "رقم العملية(مدير نقاط البيع)",
    "Pos Trans #": "رقم العملية(نقطة البيع)",
    "Agent Name": "اسم الوكيل",
    "Transaction Value": "قيمة التحويل",
    "Cards Value": "قيمة البطاقات",
    "You want to send sms ?": "هل تريد ارسال رسالة ؟",
    "You want to cancel order ?": "هل تريد إلغاء الطلب ؟",
    "Invoice Password": "كلمة مرور الفاتورة",
    "View Order": "استعراض الطلبية",
    "My Account": "حسابي",
    "Cards Information": "معلومات البطاقات",
    "Export Cards": "تصدير البطاقات",
    "Done Successfully": "تم بنجاح",
    Reports: "التقارير",
    "POS Cards Profit": "أرباح بطاقات نفاط البيع",
    Total: "المجموع",
    "The number of cards in the file : ": "عدد البطاقات في الملف : ",
    "Number Of Products": "عدد المنتجات",
    "Total Of Cost Price / Sale Price": "مجموع سعر التكلفة / سعر البيع",
    resultTitle: "الصافي",
    "Classes Name": "أسماء الفئات",
    "Total Profit For Each Class": "مجموع الربح لكل فئة",
    "Net Profit For All Classes": "صافي الربح لكل الفئات",
    "Balance Recharge Invoice": "فواتير شحن الرصيد",
    Invoices: "الفواتير",
    InvoicesQty: "عدد الفواتير",
    totalInvoicesAmount: "مجموع الفواتير",
    totalInvoicesRemaningAmount: "قيمة الفواتير المتبقية",
    "Incomplete bills": "الفواتير غير المكتملة",
    "Withdrawal Invoices": "فواتير السحب",
    "Arabic Name": "الاسم بالعربي",
    "Enter Arabic Name": "أدخل الاسم بالعربي",
    "English Name": "الاسم بالانجليزي",
    "Enter English Name": "أدخل الاسم بالانجليزي",
    Coupons: "الكوبونات",
    "Number of coupons": "عدد الكوبونات",
    "Enter Number of coupons": "أدخل عدد الكوبونات",
    "One time use": "استخدام مرة واحة",
    Code: "الكود",
    "Qty remaining": "الكمية المتبقية",
    "Enter discount rate": "أدخل معدل الخصم",
    "Discount rate": "معدل الخصم",
    "Coupon type": "نوع القسيمة",
    "Use several times": "استخدام عدة مرات",
    "Download codes": "تنزيل الأكواد",
    "Company Name": "اسم الشركة",
    "Enter Company Name": "ادخل اسم الشركه",
    "Invoice form": "شكل الفاتوره",
    "Select Invoice form": "اختر شكل الفاتوره",
    "Sale Currency": "عملة البيع",
    Country: "الدولة",
    "Type Num": "نموذج رقم",
    City: "المدينة",
    "Enter City": "أدخل المدينة",
    District: "الحي",
    "Enter District": "أدخل الحي",
    "Zip Code": "الرمز البريدي",
    "Enter Zip Code": "أدخل الرمز البريدي",
    "Commercial Registration No.": "رقم السجل التجاري",
    "Enter Commercial Registration No.": "أدخل رقم السجل التجاري",
    Mobile: "موبايل",
    "Incomplete Orders": "الطلبات الغير مكتمله",
    "Time Zone": "المنطقة الزمنية",
    "Enter Time Zone": "اختر المنطقه الزمنيه",
    Logo: "الشعار",
    "Supplier Type": "نوع المورد",
    "Select Supplier Type": "اختيار نوع المورد",
    "Payment Fees": "رسوم الدفع",
    Invoice: "الفاتورة",
    "Transfer Image": "صورة التحويل",
    Download: "تحميل",
    Preview: "معاينة",
    "The payment was approved": "تمت الموافقة على الدفعة",
    "Financial Operations Report": "كشف العمليات المالية",
    "Financial operations": "العمليات المالية",
    Loan: "قرض",
    Retrieval: "استرجاع",
    "Export the wrong cards": "تصدير البطاقات الخاطئة",
    Drawer: "الساحب",
    "Distributor Orders": "طلبيات الموزع",
    Payment: "دفعة",
    Emails: "البريد الالكتروني",
    "Send By Email": "ارسال عبر البريد الالكتروني",
    "Value of total payments": "قيمة مجموع المدفوعات",
    paymentsQty: "عدد المدفوعات",
    "total payments": "مجموع المدفوعات",
    "Value of payments": "قيمة المدفوعات",

    "POS balance": "رصيد نقاط البيع",
    "Total profits": "مجموع الأرباح",
    Attachments: "ملحقات",
    "Invoice Number": "رقم الفاتورة",
    "Final balance": "صافي الرصيد",
    "Debt Value": "قيمة الدين",
    "Receivables Value": "قيمة الذمم",
    Receivables: "الذمم",
    ReceivablesFlow: "كشف عمليات الذمم",

    "Debt Total": "مجموع الدين",
    "Cards Profit Inventory": "أرباح بطاقات المستودع",
    "Total Sale Price": "مجموع سعر البيع",
    "Unit Price": "سعر الوحدة",
    Recovery: "استرجاع",
    "We will send you this report by email":
      "سنرسل لك هذا التقرير عبر البريد الإلكتروني",
    "Repayment of loan": "سداد قرض",
    "Number Of Operations": "عدد العمليات",
    "Total cost and margin within 7 days":
      "المجموع الكلي للتكلفة والربح خلال 7 أيام",
    "The best selling cards within 7 days":
      "البطاقات الأكثر مبيعاً خلال 7 أيام",
    "Total cost and margin within ": "المجموع الكلي للتكلفة والربح خلال ",
    "The best selling cards within ": "البطاقات الأكثر مبيعاً خلال ",
    days: " أيام",
    "Create a purchase order": " إنشاء طلب شراء",
    "Least selling": "الأقل مبيعاً",
    "Best seller": "الأكثر مبيعاً",
    "Cost Total": "التكلفة الإجمالية",
    "Margin Total": "إجمالي الربح",
    Margin: "الربح",
    "Duplicate codes": "الكودات مكررة",
    Cost: "التكلفة",
    "Cards Sales": "مبيعات البطاقات",
    "loans Total": "مجموع القروض",
    "Card Class": "فئة البطاقة",
    "Operation #": "رقم العملية",
    "Card Name In File": "اسم البطاقة في الملف",
    "Card Class Value": "قيمة فئة البطاقة",
    "Enter Card Class Value": "أدخل قيمة فئة البطاقة",
    "Notification Email [ add classes ]":
      "البريد الالكتروني للاشعارات [إضافة فئات]",
    "Enter Notification Email [ add classes ]":
      "أدخل البريد الالكتروني للاشعارات [إضافة فئات]",
    "Buy Cards": "شراء البطاقات",
    "Activity log": "سجل النشاطات",
    Done: "تم بنجاح",
    Next: "التالي",
    "You want to create this purchase order ?":
      "هل تريد إنشاء طلب الشراء هذا ؟",
    "Select file template and supplier to display cards":
      "حدد نموذج الملف والمورد لعرض البطاقات",
    "Number of days to see order history": "عدد الايام لرؤية سجل الطلبات",
    "API Order": "API طلب",
    "Select Cards Category": "اختر تصنيف البطاقات",
    "Please Select Card Category": "الرجاء اختيار تصنيف البطاقة",
    "Select Card": "اختر البطاقة",
    Back: "عودة",
    "Order Has Been Successfully Added": "تم إضافة الطلب بنجاح",
    Home: "الرئيسية",
    "Determine the value of the cards": "حدد قيمة البطاقات",
    Alert: "تنبيه",
    "Net Transfer": "صافي التحويل",
    "The Amount Paid": "المبلغ المدفوع",
    "Tax Value": "قيمة الضريبة",
    "Deduction Value": "قيمة الاقتطاع",
    "Bank Account": "حساب البنك",
    "Select Bank Account": "اختر حساب البنك",
    "Show Password": "إظهار كلمة السر",
    "Actual Value": "القيمة الفعلية",
    "Remittance Value": "قيمة الحوالة",
    "Mobile Number": "رقم الموبايل",
    "Transaction Amount": "قيمة التحويل",
    "Enter Transaction Amount": "أدخل قيمة التحويل",
    "Conversion Ratio": "نسبة التحويل",
    "Please select the required quantity": "الرجاء تحديد الكمية المطلوبة",
    "Sorry, the required quantity is not available":
      "عذرا الكمية المطلوبة غير متوفره ",
    "Tax percentage": "نسبة الضريبة",
    Tax: "الضريبة",
    "Tax Amount": "المبلغ الضريبي",
    "Enter Maximum Amount Of Payment": "أدخل القيمة القصوى للدفع",
    "Fixed All Orders": "إصلاح جميع الطلبات",
    "face Amount": "القيمه الإسميه",
    amount: "القيمه",
    "Uncompleted Orders": "طلبات غير مكتمله",
    "Request #": "رقم الطلب",
    accept: "موافق",
    "Canceled Order": "طلب ملغي",
    "Not Completed Order": "الطلب لم يكتمل",
    "Start Time": "وقت البدء",
    "End Time": "وقت الانتهاء",
    "You want to accept request?": "هل تريد قبول الطلب؟",
    "Your request is in processed": "طلبك قيد المعالجة",
    Close: "إغلاق",
    "Re-order": "إعادة الطلب",
    "You want to Re-Order?": "هل تريد إعادة الطلب؟",
    "Re-order is successfully": "تم إعادة الطلب بنجاح",
    Tools: "أدوات",
    "Sales Account Report": "تقرير حساب المبيعات",
    request: "الطلب",
    responseData: "بيانات الرد",
    update: "تعديل",
    "update status": "تعديل الحالة",
    "please select the status of request": "الرجاء تحديد حالة الطلب",
    "select distributor": "حدد الموزع",
    "subscribe #": "رقم الاشتراك",
    "Resend SMS": "إعادة إرسال SMS",
    "is not a valid ": "ليس صحيحا",
    message: "الرسالة",
    "Without Tax": "بدون ضريبة",
    "With Tax": "مع الضريبة",
    "Price With Tax": "السعر مع الضريبة",
    "Price After Discount": "السعر بعد الخصم",
    "info ": "معلومات",
    "Deactivate Type": "نوع الإلغاء",
    "Deactivation Days": "ايام الإلغاء",
    "Charge inquiry type": "نوع استعلام الشحن",
    "Select charge inquiry type": "اختر نوع استعلام الشحن",
    Day: "اليوم",
    Administration: "الإدارة",
    "Enter supplier data": "ادخال بيانات الموردين",
    "No. Device": "رقم الجهاز",
    "App Version": "إصدار التطبيق",
    "Last Login": "وقت تسجيل دخول",
    "Last Logout": "وقت تسجيل خروج",
    "Number of logins": "عدد عمليات الدخول",
    "Number of logouts": "عدد عمليات الخروج",
    "Processing Reserved Cards": "معالجة البطاقات المحجوزة",
    "Request Data": "بيانات الطلب",
    "Google Auth Key": "الرمز للربط مع جوجل",
    "phone with country code": "رقم الهاتف مع رمز الدولة",
    "number of order": "# الطلبية",
    "number of request": "# الطلب",
    "Request Information": "معلومات الطلب",
    copied: "تم النسخ بنجاح",
    copy: "نسخ",
    "From which information was chosen to take this copy from the repository?":
      "من المعلومات التي تم اختيارها لأخذ هذه النسخة من المستودع؟",
    "Only one card category must be selected for the copy to be taken":
      "يجب اختيار تصنيف واحد فقط حتى يتم أخذ النسخة",
    "Only one card must be selected for the copy to be taken":
      "يجب اختيار بطاقة واحده فقط حتى يتم أخذ النسخة",
    "Edit phone": "تعديل الهاتف",
    "Select requestType": "حدد نوع الطلب",
    requestType: "نوع الطلب",
    invoiceAmount: "قيمة الفاتورة",
    "enter confirm amount": "ادخل القيمة للتاكيد",
    "Move copy with pictures": "نقل نسخة مع الصور",
    "Move copy with the same number": "نقل النسخة بنفس الرقم ",
    "Select Card Category": "حدد تصنيف البطاقة",
    "Select Card Class": "حدد فئة البطاقة",
    oldest: "الأقدم",
    Newest: "الأحدث",
    "Move copy from the repository": "نقل النسخة من المستودع",
    "Cards available to the supplier": "البطاقات المتاحة للمورد",
    "Cards are not available to the supplier": "البطاقات غير متوفرة للمورد",
    "Invalid codes": "الكودات الغير صالحة",
    "Cards not found in the database": "البطاقات الغير مسجلة بالمستودع",
    "sort by": "تصنيف حسب",
    "Sort Type": "نوع الترتيب",
    "API identifier to distributor": "معرف API للموزع",
    "API identifier": "معرف API",

    ///Add in 20-01-2022
    "Product Name": "اسم المنتج",
    "Search for Number": "ابحث عن الرقم",
    "Product Price": "سعر الشراء",
    "Product Quantity": "كمية المنتج",
    "Required card classes": "فئات البطاقة المطلوبة",
    "Search For Name": "ابحث عن الأسم",
    Product: "المنتج",
    "Credit card": "البطاقة الائتمانية",
    "Select Product": "اختر المنتج",
    "Invoice Id": "رقم الفاتوره",
    "API Suppliers Cards": "بطاقات موردين API",
    Receipt: "سندات القبض",
    payment_method: "طريقة الدفع",
    JSC: "جوهرة الشموخ",
    Permissions: "الصلاحيات",
    "Add Permission": "إضافة صلاحية صفحة",
    Permission: "صلاحية",
    Pages: "الصفحات",
    Sender: "المرسل",
    "Close Status": "اغلاق المشكله",
    "View Chat": "عرض المحادثة",
    "Select Page": "حدد الصفحة",
    referenceNumber: "رقم المرجع",

    "Select Actions": "حدد الاجراءات",
    "You want to delete this Page ?": "هل تريد حذف هذه الصفحة ؟",
    "Cards Profit inventory report": "تقرير جرد أرباح البطاقات",
    "Edit Status": "تعديل الحالة",
    "Send profits to distributors by email":
      "إرسال الأرباح الموزعين عبر البريد الإلكتروني",
    "Select Distributor Name": "حدد اسم الموزع",
    "Select Distributor": "حدد الموزع",
    "Products Number": "عدد المنتجات",
    Net: "الصافي",
    "Problem Title": "عنوان المشكلة",
    "Problem Type": "نوع المشكلة",
    "Add Problem": "أضف مشكلة",
    "CP Reference #": "الرقم المرجعي CP",
    "Pages Permissions": "صلاحية الصفحات",
    "Permission Types": "أنواع الصلاحيات",
    "Page Permission": "صلاحية صفحة",
    "Permission Type": "نوع الصلاحية",
    "Modification Date": "تاريخ التعديل",
    "in Arabic": "باللغة العربية",
    "in English": "باللغة الإنجليزية",
    "Enter Permission Type": "أدخل نوع الصلاحية",
    "You want to delete this Type ?": "هل تريد حذف هذا النوع ؟",
    "Page Action": "صلاحيات الصفحة",
    All: "الكل",
    "POS Status": "حالة نقطة البيع",
    "Select POS Status": "اختر حالة نقطة البيع",
    "Upload File": "تحميل ملف",
    "Uploaded File": "تحميل الملف",
    "Alternative Name": "الإسم البديل",
    "Enter Alternative Name": "أدخل الاسم البديل",
    "Alternative Names": "الأسماء البديلة",
    "Enter Alternative Names": "أدخل الأسماء البديلة",
    None: "لا شيء",
    "Corrected using the method": "تم تصحيح  باستخدام طريقة",
    "The order will be corrected using a method":
      "سيتم تصحيح الطلب باستخدام طريقة",
    Addition: "إضافة",
    Corrected: "تصحيح",
    Withdraw: "سحب",
    Custome: "Custome",
    "Processing Method": "طريقة المعالجة",
    Processed: "تمت  المعالجة",
    "Select Processing Method": "اختر طريقة المعالجة",
    "Select Processed": "اختر تمت  المعالجة",
    "General Settings": "إعدادات العامة",
    "Apply to all": "تطبيق على كل الانواع",
    "Managers List": "قائمة المدراء",
    "Add Manager": "إضافة مدير",
    "Join Date": "تاريخ الإضافة",
    "Select Admin Type": "حدد نوع المدير",
    Manager: "مدير",
    "Type Name": "اسم النوع",
    "Data Type": "نوع البيانات",
    "Account Type": "نوع الحساب",
    "Login Type": "تسجيل دخول الحساب",
    Select: "اختر",
    "Select Account Type": "حدد نوع الحساب",
    "Select Report": "حدد التقرير",
    "Enter API Key": "أدخل API Key",
    "You want to delete this manager ?": "هل تريد حذف هذا المدير؟",
    "Password does not match": " كلمة المرور غير متطابقة",
    "Select Manager": "حدد المدير",
    "Wholesale Orders": "طلبات الجملة",
    "Cart Name": "اسم عربة التسوق",
    "Select Cart Name": "حدد اسم عربة التسوق",
    "Add Cart": "إضافة عربة تسوق",
    "Cart #": "رقم عربة التسوق",
    Review: "إستعراض",
    Buying: "شراء",
    "The card class has no supplier API": "فئة البطاقة ليس لديها مورد API",
    "This card class has no Prices for suppliers API":
      "فئة البطاقة هذه ليس لها أسعار لموردي API",
    "This card class has no Prices for suppliers":
      "فئة البطاقة هذه ليس لها أسعار لموردين",
    "Correction Date": "تاريخ التصحيح",
    "Corrector Name": "اسم المصحح",
    "Enter Cart Name": "أدخل اسم عربة التسوق",
    "You want to delete this cart ?": "هل تريد حذف عربة التسوق هذه ؟",
    "Confirm adding orders to distributor": "تاكيد إضافة الطلبات الى الموزع",
    "Confirm adding orders to": "تاكيد إضافة الطلبات الى",
    "from the cart": "من عربة التسوق",
    number: "رقم",
    "Files Forms": "نماذج الملفات",
    "Discount Add Balance": "خصم اضافة الرصيد",
    "Correction View": "عرض التصحيح",
    "Purchase order number": "رقم طلب الشراء",
    "Purchase order date": "تاريخ طلب الشراء",
    "Review purchase orders": "إستعرض طلب الشراء",
    "Review Order": "إستعرض الطلب",
    Cart: "عربة التسوق",
    "You want to delete this order problem ?":
      "هل تريد حذف المشكلة الطلبية هذا؟",
    "Reason for withdrawing the cards": "سبب سحب البطاقات",
    "Enter reason for withdrawing the cards": "أدخل سبب سحب البطاقات",
    "SEARCH TO ADD A NOTE": "البحث لإضافة ملاحظة",
    "Purchase Order Information": "معلومات طلبات الشراء",
    playerId: "معرف اللاعب",

    "Purchase order value": "قيمة طلب الشراء",
    "Private API Key": "مفتاح API خاص",
    "Public API Key": "مفتاح API عام",
    "Actual Value": "القيمة الفعلية",
    "Transaction Value": "قيمة التحويل",
    "Correction Type": "نوع التصحيح",
    "Card Rating": "تصنيف البطاقة",
    "Card Category": "فئة البطاقة",
    "Invoice password": "كلمة مرور الفاتورة",
    visaPay: "السماح بالدفع عن طريق الفيزا",
    pay: "دفع فواتير",
    "Payment type": "نوع الدفع",
    "Select Payment type": "اختر نوع الدفع",
    "Do u want to also remove the pos balance?":
      "هل تريد ايضا سحب الرصيد من النقطه البيع؟",
    "Cancel and remove POS balance": "الغاء الفاتوره مع سحب رصيد النقظه",
    "Cancel but keep POS balance": "الغاء الفاتوره بدون سحب رصيد النقظه",
    "Bank account": "الحساب البنكي",
    "Total discount value": "قيمة الخصم على المجموع",
    "Invoice date": "تاريخ الفاتورة",
    "Select Country": "حدد الدولة",
    "Beneficiary Name": "اسم المستفيد",
    "Select Beneficiary Name": "حدد اسم المستفيد",
    "Company Account": "حساب الشركة",
    "Select Company Account": "حدد حساب الشركة",
    "Invoice Password": "كلمة مرور الفاتورة",
    "Orders Total After Discount": "مجموع الطلبيات بعد الخصم",
    "Discount Type": "نوع الخصم",
    "Select Discount Type": "حدد نوع الخصم",
    Discount: "الخصم",
    "Select Discount": "حدد الخصم",
    "Discount Value": "قيمة الخصم",
    "Card Rating": "تصنيف البطاقة",
    "Select Card Rating": "حدد تصنيف البطاقة",
    "Send Invoice by email": "أرسل الفاتورة عبر البريد الإلكتروني",
    Company: "الشركة",
    "Upload the file Now": "قم بتحميل الملف الآن",
    "There are no cards in the inventory associated with this supplier and file template":
      "لا توجد بطاقات في المستودع مرتبطة بهذا المورد ونموذج الملف",
    "Select the bank to upload the file": "حدد البنك لتحميل الملف",
    "Select the cards to upload the file": "أختر البطاقات لتحميل الملف",
    "Addition completed successfully": "تمت عملية الإضافة بنجاح",
    "Correction completed successfully": "تمت عملية التصحيح بنجاح",
    enterCode: "ادخل الرمز",
    "Select Company": "حدد الشركة",
    "Have Discount": "احصل على خصم",
    "Search In": "البحث في",
    "Enter Id": "أدخل الرقم",
    "Select Search In": "اختر البحث في",
    "Invoice Report": "تقرير الفواتير",
    "Bills requests": "الفواتير",
    "Refernce Number": "رقم المرجع",
    "Serial Number": "رقم التسلسل",
    "View Invoice": "عرض الفاتورة",
    "Invoice Total": "مجموع الفاتورة",
    "Correction Confirmation": "تأكيد التصحيح",
    "Review Request Data": "مراجعة بيانات الطلب",
    Distributor: "الموزع",
    "The number must be decimal": "يجب أن يكون الرقم عشرياً",
    "The number must be Integer": "يجب أن يكون رقم صحيح",
    "The number must be between 0 and ": "  يجب أن يكون الرقم بين 0 و",
    "The number must be between 0 and 1": " يجب أن يكون الرقم بين 0 و 1",
    "The number must be grater than 0": "يجب أن يكون الرقم أكبر من 0",
    "Class #": "رقم الفئة",
    "Cost price in warehouse": "سعر التكلفة في المستودع",
    "Buying price at distributor": "سعر الشراء عند الموزع",
    "Point selling price": "سعر البيع للنقطة",
    Profit: "ربح",
    "Wholesale Request #": "رقم طلب الجملة",
    charge: "شحن",
    "Setting Type": "نوع الإعداد",
    "Point name": "اسم النقطة",
    "Select Filter Mode": "اختر وضع التصفية",
    "Not available to the supplier": "غير متوفره  للمورد",
    "Not registered in the warehouse": "غير مسجلة في المستودع",
    "Select Type Insert": "اختر نوع الإضافة",
    "Select Supplier": "حدد المورد",
    codeSent: "يرجى ادخال الكود الذي تم ارساله عبر الايميل او رسالة نصية ",
    "Select Class": "اختر الفئة",
    Class: "الفئة",
    "Suppliers Prices": "أسعار الموردين",
    "There are no cards available": "لا توجد بطاقات متاحة",
    "Reference # of (Wholesale  or API Order)":
      "رقم المرجع (الجملة أو طلب API)",
    "Cards Number": "عدد البطاقات",
    "Buy Price": "سعر الشراء",
    "Sale Price": "سعر البيع",
    "Currency Code": "كود العملة",
    "Currency Number": "رقم العملة",
    "Currency Price": "سعر العملة",
    "Currencies List": "قائمة العملات",
    "Add Currency": "إضافة عملة",
    Enter: "أدخل",
    "Form Name": "اسم النموذج",
    "Main Code": "الكود الرئيسي",
    errPosCode: "الرمز غير مطابق",
    "Enter Main Code": "ادخل الكود الرئيسي",
    "Secondary Code": "الكود الفرعي",
    "Enter Secondary Code": "ادخل الكود الفرعي",
    "With Hash": "مع هاش",
    "Enter Form Name": "أدخل اسم النموذج",
    "Enter Form Name In English": "أدخل اسم النموذج بللغة الإنجليزية",
    "Discount Name": "اسم الخصم",
    "Login Phone Number": "رقم الهاتف لتسجيل الدخول",
    "New Password": "كلمة السر الجديدة",
    "New POS code": "كود الPOS الجديد",
    "You want to Hide Cards Prices?": "هل تريد اخفاء اسعار البطاقات؟",
    "You want to Display Cards Prices?": "هل تريد عرض اسعار البطاقات؟",
    "Enter Discount Name": "أدخل اسم الخصم",
    "Enter Discount Name In English": "أدخل اسم الخصم بللغة الإنجليزية",
    "Discount Name In English": "اسم الخصم بللغة الإنجليزية",
    "You want to delete this currency ?": "هل تريد حذف هذه العملة ؟",
    Attached: "المرفق",
    "You want to delete this attached ?": "هل تريد حذف هذا المرفق ؟",
    Attachments: "المرفقات",
    "Add Attached": "إضافة مرفق",
    "Attachments List": "قائمة المرفقات",
    "Account Information": "معلومات الحساب",
    "Bank Account Information": "معلومات الحساب البنكي",
    Branch: "الفرع",
    "Form Type": "نوع النموذج",
    "Select Form Type": "اختر نوع النموذج",
    "Account Name": "اسم صاحب الحساب",
    "Swift Code": "Swift كود",
    "IBAN Id": "IBAN رقم",
    "Edit the Classes Buffer": "تعديل البفر للفئات",
    "Direct Shipping Report": "تقرير الشحن المباشر",
    "Number of codes already exist:": "عدد الكودات الموجود بالفعل:",
    "Account Number": "رقم الحساب",
    "Company Information": "معلومات الشركة",
    "Add Account": "إضافة حساب",
    "Trade License Number": "رقم الرخصة التجارية",
    "Change POS Code": "تغيير رمز POS",
    "More Data": "المزيد من المعلومات",
    "File Upload Forms": "نماذج ملفات الرفع",
    "Purchase Tax": "ضريبة الشراء",
    "You want to delete this company ?": "هل تريد حذف هذه الشركة ؟",
    "Seal Image": "صورة الختم",
    "Logo Image": "صورة الشعار",
    Account: "حساب",
    "CIF Id": "CIF رقم",
    "Opening balance": "الرصيد الأفتتاحي",
    Regions: "المناطق",
    Status: "الحالة",
    "Select Status": "اختر الحالة",
    Checked: "تم التدقيق",
    Pending: "قيد الانتظار",
    Region: "منطقة",
    "Change Password": "تغيير كلمة السر",
    "Regions List": "قائمة المناطق",
    "Regions In Arabic": "المناطق باللغة العربية",
    "Regions In English": "المناطق باللغة الإنجليزية",
    "You want to delete this bank ?": "هل تريد حذف هذا البنك ؟",
    "You want to delete this region ?": "هل تريد حذف هذه المنطقة ؟",
    "Direct Charge Countries": "دول الشحن المباشر",
    "Select Direct Charge Countries": "أختر دول الشحن المباشر",
    "Countries List": "قائمة الدول",
    Countries: "الدول",
    "Country Status": "حالة الدولة",
    "Number Length": "طول الرقم",
    "Country Key": "مفتاح الدولة",
    "Country name in English": "اسم الدولة باللغة الإنجليزية",
    "Country name in Arabic": "اسم الدولة باللغة العربية",
    "Note: Number length must be without country code and zero":
      "ملاحظة: يجب أن يكون طول الرقم بدون رمز البلد وصفر",
    Trash: "المحذوفات",
    "Select Deleted Types": "اختر النوع",
    "Types of Deleted": "أنواع المحذوفات",
    ID: "الرقم",
    Deleted: "تم حذفها",
    //     1-تعويض رصيد

    // 2-استبدال بطاقة

    // 3- شحن نفس البطاقة
    buy: "شراء",

    // 4- تم حل مشكلة البطاقة
    compensation: "تعويض رصيد",
    replacing: "استبدال بطاقة",
    cardCharge: "شحن نفس البطاقة",
    problemSolved: "تم حل مشكلة البطاقة ",

    "It is not deleted": "لم يتم حذفها",
    Number: "رقم",
    "Assign ID": "رقم التعيين",
    "Use in orders": "الاستخدام في الطلبات",
    "Active for use in orders": "فعالة للاستخدام في الطلبات",
    "Not effective for orders": "غير فعالة للطلبات",
    "Export All Type": "تصدير كافة الأنواع",
    "You want to restore this item ?": "تريد استعادة هذا العنصر؟",
    "Supplier Block Time": "وقت تعطيل المورد",
    "Please enter the time in minutes": "الرجاء إدخال الوقت بالدقائق",
    "Ding Countries": "دول Ding",
    Update: "تحديث",
    "Distributor Commission & POS E-fawateercom":
      "عمولة الموزع ونقاط البيع E-fawateercom",
    "Commission List": "قائمة العمولة",
    Commission: "عمولة",
    "API Type": "نوع API",
    General: "عامة",
    "Financial Audit Status": "حالة التدقيق المالي",
    "Profit Percentage": "نسبة الربح",
    "Distributor Commission": "عمولة الموزع",
    "POS Commission": "عمولة نقاط البيع",
    "Invoice Information": "معلومات الفاتورة",
    Invoice: "الفاتورة",
    "Distributor Information": "معلومات الموزع",
    margin: "الهامش",
    "Conversion Reasons": "أسباب التحويل",
    Reason: "سبب",
    "File Name": "اسم الملف",
    "Number Of Cards Requested From Supplier":
      "عدد البطاقات المطلوبة من المورد",
    "Enter Number Of Cards Requested From Supplier":
      "أدخل عدد البطاقات المطلوبة من المورد",
    "Sale Date": "تاريخ البيع",
    "Sale Info": "معلومات البيع",
    "Reason In Arabic": "السبب باللغة العربية",
    "Reason In English": "السبب باللغة الإنجليزية",
    "You want to delete this reason ?": "تريد حذف هذا السبب ؟",
    "Account Statement Dist And Pos": "كشف حساب الموزعين و نقاط البيع",
    "Terms and Conditions": "الشروط والأحكام",
    "How to use": "طريقة الاستخدام",
    "Inquiries about POS": "استعلام عن موظف",
    "Distributor Id": "رقم الموزع",
    "There are no files": "لا توجد ملفات",
    "First Note": "الملاحظة الأولى",
    "Second Note": "الملاحظة الثانية",

    "Financial operations for all distributors":
      "كشف العمليات المالية لجميع الموزعين",
    "Distributor Balance": "رصيد الموزع",
    "Required shipping amount": "مبلغ الشحن المباشر",
    "All financial operations": "جميع العمليات المالية",
    "Balance Recharge Requests": "طلبات شحن الرصيد",
    "Balance Recharge": "شحن الرصيد",
    "Order Value": "قيمة الطلب",
    "Balance recharge order number": "رقم طلب شحن الرصيد",
    "There are no cards to display": "لا يوجد بطاقات لعرضها",
    "Transfer currency": "عملة التحويل",
    Select: "حدد",
    "To Card Type": "إلى نوع البطاقة",
    "To Card": "إلى البطاقة",
    "To Card Class": "إلى فئة البطاقة",
    "From Card Type": "من نوع البطاقة",
    "From Card": "من البطاقة",
    "From Card Class": "من فئة البطاقة",
    Form: "النموذج",
    "Add Form": "إضافة نموذج",
    "Add Discount": "إضافة الخصم",
    "Edit Form": "تعديل النموذج",
    "Transfer Price": "سعر التحويل",
    "Main Supplier": "المورد الرئيسي",
    Selected: "إختر",
    "Select Day": "إختر يوم",
    "Transaction Amount": "قيمة الحوالة",
    "Conversion Reason": "سبب التحويل",
    "Receiver Bank": "البنك المستقبِل",
    "Add tax value": "اضافة قيمة الضريبة",
    "Sending Bank": "البنك المرسل",
    "Current Account": "الحساب الحالي",
    "Another Account": "حساب آخر",
    "Update Cards": "تحديث البطاقات",
    "Transfer Image": "صورة الحوالة",
    "You want to delete this order ?": "تريد حذف هذا الطلب ؟",
    "Shipping order information": "معلومات طلب الشحن",
    "Request Date": "تاريخ الطلب",
    Accept: "موافقة",
    "Select type disable": "حدد نوع الإلغاء",
    "Print Times": "أوقات الطباعة",
    "Final Amount": "القيمة النهائية",
    "Balance in distributor currency": "الرصيد بعملة الموزع",
    "Amount in distributor currency": "المبلغ بعملة الموزع",
    "Total POS Balances": "مجموع ارصدة نقاط البيع",
    "PayThem Cards": "بطاقات PayThem",
    "Update PayThem Cards": "تحديث بطاقات PayThem",
    "Ezpin Cards": "بطاقات Ezpin",
    "Update Ezpin Cards": "تحديث بطاقات Ezpin",
    "PrePaidForge Cards": "بطاقات PrePaidForge",
    "Update PrePaidForge Cards": "تحديث بطاقات PrePaidForge",
    "Update Joker Cards": "تحديث بطاقات جوكر",
    "Joker Cards": "بطاقات جوكر",
    "Update Gift2Game Cards": "تحديث بطاقات Gift2Game",
    "Gift2Game Cards": "بطاقات Gift2Game",
    "OneCard Cards": "بطاقات OneCard",
    "Update OneCard Cards": "تحديث بطاقات OneCard",
    "Ding Cards": "بطاقات Ding",
    "Update Ding Cards": "تحديث بطاقات Ding",
    "Card Id": "رقم البطاقة",
    From: "من",
    To: "إلى",
    "Egifter Cards": "بطاقات Egifter",
    "Direct Charge Suppliers": "موردي الشحن المباشر",
    "Direct Charge": "شحن مباشر",
    "Direct Charge Type": "نوع شحن مباشر",
    "Excel type": "اكسل",
    "Available Classes": "الفئات المتوفرة",
    "Card Code": "رمز البطاقة",
    "Provider Code": "رمز المزود",
    "Type of services": "نوع الخدمات",
    "Card Inquiry": "إستعلام عن بطاقة",
    "Job Name": "أسم الوظيفة",
    "Cards Value for Distributor": "قيمة البطاقات للموزع",
    "Cards Value for POS": "قيمة بطاقات لنقطة البيع",
    "Startup date/time": "تاريخ/وقت  بدء التشغيل",
    "Operation end date/time": "تاريخ/وقت نهاية التشغيل",
    "Po Number": "رقم Po",
    "Usage time": "وقت الإستخدام",
    "Activate Distributors Cards": "تفعيل بطاقات الموزعين",
    "Cancellation Date": "تاريخ الإلغاء",
    "Cancellation Time": "وقت الإلغاء",
    Activate: "تفعيل",
    "Domain To": "الى domain",
    "Domain From": "من domain",
    Deactivated: "تعطيل",
    Deactive: "معطله",
    "You cannot select this domain. It has already been selected":
      "لا يمكنك تحديد هذا الدومين. لقد تم اختياره بالفعل",
    Totals: "المجاميع",
    "Problem Chat": "محادثة المشكلة",
    "Select Domain To": "اختر  domain ",
    "Select Domain From": "اختر  domain ",
    "WhatsApp Number": "رقم الواتس اب",
    "Class Id": "رقم الفئة",
    Deactivate: "إلغاء تفعيل ",
    "Sending Date": "تاريخ الإرسال",
    "Ban Date": "تاريخ الحظر",
    "Account Balances": "أرصدة الحساب",
    "Account Balances Report": "تقرير أرصدة الحساب",
    "Supplier Code": "كود المورد",
    "Number of Requests": "عدد الطلبات",
    "Status in Buffer": "حالته في Buffer",
    "Total Balance": "إجمالي الرصيد",
    "Edit problem status": "تعديل حالة المشكلة",
    "Pull Cards": "سحب  بطاقات",
    "Distributors Warehouse": "مستودع الموزعين",
    "Total Revolving Balance": "مجموع الرصيد المدور",
    daysCountError: "يجب ان تكون فترة البحث اسبوع او اقل ",
    paymentStatus: "حالة الدفع",
    "Unlock All Request Suppliers": "فك حجز كافة طلبات الموردين",
    "You want to unlock this supplier request ?":
      "أتريد فك حجز طلب المورد هذا ؟",
    "You want to unlock all suppliers request ?":
      "أتريد فك حجز كافة طلبات الموردين ؟",
    "Supplier Cards Held In Buffer": "بطاقات الموردين محجوزه في Buffer",
    "Your Total Balance": "رصيدك الإجمالي",
    "Card Status": "حالة البطاقة",
    "Select Reference #": "اختر الرقم المرجعي",
    "View Details": "عرض التفاصيل",
    "View Other Obligations": "عرض الالتزامات الأخرى",
    "Admin Id": "رقم الادمن",
    "Suppliers Api": "الموردين Api",
    Commitments: "الألتزامات",
    "Search By Name": "البحث عن طريق الإسم",
    "Requested Qty": "الكمية المطلوبة",
    Error: "خطأ",
    "You cannot export more than one card file at a time":
      "لا يمكنك تصدير أكثر من ملف بطاقات في آن واحد",
    "Deactivate Cards": "تفعيل وإلغاء تفعيل البطاقات",
    "Select another class to transfer cards from, it is not possible to transfer to and from the same class":
      "حدد فئة أخرى لتحويل البطاقات منها ، ولا يمكن التحويل من وإلى نفس الفئة",
    "Select another class to transfer cards to, it is not possible to transfer to and from the same class":
      "حدد فئة أخرى لتحويل البطاقات إليها ، ولا يمكن التحويل من وإلى نفس الفئة",
    "From Card": "من بطاقة",
    "To Card": "الى بطاقة",
    "Error Number": "رقم الخطأ",
    "The sum of other obligations": "مجموع الالتزامات الأخرى",
    "Total Balances": "إجمالي الأرصدة",
    "Other Obligations": "الألتزامات اخرى",
    "List Of Special Numbers": "قائمة الأرقام الخاصة",
    "(Calculated Numbers)": "(الأرقام المحسوبة)",
    "Distributors Classes": "فئات الموزعين",
    "Distributor Class Name": "اسم فئة الموزع",
    "Select Distributor Class": "أختر فئة الموزع",
    "Upload Excel": "تحميل Excel",
    "Clear Filter": "تحديث الفلتر",
    "Assignment Date": "تاريخ التعيين",

    "Show only": "عرض فقط",
    Model: "نموذج ",
    "Cards Transfer": "تحويل البطاقات",
    "Upload Excel Multiple": "تحميل Excel متعدد",
    "You cannot export the invoice because the report does not contain data":
      "لا يمكنك تصدير الفاتورة لأن التقرير لا يحتوي على بيانات ",
    Unlock: "فك الحجز ",
    "Status Note": "ملاحظة الحالة",
    "All card classes following this card do not have an API supplier":
      " جميع فئات البطاقات التي تتبع هذه البطاقة لا تحتوي على مورد API",
    "Order Id": "رقم الطلب",
    "Select card class to display its API suppliers":
      "اختر فئة بطاقة لعرض موردي API الخاصين بها",
    "Free Cards": " بطاقات مجانية",
    "Direct Charge Cards API": "بطاقات الشحن المباشر API",
    "Order Amount": "قيمة الطلب",
    "Number Print Times": "عدد مرات الطباعة",
    "Codes File": "ملف الأكواد",
    "Select Type from Types of Deleted": "حدد النوع من أنواع المحذوفات",
    "Actual Order Amount": "قيمة الطلب الفعلية",
    "Date/Time": "تاريخ / وقت",
    "Order Date/Time": "تاريخ / وقت الطلب",
    "Note Date/Time": "تاريخ / وقت الملاحظة",
    "Reprint Date/Time": "تاريخ / وقت إعادة الطباعة",
    "Order Cards": "بطاقات الطلب",
    "Report Name": "أسم التقرير ",
    "Domain Name": "أسم Domain ",
    "Price Type": "نوع السعر",
    "Purchase Request # (Class)": "رقم طلب الشراء للفئة",
    "Requesting Date": "تاريخ طلب التقرير",
    "Search Date": "تاريخ البحث",
    "Requests For Reports": "طلبات التقارير",
    "Total Cost Price": "إجمالي سعر التكلفة",
    "Total Cost Prices": "مجموع سعر التكلفة",
    "Total Cost": "التكلفة الإجمالية",
    "View Details": "عرض التفاصيل",
    "Entry Number": "العدد المدخل",
    totalAmountVisa: "مجموع طلبات البطاقة الائتمانية",
    "Number Sold": "العدد المباع",
    "Select Order Type": "أختر نوع الطلب",
    "Order Type": "نوع الطلب",
    "Duplicate Cards": "البطاقات المكررة",
    "Export Sales Details": "تصدير تفاصيل البيع",
    "Show Sold Number": "إظهار العدد المباع",
    "Cards Inventory Report": "تقرير جرد البطاقات",
    Link: "الرابط",
    theLink: "الرابط",

    Domain: "Domain",
    "Without Supplier": "بدون مورد",
    "Enter Link": "أدخل الرابط",
    "Linked To Inventory": "مرتبط بالمستودع ",
    "Priority In Buffer": "الأولوية في Buffer",
    "View API Suppliers": "عرض موردين API",
    "API Suppliers": "موردين API",
    "Select Report Name": "حدد اسم التقرير",
    "Show Filter": "إظهار الفلتر",
    "Hide Filter": "إخفاء الفلتر",
    "Select Domain Name": "حدد اسم Domain",
    "API Suppliers For The Class": "موردين API للفئة",
    "Distributor Cards Prices": "أسعار بطاقات الموزع",
    "Cards Prices": "أسعار البطاقات",
    "Prices Saved Successfully": "تم حفظ الأسعار بنجاح",
    "View Sell Details": "عرض تفاصيل البيع",
    "View API Suppliers Balance": "عرض رصيد موردين API",
    "API Suppliers Balances": "أرصدة موردين API",
    "POS Cards Profit Inventory Report": "تقرير جرد أرباح بطاقات نقاط البيع",
    Determine: "تحديد",
    "Cron Job Report": "تقرير Cron Job",
    Excel: "Excel",
    API: "API",
    "Form Name In English": "اسم النموذج باللغة الإنجليزية",
    "Excel Suppliers": "موردي اكسل",
    "POS address": "عنوان نقطه البيع",
    "Repeat Cards Report": "تقرير البطاقات المتكررة",
    "Please select only one distributor to be able to export the invoice":
      "الرجاء تحديد موزع واحد فقط لكي تتمكن من أصدار الفاتوره",
    "Add Bank Info": "إضافة المعلومات البنك",
    "Telephone Number": "رقم هاتف",
    "Enter Telephone Number": "ادخل رقم الهاتف",
    "Phone Number": "رقم الموبايل",
    "Postal Code": "الرمز البريدي",
    "Enter Postal Code": "ادخل الرمز البريدي",
    "Enter Phone Number": "ادخل رقم الموبايل",
    "Daily max pay limit": "سقف الشراء اليومي",
    "Enter Daily max pay limit": "ادخل سقف الشراء اليومي",
    "Duplicate Orders Report": "تقرير الطلبات المكررة",
    Request: "طلب",
    clickHere: "أضغط هنا",
    "Add Credit To POS": "إضافة رصيد إلى نقاط البيع",
    Paid: "دفعه",
    "Add payment": "اضافة دفعة",
    "Inserted By": "تم إدخاله بواسطة",
    "Transaction Inserted By": "تم إدخال الحركة بواسطة",
    "POS Id": "رقم نقطة البيع",
    Services: "الخدمات",
    Providers: "المزودين",
    Products: "المنتجات",
    "Services Classes": "فئلت الخدمات",
    "Fix it": "اصلحه",
    "Order Creator": "منشئ الطلبية",
    "Invoice Creator": "منشئ الفاتورة",
    "Account Type of Creator": "نوع حساب المنشئ ",
    "Type of Order": "نوع الطلبية ",
    "Trans Id": "رقم العملية",
    "Add POS": "إضافة نقطة البيع",
    "All Countries": "جميع البلدان",
    "All Services": "جميع الخدمات",
    "All Providers": "جميع المزودين",
    "All Products": "جميع المنتجات",
    "All Services Classes": "جميع فئات الخدمات",
    "DTone Configrations": "إعدادت DTone",
    "DTone Countries": "دول DTone",
    "DTone Services": "خدمات DTone",
    "DTone Providers": "مزودين DTone",
    "DTone Products": "منتجات DTone",
    "DTone Services Classes": "فئات خدمات DTone",
    "Service Id": "رقم الخدمه",
    "Service Name": "أسم الخدمه",
    "Service Status": "حالة الخدمه",
    "Select Service Class Name": "أختر أسم فئة الخدمه",
    "Service Class Name": "أسم فئة الخدمه",
    "Service Class Status": "حالة فئة الخدمه",
    "Provider Code": "كود المزود",
    "Provider Name": "أسم المزود",
    "Select Service Name": "أختر الخدمه",
    "Orders Processing": "معالجة الطلبات",
    "Request Generator": "منشئ الطلب",
    "The account number it belongs to": "رقم الحساب الذي يتبعه",
    "Payment received": "تم استلام الدفعة",
    "Waiting for the receipt": "في انتظار الاستلام",
    "The payment was declined": "تم رفض الدفعة",
    "Balance withdrawal From POS": "تصحيح الرصيد لنقاط البيع",
    "Profit From POS": "الربح من نقاط البيع",
    "Repayment of loan-Paid": "سداد قرض-دفعه",
    "Country Name": "أسم الدولة",
    "No financial transaction has been added to this order":
      "لم تتم إضافة أي حركة مالية إلى هذه الطلبيه",
    "Correction/return to the warehouse": "تصحيح/اعادة الى المستودع",
    "Correction/return to the distributor": "تصحيح/اعادة الى للموزع",
    "Return to the warehouse": "اعادة الى المستودع",
    "Return to the distributor": "اعادة الى للموزع",
    "The distributor has been discountedThe point has not been credited":
      "تمت عملية الخصم على الموزع لم يتم إضافة الرصيد للنقطة",
    "Select Country Name": "أختر أسم الدولة",
    "Select Provider Name": "أختر المزود",
    "Incomplete Credit Additions": "عمليات إضافة الرصيد الغير مكتملة",
    "Cards Profit Inventory Report With Bank Info":
      "تقرير جرد أرباح البطاقات مع معلومات البنك",
    "The POS has not been credited": "لم يتم إضافة الرصيد للنقطة",
    "The distributor has been discounted": "تمت عملية الخصم على الموزع",
    "Total Summation": "المجموع الكلي",
    "Pos Categories Name": "أسم فئة التسعير لنقاط البيع",
    "Pos Price Categories": " فئات التسعير لنقاط البيع",
    "Select Pos Categories": "اختار فئة التسعير",
    "Requset Date": "تاريخ الطلب",
    "Request Result Date": "تاريخ نتيجة الطلب",
    "Request Result": "نتيجة الطلب",
    "Add Note": "إضافة ملاحظة",
    "Edit Note": "تعديل الملاحظة",
    "You want to delete all days ?": "تريد حذف كل الأيام ؟",
    "You want to delete this day ?": "تريد حذف هذا اليوم ؟",
    "You want to delete all days from this card and every classes that  under its ?":
      "تريد حذف كل الأيام من هذه البطاقة ومن كل الفئات التي تندرج تحتها ؟",
    "You want to delete this day from this card and every classes that  under its ?":
      "تريد حذف هذا اليوم من هذه البطاقة ومن كل الفئات التي تندرج تحتها ؟",
    "Delete All Days": "حذف كل الأيام",
    "Edit Inventory Supplier for Classes": "تعديل مورد المستودع للفئات",
    "Allow Reprinting": "السماح بإعادة الطباعة",
    "POS Application Email": "البريد الإلكتروني لتطبيق نقاط البيع",
    "Enter POS Application Email": "أدخل البريد الإلكتروني لتطبيق نقاط البيع",
    "Email [Recharge POS]": "البريد الإلكتروني [شحن رصيد النقاط ]",
    "Enter Email [Recharge POS]": "أدخل البريد الإلكتروني [شحن رصيد النقاط ]",
    "Logs Requests API To Distributors": "سجلات طلبات API إلى موزعين",
    "Cards not available": "البطاقات غير متوافره",
    "Buy Price Distributor": "سعر الشراء الموزع",
    "Select Operation Type": "اختر نوع العملية",
    "There is no cards available in stock": "لا توجد بطاقات متوفرة في المخزون",
    "Please enter a valid amount": "يرجى إدخال مبلغ صالح",
    "Please select a type of operation": "الرجاء تحديد نوع العملية",
    "Total balances of POS managers": "إجمالي أرصدة مديري نقاط البيع",
    "Manger POS": "مدير نقاط البيع",
    Accountant: "محاسب",
    "Responsible staff": "الموظفين المسؤول عنهم",
    "POS Supervisor": "مندوب نقاط بيع",
    "POS Supervisor Supervisor": "مشرف مندوب نقاط البيع",
    "POS Supervisor Manger": "المدير",
    Column: "العامود",
    "This location is reserved for another column":
      "هذا الموقع محجوز لعمود آخر",
    "Location is reserved for this column": "الموقع محجوز لهذا لعمود",
    "Its location in the file": "موقعه  في الملف",
    "Arrangement the columns in the form": "ترتيب الأعمدة في النموذج",
    "All Categories Sort": "جميع التصنيفات",
    "Selected card do not have any card class":
      "البطاقة المحددة لا تحتوي على أي فئة بطاقة",
    "Selected category does not have any card":
      "التصنيف المختار ليس لديه أي بطاقة",
    "Are you want to activate all cards for all POS ?":
      "هل تريد تفعيل جميع البطاقات لجميع نقاط البيع؟",
    "Are you want to deactivate all cards for all POS ?":
      "هل تريد إلغاء تفعيل جميع البطاقات لجميع نقاط البيع؟",
    "Modify All Prices": "تعديل جميع الأسعار",
    "Modify Buy Prices": "تعديل أسعار الشراء",
    "Modify POS Classes Prices": "تعديل أسعار فئات نقاط البيع",
    "Prices Adjustment": "تعديل الأسعار",
    "Conversion Rate": "معدل التحويل",
    "Enter Conversion Rate": "أدخل معدل التحويل",
    "Balance withdrawal via POS Manager": "تصحيح الرصيد عبر مدير نقاط البيع",
    "Balance withdrawal": "تصحيح رصيد",
    "Actions Director": "مديرالعمليات",
    "Modify prices by conversion rate": "تعديل الأسعار حسب معدل التحويل",
    "Conversion In Multiplication": "تحويل الأسعار إلى أعلى",
    "Conversion In Divide": "تحويل الأسعار إلى أدنى",
    "In conversion rate is equal : ": " معدل التحويل يساوي : ",
    "Add Credits via Points Manager": "إضافة الرصيد عبر مدير النقاط",
    "Add Credits": "إضافة رصيد",
    Serial: "السيريال",
    "Reprint POS Orders": "إعادة طباعة طلبات نقاط البيع",
    "Modified Successfully": "تم التعديل بنجاح",
    "Added Successfully": "تمت الإضافة بنجاح",
    doneSucessfully: "تمت العمليه ",
    "All Categories": "جميع الفئات",
    "Enter the From date and the To date for orders to be sent via email":
      "أدخل التاريخ من والتاريخ الى للطلبيات المراد إرسالها عبر البريد الإلكتروني",
    "WhatsApp Notifications": "إشعارات الواتس اب",
    "Please enter the code or card serial number to be able to search":
      "الرجاء إدخال الرمز أو الرقم التسلسلي للبطاقة حتى يصبح بإمكانك البحث",
    "Direct Charging Type": "نوع الشحن المباشر",
    "WhatsApp Client Number": "رقم عميل واتس اب",
    "WhatsApp Channel Number": "رقم قناة واتس اب",
    "Enter a from and date for the orders to be exported":
      "أدخل تاريخًا من وإلى للطبيات المراد تصديرها",
    "Select Direct Charging Type": "أختر نوع الشحن المباشر",
    "Do you want to allow the POS ": " هل تريد السماح لنقطة البيع",
    "to re-print an order number ": "  بإعادة طباعة طلب",
    "Activate status to all classes": "تفعيل الحالة لجميع الفئات",
    "Deactivate status to all classes": "إلغاء تفعيل الحالة لجميع الفئات",
    "It is used to raise cards to the inventory from more than one class":
      "يتم استخدامه لرفع البطاقات إلى المستودع من أكثر من فئة",
    "They are used to raise cards to the inventory from more than one class":
      "يتم استخدامها لرفع البطاقات إلى المستودع من أكثر من فئة",
    "Do you want to return the card to the inventory to use it again?":
      "هل تريد إعادة الكود إلى المخزون لاستخدامها مرة أخرى؟",
    "Do you want to return all cards to the inventory to use them again ?":
      "هل تريد إعادة جميع البطاقات المحجوزة من هذا الطلب إلى المخزون لاستخدامها مرة أخرى؟",
    "Activate Tax Categories": "تفعيل التصنيفات الضريبة",
    "Activate Tax Categories for Distributors":
      "تفعيل التصنيفات الضريبة للموزعين",
    "There's are no any distributor is taxed": "لا يوجد أي موزع خاضع للضريبة",
    "Total balance additions": "إجمالي إضافات الرصيد",
    "Total balance corrections": "إجمالي تصحيحات الرصيد",
    "SEARCH TO ALLOW REPRINTING": "ابحث للسماح بإعادة الطباعة",
    "SEARCH BY CODE": "البحث عن طريق الكود",
    "EXPORT BY REFERNCE #": "التصدير عن طريق رقم المرجعي",
    "Activate and deactivate the class": "تفعيل والغاء تفعيل الفئات",
    "Minimum Stock": "الحد الأدنى من المخزون",
    "Prioritizing Suppliers": "ترتيب أولويات الموردين",
    "Prioritizing Suppliers for Classes": "تحديد أولويات الموردين للفئات",
    "Enter Minimum Stock": "أدخل الحد الأدنى للمخزون",
    "Disable it from orders using history":
      "إلغائها من الطلبات باستخدام التاريخ",
    "Disable it from orders using days": "إلغائها من الطلبات باستخدام الأيام",
    "Are you want to fix order No. ": "هل تريد اصلاح الطلب رقم ",
    ") ?": ") ؟",
    Actions: "الإجراءات",
    "Admin Name": "اسم المدير",
    "Transfer Type": "نوع التحويل",
    "Select Transfer Type": "اختر نوع التحويل",
    "Are you want to fix all orders ?": "هل تريد إصلاح جميع الطلبات ؟",
    "Please enter a valid period !": "رجاءا إدخال فترة صالحة !",
    "You want to deactivate this card class ": " تريد إلغاء تفعيل هذه التصنيف ",
    "You want to deactivate this card ": " تريد إلغاء تفعيل هذه البطاقة ",
    "You want to deactivate this category ":
      " تريد إلغاء تفعيل فئة البطاقة هذه",
    "You want to activate this card class ": " تريد تفعيل هذه التصنيف ",
    "You want to activate this card ": " تريد تفعيل هذه البطاقة ",
    "You want to activate this category ": " تريد تفعيل فئة البطاقة هذه",
    "Do you want to correct this order using the return-to-repository method ?":
      "هل تريد تصحيح هذا الطلب باستخدام طريقة الإعادة إلى المستودع ؟",
    "Do you want to correct this order using the return-to-distributor method ?":
      "هل تريد تصحيح هذا الطلب باستخدام طريقة الإعادة إلى الموزع ؟",
    "These operations have been performed correctly since the order was created":
      "العمليات التي يتم إجراؤها لتصحيح الطلبية",
    "Operations performed to correct the order":
      "تم تنفيذ هذه العمليات بشكل صحيح منذ إنشاء الطلبية",
    "Select a distributor in order to show the data":
      "اختر موزع من أجل إظهار البيانات",
    "Select a distributor class in order to show the data":
      "اختر فئة الموزع لإظهار البيانات",
    "The service is not currently supported by this supplier.":
      "الخدمة غير مدعومة حاليًا من قبل هذا المورد.",
    "Number of codes that do not contain a PIN or that there was a problem when requesting it:":
      "عدد الكود التي لا تحتوي على PIN أو حدثت  مشكلة ما عند طلبها",
    "The maximum value that a POS can request through this category":
      "الحد الأقصى للقيمة التي يمكن لنقاط البيع طلبها من خلال هذه الفئة",
    "The quantity will be requested from all API suppliers via Buffer":
      "الكمية سوف يتم طلبها من جميع موردين API عبر البفر",
    "When the stock reaches this number, it will be filled from the buffer":
      "عندما يصل المخزون إلى هذا الرقم ، سيتم ملؤه من البفر",
    "This category will be assigned to the Ding property and removed from all other categories":
      "سيتم تعيين هذا التصنيف لخاصية الى Ding و الغائها من جميع التصنيفات الاخرى",
    "This category will be assigned to the Dtone property and removed from all other categories":
      "سيتم تعيين هذا التصنيف لخاصية الى Dtone و الغائها من جميع التصنيفات الاخرى",
    "You cannot search by reprint date because you want to allow reprints":
      "لا يمكنك البحث عن طريق تاريخ إعادة الطباعة لأنك تريد السماح بإعادة الطباعة",
    "Sorry, you can't search for more than 30 days":
      "عذرًا ، لا يمكنك البحث عن فتره تزيد عن 30 يومًا",
    "Are you sure you want to update the prices for all card POS pricing categories except for the cards you choose from the search filters?":
      "هل أنت متأكد من أنك تريد تحديث الأسعار لجميع فئات تسعير نقاط البيع الخاصة بالطاقات باستثناء البطاقات التي تختارها من عوامل تصفية البحث؟",
    "Are you sure you want to update the prices for all cards and all POS pricing categories except for the cards you choose from the search filters?":
      "هل أنت متأكد أنك تريد تحديث أسعار جميع البطاقات وجميع فئات تسعير نقاط البيع باستثناء البطاقات التي تختارها من فلاتر البحث؟",
    "Are you sure you want to update the prices for all cards except for the cards you choose from the search filters?":
      "هل أنت متأكد أنك تريد تحديث أسعار جميع البطاقات باستثناء البطاقات التي تختارها من فلاتر البحث؟",
    "Prices will be updated for all cards and all POS pricing categories except for the cards you choose from the search filters":
      "سيتم تحديث الأسعار لجميع البطاقات وجميع فئات تسعير نقاط البيع باستثناء البطاقات التي تختارها من فلاترالبحث",
    "Prices will be updated for all cards for the cards you choose from the search filters":
      "سيتم تحديث الأسعار لجميع البطاقات باستثناء البطاقات التي تختارها من فلاترالبحث",
    "Prices will be updated for all card POS pricing categories except for the cards you choose from the search filters":
      "سيتم تحديث الأسعار لجميع فئات تسعير نقاط البيع الخاصة بالطاقات باستثناء البطاقات التي تختارها من فلاتر البحث",
    "You cannot update rates by conversion because you did not enter the conversion rate correctly":
      "لا يمكنك تحديث الأسعار عن طريق التحويل لأنك لم تدخل معدل التحويل بشكل صحيح",
    "Please, choose the type of account you want for this employee":
      "من فضلك ، اختر نوع الحساب الذي تريده لهذا الموظف",
    "You cannot upload prices by excel file because you do not have POS price categories":
      "لا يمكنك تحميل الأسعار عن طريق ملف إكسل لأنه ليس لديك فئات أسعار نقاط البيع",
    "close problem status": "هل انت متاكد من انك تريد اغلاق هذه المشكله؟?",
    "Employee Name": "اسم الموظف",
    "Employee Number": "رقم الموظف",
    "Select Employee": "اختر اسم الموظف",
    "User level": "المستوى",
    CardsProfitInventoryReportAPI: "تقارير سحب بطاقات API",
    "Transfer Type": "نوع الحوالة",
    "Email address for charging points of sale balance":
      "البريد الالكتروني الخاص بشحن رصيد نقاط البيع",
    "Notification Email": "البريد الالكتروني الخاص بالاشعارات (ملئ الفئات)",
    "Point of sale application Email":
      "البريد الالكتروني الخاص بتطبيق نقاط البيع",
    "Allow work with Ding": "السماح للتعامل مع Ding",
    "Ding percentage": "النسبة المئوية ل Ding",
    "Allow work with DTone": "السماح للتعامل مع DTone",
    "Allow modification of purchase prices": "سماح تعديل اسعار الشراء",
    "Allow release of order information": "سماح بإصدار معلومات الطلب",
    "Change currency": "تغيير العملة",
    "Display a logo for points of sale": "اظهار شعار لنقاط البيع",
    "App Logo": "شعار التطبيق",
    "Show the order number to the points of sale":
      "اظهار رقم الطلبيه لنقاط البيع",
    "Tax distributor": "موزع ضريبي",
    "Invoice message text": "نص رسالة الفاتورة",
    "Invoice message notes": "ملاحظات الفاتورة",
    "Bill status": "حالة الفاتورة",
    "Create a new Distributer": "انشاء موزع جديد",
    "dist type": "نوع الموزع",
    "Get Codes By Send Email": "تصدير بطاقات عن طريق الايميل",
    Linked: "مرتبط بنقطة بيع",
    Unlinked: "غير مرتبط بنقطة بيع",
    "Serial status": "حالة السيريال",
    Unlink: "فك الربط",
    "Edit Link": "تعديل حالة الربط",
    Link: "ربط",
    "Edit Serial": "تعديل السيريال",
    Cheque: "شييك",
    "Cheque Image": "صورة الشييك",
    Cash: "كاش",
    "Serial Link": "ربط السيريال",
    "Edit Serial Link": "تعديل ربط السيريال",
    "Edit Serial Number": "تعديل السيريال",
    "Payment Method": "طريقة الدفع",
    totalPosCount: "عدد نقاط البيع",
    "An error occurred while processing your request.":
      "حدث خطأ أثناء معالجة طلبك.",
    "Qr Code Type": "نوع كود الQR",
    "QR Code Types": "انواع كود الQR",
    formTitle: "نموذج",
    "The billing number is the billing number,subscriber number, phone number, or university number":
      "رقم الفاتورة هو رقم الفاتورة أو رقم المشترك أو رقم الهاتف أو رقم الجامعة",
    transReferenceId: "معرف مرجع المعاملة",
    referenceId: "معرف المعاملة",
    transAmount: "قيمة المعاملة",
    transEntryDate: "تاريخ العملية",
    transErrorDesc: "وصف خطأ المعاملة",
    transTitle: "عنوان المعاملة",
    transStatus: "حالة العملية",
    paymentUrl: "رابط الدفع",
    "You need to update your password to be able to login":
      "يجب ان تغير كلمة السر الخاصة بك لتتمكن من تسجيل الدخول",
    "POS Limits": "محددات الفواتير",
    maxCashInHand: "الحد الاقصى للذمم",
    maxCashDebit: "الحد الاعلى للدين",
    debit: "الدين",
    "POS Supervisor Name": "اسم مندوب نقاط البيع",
    "Select POS Supervisor": "اختر مندوب نقاط البيع",
    totalSent: "مجموع المبالغ المرسلة",
    totalReceived: "مجموع المبالغ المستقبلة",
    operationclose: "اغلاق فاتورة",
    operationportion: "دفع جزئي",
    "Sender Name": "اسم المرسل",
    "transferable employees": "الموظفين الذين يمكن تحويل الذمم اليهم",
    token: "التوكن",
    apiKey: "رمز الAPI",
    "Update token": "تحديث التوكن",
    apiPassword: "كلمة سر الAPI",
    "Only numbers are allowed": "فقط الارقام مسموحه",
  },
};
