import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { translations } from "../../../../translate.js";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import { atelierEstuaryDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Picky } from "react-picky";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import "react-picky/dist/picky.css";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class EmpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceCat: "",
      numDaysToViewOrders:
        this.props.status == "add"
          ? "0"
          : this.props.empData.numDaysToViewOrders,
      showExportOrder:
        this.props.status == "add" ? 0 : this.props.empData.showExportOrder,
      submitError: "",
      payFromDistMoney:
        this.props.status == "add"
          ? "0"
          : this.props.empData["payFromDistMoney"],
      fname: this.props.status == "add" ? "" : this.props.empData["fname"],
      lname: this.props.status == "add" ? "" : this.props.empData["lname"],
      joinDate:
        this.props.status == "add" ? "" : this.props.empData["joinDate"],
      mobile: this.props.status == "add" ? "" : this.props.empData["mobile"],
      userEmail:
        this.props.status == "add" ? "" : this.props.empData["userEmail"],
      posCode: this.props.status == "add" ? "" : this.props.empData["posCode"],
      lat: this.props.status == "add" ? "" : this.props.empData["lat"],
      longitude: this.props.status == "add" ? "" : this.props.empData["lag"],
      taxNumber:
        this.props.status == "add" ? "" : this.props.empData["distTaxNumber"],
      userPassword: "",
      // userPassword: (this.props.status == 'add') ? '' : this.props.empData['userPassword'],
      // efwatercomAllow: (this.props.status == 'add') ? '' : this.props.empData['efwatercomAllow'],
      address: this.props.status == "add" ? "" : this.props.empData["address"],
      posId: this.props.status == "add" ? 0 : this.props.empData["userId"],
      priceCatId:
        this.props.status == "add" ? 0 : this.props.empData["priceCatId"],
      imageUrl: this.props.status == "add" ? 0 : this.props.empData["imageUrl"],
      imageUrlNew:
        this.props.status == "add" ? 0 : this.props.empData["imageUrlNew"],
      posQrImgURL:
        this.props.status == "add" ? 0 : this.props.empData["posQrImg"],
      cabEditClass:
        this.props.status == "add"
          ? true
          : this.props.empData["parentsId"] ==
            localStorage.getItem("PERM_DIST") ||
            localStorage.getItem("PERM_TYPE") == 5 ||
            this.props.permType == 5
            ? true
            : localStorage.getItem("ACCOUNT_TYPE") == 1 &&
              localStorage.getItem("USER_LEVEL") == 3
              ? true
              : false, // && this.props.empData['accountType'] != 8
      posParentId:
        this.props.status == "add" ? 0 : this.props.empData["posParentId"],
      accountType:
        this.props.status == "add" ? 0 : this.props.empData["accountType"],
      selectedPosList:
        this.props.status == "add"
          ? []
          : this.props.empData["posPerm"] != undefined &&
            this.props.pageView == 1
            ? this.props.empData["posPerm"]
            : [],
      staffsList: [],
      staffs: [],
      staffsList2:
        this.props.status == "add" ? [] : this.props.empData["staffPerm"],
      selectedPos: [],
      supervisorPos: [],
      supervisorPosList: [],
      selectSupervisorPosList:
        this.props.status == "add" ? [] : this.props.empData["superPosPerm"],
      pageView: this.props.pageView == undefined ? 0 : this.props.pageView,
      mangerPosList:
        this.props.mangerPos == undefined ? [] : this.props.mangerPos,
      accountTypes: this.props.accountTypes,
      selectedAcountTypes: [],
      image1: 0,
      image2: 0,
      posQrImg: 0,
      deleteImage1: 0,
      deleteImage2: 0,
      deletePosQrImg: 0,
      posList: [],
      classList: [],
      openBills:
        this.props.status == "add" ? "0" : this.props.empData["openBills"],
      visaPay: this.props.status == "add" ? "0" : this.props.empData["visaPay"],
      userPhoneForLogIn:
        this.props.status == "add"
          ? ""
          : this.props.empData["userPhoneForLogIn"],
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  getPriceCatInfo = async () => {
    try {
      console.log(
        localStorage.getItem("PRICE_CAT_ID"),
        'localStorage.getItem("PRICE_CAT_ID")'
      );

      const response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify({
          type: "getPriceCatIdById",
          priceCatId:
            this.props.status == "add"
              ? localStorage.getItem("PRICE_CAT_ID")
              : this.props.empData["priceCatId"],
          lang: localStorage.getItem("LANG"),
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
        headers: {
          Accept: "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ priceCat: data?.name });
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  componentDidMount() {
    console.log(this.props.empData?.staffPerm, "this.props.empData");
    if (localStorage.getItem("POS_MGR_EMP") != 1) {
      this.getDistClasses();
    }

    if (
      this.state.pageView == 1 &&
      (this.state.accountType == 8 || this.state.accountType == 17)
    ) {
      console.log("hello");

      this.getDistPos();
      this.getSuperVisors();
    }
    if (this.state.pageView == 1 || this.state.accountType == 18) {
      this.getDistPos();
      this.getStaff();
    }
    if (this.state.pageView == 1 || this.state.accountType == 19) {
      this.getStaff();
      this.getSuperVisors();
    }
    if (
      this.state.pageView != 1 &&
      (this.state.permType == 5 ||
        localStorage.getItem("PERM_TYPE") == 5 ||
        localStorage.getItem("USER_LEVEL") == 1)
    ) {
      this.getSuperVisors();
    }
    if (
      this.state.pageView != 1 &&
      (localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
        localStorage.getItem("USER_LEVEL") == 18 ||
        localStorage.getItem("USER_LEVEL") == 19)
    ) {
      this.getPriceCatInfo();
    }
  }
  getSuperVisors = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistPos",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        getType: "all",
        accountType:
          this.state.accountType == 17 ||
            this.state.accountType == 18 ||
            this.state.accountType == 19
            ? "17,18,19"
            : 17,
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState(
              {
                supervisorPosList: data["data"].filter(
                  (e) => e.userId != this.state.posId
                ),
              },
              function () {
                if (
                  this.state.selectSupervisorPosList?.length > 0 &&
                  this.props.status != "add"
                ) {
                  var list = [];
                  var pos = "";
                  for (var key in this.state.supervisorPosList) {
                    pos = this.state.selectSupervisorPosList.includes(
                      this.state.supervisorPosList[key]["userId"]
                    );
                    if (pos == true) {
                      list.push(this.state.supervisorPosList[key]);
                    }
                  }
                  this.setState({
                    supervisorPos: list,
                  });
                }
                if (
                  this.props.status != "add" &&
                  this.props?.empData?.supervisorPosId != undefined &&
                  this.state.pageView != 1 &&
                  (this.state.permType == 5 ||
                    localStorage.getItem("PERM_TYPE") == 5 ||
                    localStorage.getItem("USER_LEVEL") == 1)
                ) {
                  this.setState({
                    supervisorPos:
                      data.data.filter(
                        (e) => e.userId == this.props?.empData?.supervisorPosId
                      )?.length == 1
                        ? data.data.filter(
                          (e) =>
                            e.userId == this.props?.empData?.supervisorPosId
                        )[0]
                        : "",
                  });
                }
              }
            );
          }
        }
      });
  };
  getStaff = () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        accountType: [],
        distId: this.props.distId ? this.props.distId : "",
        getType: "all",
        allEmp: "1",
        type: "getDistPos",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState(
              {
                staffsList: data["data"].filter(
                  (e) => e.userId != this.state.posId
                ),
              },
              function () {
                if (
                  this.state.staffsList2?.length > 0 &&
                  this.props.status != "add"
                ) {
                  this.setState({
                    staffs: data["data"].filter((elem) =>
                      this.state.staffsList2.includes(elem.userId)
                    ),
                  });
                }
              }
            );
          }
        }
      });
  };
  getDistPos() {
    console.log("gw");

    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistPos",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        getType: "all",
        accountType: 7,
        distId: this.props.distId ? this.props.distId : "",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState(
              {
                posList: data["data"],
              },
              function () {
                if (
                  this.state.selectedPosList?.length > 0 &&
                  this.props.status != "add"
                ) {
                  var list = [];
                  var pos = "";
                  for (var key in this.state.posList) {
                    pos = this.state.selectedPosList.includes(
                      this.state.posList[key]["userId"]
                    );
                    if (pos == true) {
                      list.push(this.state.posList[key]);
                    }
                  }
                  this.setState({
                    selectedPos: list,
                  });
                }
              }
            );
          }
        }
      });
  }

  getDistClasses() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getDistClasses",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.props.distId ? this.props.distId : "",
        list: localStorage.getItem("USER_LEVEL") == 1 ? 1 : "",

        page: 1, //
        limit: 100, //
        getType: "all",
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('====================================== getDistClasses');
          // console.log(data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({
              classList: data["data"],
            });
          }
        }
      });
  }
  deleteUploadImage = (type) => {
    if (type == "1") {
      this.setState({ deleteImage1: 1 });
    } else if (type == "2") {
      this.setState({ deleteImage2: 1 });
    } else {
      this.setState({ deletePosQrImg: 1 });
    }
  };

  handleValidSubmit(event) {
    event.preventDefault();

    var canSave = 1;

    // check password
    if (
      this.state.userPassword != "" &&
      this.state.userPassword != null &&
      this.state.userPassword != undefined &&
      this.state.confirmPassword != "" &&
      this.state.confirmPassword != null &&
      this.state.confirmPassword != undefined
    ) {
      if (this.state.userPassword != this.state.confirmPassword) {
        canSave = 0;
        this.setState({ submitError: Strings["Password Not Match"] });
      }
    }
    // check accountype
    if (
      this.state.accountType == "" &&
      this.props.status == "add" &&
      this.state.pageView == 1
    ) {
      canSave = 0;
      this.setState({
        submitError:
          Strings[
          "Please, choose the type of account you want for this employee"
          ],
      });
    }
    if (canSave == 1) {
      var data = new FormData();
      var posImg =
        document.querySelector("input[name=posImg]") != null
          ? document.querySelector("input[name=posImg]").files[0]
          : "";
      var posImg2 =
        document.querySelector("input[name=posImg2]") != null
          ? document.querySelector("input[name=posImg2]").files[0]
          : "";
      var posQrImg =
        document.querySelector("input[name=posQrImg]") != null
          ? document.querySelector("input[name=posQrImg]").files[0]
          : "";

      // console.log('posImg');
      // console.log(posImg);
      // console.log('posImg2');
      // console.log(posImg2);

      var uploadedImages = "";
      if (
        this.state.image1 == 1 &&
        this.state.image2 == 1 &&
        this.state.posQrImg == 1
      ) {
        uploadedImages = "all";
      } else if (this.state.image1 == 1 && this.state.image2 == 1) {
        uploadedImages = "2image";
      } else if (this.state.image1 == 1 && this.state.posQrImg == 1) {
        uploadedImages = "3image";
      } else if (this.state.image2 == 1 && this.state.posQrImg == 1) {
        uploadedImages = "4image";
      } else if (this.state.image1 == 1) {
        uploadedImages = "1";
      } else if (this.state.image2 == 1) {
        uploadedImages = "2";
      } else if (this.state.posQrImg == 1) {
        uploadedImages = "3";
      }
      var deletedImages = "";
      if (
        this.state.deleteImage1 == 1 &&
        this.state.deleteImage2 == 1 &&
        this.state.deletePosQrImg == 1
      ) {
        deletedImages = "all";
      } else if (this.state.deleteImage1 == 1 && this.state.deleteImage2 == 1) {
        deletedImages = "2image";
      } else if (
        this.state.deleteImage1 == 1 &&
        this.state.deletePosQrImg == 1
      ) {
        deletedImages = "3image";
      } else if (
        this.state.deleteImage2 == 1 &&
        this.state.deletePosQrImg == 1
      ) {
        deletedImages = "4image";
      } else if (this.state.deleteImage1 == 1) {
        deletedImages = "1";
      } else if (this.state.deleteImage2 == 1) {
        deletedImages = "2";
      } else if (this.state.deletePosQrImg == 1) {
        deletedImages = "3";
      }

      var location = this.state.lat + "," + this.state.longitude;

      data.append("data[]", posImg);
      // userPhoneForLogIn
      data.append("userPhoneForLogIn", this.state.userPhoneForLogIn);
      data.append("showExportOrder", this.state?.showExportOrder);
      data.append("data[]", posImg2);
      data.append("data[]", posQrImg);
      data.append("type", "addPos");
      data.append("numDaysToViewOrders", this.state?.numDaysToViewOrders);
      if (
        (localStorage.getItem("USER_LEVEL") == 1 &&
          this.props.distData?.visaPay == 1 &&
          (localStorage.getItem("PERM_TYPE") == 5 ||
            this.props.permType == 5)) ||
        (localStorage.getItem("VISA_PAY") == 1 &&
          (localStorage.getItem("PERM_TYPE") == 5 ||
            this.props.permType == 5)) ||
        (localStorage.getItem("ACCOUNT_TYPE") == 8 &&
          localStorage.getItem("USER_LEVEL") == 8 &&
          localStorage.getItem("VISA_PAY") == 1 &&
          this.state.pageView != 1)
      ) {
        data.append("visaPay", this.state?.visaPay);
      }
      data.append("userId", localStorage.getItem("USER_ID"));
      data.append("token", localStorage.getItem("USER_TOKEN"));
      data.append("lang", localStorage.getItem("LANG"));
      var indexUserId = this.state.pageView == 1 ? "empId" : "posId";
      data.append(indexUserId, this.state.posId);
      if (this.state.pageView == 1) {
        data.append("accountType", this.state.accountType);
        if (this.state.accountType == 8 || this.state.accountType == 17) {
          data.append(
            "pos",
            JSON.stringify(this.state.selectedPos.map((data) => data.userId))
          );
        }
        if (this.state.accountType == 17 || this.state.accountType == 18) {
          data.append("payFromDistMoney", this.state.payFromDistMoney);
          data.append("openBills", this.state.openBills);
          data.append(
            "supervisorPos",
            JSON.stringify(this.state.supervisorPos.map((data) => data.userId))
          );
        }
        if (this.state.accountType == 18) {
          data.append(
            "supervisorId",
            JSON.stringify(this.state.supervisorPos.map((data) => data.userId))
          );
        }
        if (this.state.accountType == 19) {
          data.append(
            "supervisorId",
            JSON.stringify(this.state.supervisorPos.map((data) => data.userId))
          );
          data.append(
            "staffs",
            JSON.stringify(this.state.staffs.map((data) => data.userId))
          );
        }
      }

      if (
        (localStorage.getItem("PERM_TYPE") == 5 || this.props.permType == 5) &&
        this.state.pageView != 1
      ) {
        data.append(
          "supervisorPos",
          this.state.supervisorPos?.userId || undefined
        );
      }
      data.append("fname", this.state.fname);
      data.append("lname", this.state.lname);
      data.append("joinDate", this.state.joinDate);
      data.append("mobile", this.state.mobile);
      data.append("userEmail", this.state.userEmail);
      data.append("userPassword", this.state.userPassword);
      data.append("address", this.state.address);
      data.append("location", location);
      data.append("taxNumber", this.state.taxNumber);
      data.append("priceCatId", this.state.priceCatId);
      data.append("posCode", this.state.posCode);
      data.append("uploadedImages", uploadedImages);
      data.append("deletedImages", deletedImages);
      data.append("posParentId", this.state.posParentId);
      data.append("distId", this.props.distId ? this.props.distId : "");

      fetch(window.API_PERM_USER, {
        // mode: 'no-cors',
        method: "POST",
        body: data,
        headers: {
          Accept: "application/json",
        },
      })
        .then(function (response) {
          if (!response.ok) {
            // console.log(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            // console.log('===============================');
            // console.log(data);

            if (data["logout"] == 1) {
              localStorage.setItem("USER_ID", "");
              localStorage.setItem("USER_TOKEN", "");
              window.location.href = "/logout";
            } else if (data["error"] == 0) {
              this.props.handleFormAction();
            } else {
              this.setState({ submitError: data["errorMsg"] });
            }
          }
        });
    }
  }

  handleInputChange = (event) => {
    console.log(event.target);

    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        submitError: "",
      },
      () => {
        if (name == "accountType") {
          if (this.props.status == "add") {
            this.setState(
              {
                supervisorPosList: [],
                supervisorPos: [],
                staffs: [],
                staffsList: [],
              },
              () => {
                this.getSuperVisors();
                this.getStaff();
              }
            );
          } else {
            this.setState(
              { supervisorPosList: [], staffs: [], staffsList: [] },
              () => {
                this.getSuperVisors();
                this.getStaff();
              }
            );
          }
        }
      }
    );
  };

  handleImgChange = (event, type) => {
    if (type == 1) {
      var fileReader = new FileReader();
      const scope = this;

      if (event.target.files[0].type.includes("image")) {
        this.setState({ displayErrorHeaderMsg: false });
        this.setState({ progressUploadHeader: 10 });
        fileReader.readAsBinaryString(event.target.files[0]);
        fileReader.onprogress = function (data) {
          if (data.lengthComputable) {
            var progress = parseInt((data.loaded / data.total) * 100, 10);
            scope.setState({ progressUploadHeader: progress });
          }
        };
      } else {
        this.setState({ displayErrorHeaderMsg: true });
      }

      this.setState({
        image1: 1,
      });
    } else if (type == 2) {
      var fileReader = new FileReader();
      const scope = this;

      if (event.target.files[0].type.includes("image")) {
        this.setState({ displayErrorHeaderMsg_2: false });
        this.setState({ progressUploadHeader_2: 10 });
        fileReader.readAsBinaryString(event.target.files[0]);
        fileReader.onprogress = function (data) {
          if (data.lengthComputable) {
            var progress = parseInt((data.loaded / data.total) * 100, 10);
            scope.setState({ progressUploadHeader_2: progress });
          }
        };
      } else {
        this.setState({ displayErrorHeaderMsg_2: true });
      }

      this.setState({
        image2: 1,
      });
    } else if (type == 3) {
      var fileReader = new FileReader();
      const scope = this;

      if (event.target.files[0].type.includes("image")) {
        this.setState({ displayErrorHeaderMsg_3: false });
        this.setState({ progressUploadHeader_3: 10 });
        fileReader.readAsBinaryString(event.target.files[0]);
        fileReader.onprogress = function (data) {
          if (data.lengthComputable) {
            var progress = parseInt((data.loaded / data.total) * 100, 10);
            scope.setState({ progressUploadHeader_3: progress });
          }
        };
      } else {
        this.setState({ progressUploadHeader_3: true });
      }

      this.setState({
        posQrImg: 1,
      });
    }
  };

  handleChangeSelect = (type, option) => {
    console.log("[type]: option", { [type]: option });

    this.setState((state) => {
      return {
        [type]: option,
      };
    });
  };
  setUpSelectOptions = (data, type) => {
    if (type == "class") {
      return (
        <option value={data.id} key={data.id}>
          {data.name}
        </option>
      );
    } else if (type == "acouuntType") {
      return (
        <option value={data.user_type} key={data.user_type}>
          {localStorage.getItem("LANG") == 1 ? data.name_ar : data.name_en}
        </option>
      );
    } else {
      return (
        <option value={data.userId} key={data.userId}>
          {data.fname}
          {data.lname}
        </option>
      );
    }
  };

  render() {
    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col md={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {this.props.status == "add"
                  ? Strings["Add"]
                  : Strings["Edit"]}{" "}
                {(localStorage.getItem("PERM_TYPE") == 5 ||
                  this.props.permType == 5 ||
                  localStorage.getItem("POS_MGR_EMP") == 1 ||
                  localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
                  localStorage.getItem("USER_LEVEL") == 18 ||
                  localStorage.getItem("USER_LEVEL") == 19) &&
                  this.state.pageView != 1
                  ? Strings["POS"]
                  : Strings["Employee"]}
              </div>
            </Col>
            <Col md={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <AvForm
            autocomplete="off"
            onValidSubmit={this.handleValidSubmit}
            ref={(c) => (this.form = c)}
          >
            {(localStorage.getItem("POS_MGR_EMP_NEW") == 1 ||
              localStorage.getItem("USER_LEVEL") == 18 ||
              localStorage.getItem("USER_LEVEL") == 19) && (
                <AvField
                  name="priceCat"
                  label={Strings["priceCat"] + " *"}
                  placeholder={Strings["priceCat"]}
                  value={this.state.priceCat}
                  disabled={true}
                  onChange={this.handleInputChange}
                  errorMessage={Strings["This field is required"]}
                />
              )}
            <AvField
              name="fname"
              autocomplete="off"
              label={Strings["First Name"] + " *"}
              placeholder={Strings["Enter First Name"]}
              value={this.state.fname}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />

            <AvField
              name="lname"
              autocomplete="off"
              label={Strings["Last Name"] + " *"}
              placeholder={Strings["Enter Last Name"]}
              value={this.state.lname}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              name="joinDate"
              type="date"
              label={Strings["Subscription Date"] + " *"}
              placeholder={Strings["Enter Subscription Date"]}
              value={this.state.joinDate}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              name="mobile"
              type="number"
              label={Strings["Phone"] + " *"}
              placeholder={Strings["Enter Phone"]}
              value={this.state.mobile}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              name="userEmail"
              autoComplete={"off"}
              type="email"
              label={Strings["Email"] + " *"}
              placeholder={Strings["Enter Email"]}
              value={this.state.userEmail}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            {this.state.pageView != 1 && (
              <AvField
                name="userPhoneForLogIn"
                label={Strings["Login Phone Number"] + " *"}
                placeholder={Strings["Enter Phone Number"]}
                value={this.state.userPhoneForLogIn}
                required
                type="number"
                onChange={this.handleInputChange}
                errorMessage={Strings["This field is required"]}
              />
            )}
            {/* ############# */}
            {(localStorage.getItem("PERM_TYPE") == 2 || this.props.permType == 2 ||
              localStorage.getItem("PERM_TYPE") == 5 || this.props.permType == 5) &&
              this.state.pageView == 1 && (

                <AvField
                  disabled={this.props.status == "add" ? false : true}
                  required
                  type="select"
                  name="accountType"
                  label={Strings["Acount Type"]}
                  value={this.state.accountType}
                  onChange={this.handleInputChange}
                  className="p-1 pl-3"
                  errorMessage={Strings["This field is required"]}
                >
                  <option
                    style={{
                      display: "none",
                    }}
                    value=""
                    selected="selected"
                  >
                    {Strings["Select Acount Type"]}
                  </option>
                  {this.state.accountTypes.map((data) =>
                    this.setUpSelectOptions(data, "acouuntType")
                  )}
                </AvField>
              )
            }

            {(localStorage.getItem("PERM_TYPE") == 5 ||
              this.props.permType == 5) &&
              this.state.pageView == 1 &&
              (this.state.accountType == 8 || this.state.accountType == 17) && (
                <div className="member-searchable-list mb-2">
                  <label className="labelEmpForm">{Strings["POS"]} *</label>
                  {/* className={this.state.msgError == 2 ? 'label-error' : ''} */}
                  <Picky
                    value={this.state.selectedPos}
                    options={this.state.posList}
                    onChange={this.handleChangeSelect.bind(this, "selectedPos")}
                    open={false}
                    valueKey="userId"
                    labelKey={"fullName"}
                    multiple={true}
                    includeSelectAll={true}
                    includeFilter={true}
                    dropdownHeight={200}
                    placeholder={Strings["POS Name"]}
                    filterPlaceholder={Strings["Select POS"]}
                  />
                </div>
              )}
            {((localStorage.getItem("PERM_TYPE") == 5 &&
              localStorage.getItem("OPEN_BILLS") == 1) ||
              this.props.permType == 5) &&
              this.state.pageView == 1 &&
              (this.state.accountType == 17 ||
                this.state.accountType == 18 ||
                this.state.accountType == 19) && (
                <div className="member-searchable-list mb-2">
                  <label className="labelEmpForm">
                    {Strings["transferable employees"]} *
                  </label>
                  <Picky
                    value={this.state.supervisorPos}
                    options={this.state.supervisorPosList}
                    onChange={this.handleChangeSelect.bind(
                      this,
                      "supervisorPos"
                    )}
                    valueKey="userId"
                    labelKey={"fullName"}
                    multiple={true}
                    includeSelectAll={true}
                    includeFilter={true}
                    dropdownHeight={200}
                    placeholder={`${Strings["Select"]} ${Strings["transferable employees"]}`}
                    filterPlaceholder={`${Strings["Select"]} ${Strings["transferable employees"]}`}
                  />
                </div>
              )}
            {((localStorage.getItem("PERM_TYPE") == 5 &&
              localStorage.getItem("OPEN_BILLS") == 1) ||
              this.props.permType == 5) &&
              this.state.pageView == 1 &&
              this.state.accountType == 19 && (
                <div className="member-searchable-list mb-2">
                  <label className="labelEmpForm">
                    {Strings["Responsible staff"]} *
                  </label>
                  <Picky
                    value={this.state.staffs}
                    options={this.state.staffsList}
                    onChange={this.handleChangeSelect.bind(this, "staffs")}
                    valueKey="userId"
                    labelKey={"fullName"}
                    multiple={true}
                    includeSelectAll={true}
                    includeFilter={true}
                    dropdownHeight={200}
                    placeholder={`${Strings["Select"]} ${Strings["Responsible staff"]}`}
                    filterPlaceholder={`${Strings["Select"]} ${Strings["Responsible staff"]}`}
                  />
                </div>
              )}
            {(localStorage.getItem("PERM_TYPE") == 5 ||
              this.props.permType == 5) &&
              this.state.pageView != 1 && (
                <div className="member-searchable-list mb-2">
                  <label className="labelEmpForm">
                    {Strings["POS Supervisor"]} *
                  </label>
                  <Picky
                    value={this.state.supervisorPos}
                    options={this.state.supervisorPosList}
                    onChange={this.handleChangeSelect.bind(
                      this,
                      "supervisorPos"
                    )}
                    open={false}
                    valueKey="userId"
                    labelKey={"fullName"}
                    multiple={false}
                    includeSelectAll={false}
                    includeFilter={true}
                    dropdownHeight={200}

                    placeholder={Strings["POS Supervisor Name"]}
                    filterPlaceholder={Strings["Select POS Supervisor"]}
                  />
                </div>
              )}

            {(localStorage.getItem("PERM_TYPE") == 5 ||
              this.props.permType == 5) &&
              this.state.pageView == 1 &&
              (this.state.accountType == 17 ||
                this.state.accountType == 18) && (
                <div className="form-group mb-0">
                  <FormControlLabel
                    label={
                      <>
                        <label className="font-w-400">
                          {Strings["payFromDistMoney"]}
                        </label>
                      </>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        name="payFromDistMoney"
                        // onBlur={handleBlur}
                        onChange={() => {
                          this.setState({
                            payFromDistMoney:
                              this.state.payFromDistMoney == 1 ? 0 : 1,
                          });
                        }}
                        checked={this.state.payFromDistMoney == 1}
                      />
                    }
                  />
                </div>
              )}

            {(localStorage.getItem("PERM_TYPE") == 5 ||
              this.props.permType == 5) &&
              this.state.pageView == 1 &&
              (this.state.accountType == 17 ||
                this.state.accountType == 18) && (
                <div className="form-group mb-0">
                  <FormControlLabel
                    label={
                      <>
                        <label className="font-w-400">
                          {Strings["openBills"]}
                        </label>
                      </>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        name="openBills"
                        // onBlur={handleBlur}
                        onChange={() => {
                          this.setState({
                            openBills: this.state.openBills == 1 ? 0 : 1,
                          });
                        }}
                        checked={this.state.openBills == 1}
                      />
                    }
                  />
                </div>
              )}
            <AvField
              name="userPassword"
              autoComplete="new-password"
              type="password"
              label={
                this.props.status == "add"
                  ? Strings["Password"] + " *"
                  : Strings["Password"]
              }
              placeholder={Strings["Enter password"]}
              value={this.state.userPassword}
              required={this.props.status == "add" ? true : false}
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              name="confirmPassword"
              autoComplete="new-password"
              type="password"
              label={
                this.props.status == "add"
                  ? Strings["Confirm Password"] + " *"
                  : Strings["Confirm Password"]
              }
              placeholder={Strings["Enter password"]}
              value={this.state.confirmPassword}
              required={this.props.status == "add" ? true : false}
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />

            {this.props.permType == 5 &&
              // (localStorage.getItem("PERM_TYPE") == 5 ||
              //   this.props.permType == 5 ||
              //   (localStorage.getItem("ACCOUNT_TYPE") == 8 &&
              //     localStorage.getItem("USER_LEVEL") == 8))
              this.state.pageView != 1 && (
                <AvField
                  name="numDaysToViewOrders"
                  label={Strings["Number of days to see order history"] + " *"}
                  placeholder={Strings["Number of days to see order history"]}
                  value={this.state.numDaysToViewOrders}
                  type="number"
                  onChange={this.handleInputChange}
                  errorMessage={Strings["This field is required"]}
                />
              )}
            {((localStorage.getItem("PERM_TYPE") == 5 &&
              localStorage.getItem("SHOW_ORDER_EXPORT") == 1) ||
              this.props.permType == 5) &&
              this.state.pageView != 1 && (
                <div className="form-group mb-0">
                  <FormControlLabel
                    label={
                      <>
                        <label className="font-w-400">
                          {Strings["Allow release of order information"]}
                        </label>
                      </>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        name="showExportOrder"
                        onChange={() => {
                          this.setState({
                            showExportOrder:
                              this.state.showExportOrder == 1 ? 0 : 1,
                          });
                        }}
                        checked={this.state.showExportOrder == 1}
                      />
                    }
                  />
                </div>
              )}
            {((localStorage.getItem("USER_LEVEL") == 1 &&
              this.props.distData?.visaPay == 1 &&
              (localStorage.getItem("PERM_TYPE") == 5 ||
                this.props.permType == 5)) ||
              (localStorage.getItem("VISA_PAY") == 1 &&
                (localStorage.getItem("PERM_TYPE") == 5 ||
                  this.props.permType == 5))) &&
              this.state.pageView != 1 && (
                <div className="form-group mb-0">
                  <FormControlLabel
                    label={
                      <>
                        <label className="font-w-400">
                          {Strings["visaPay"]}
                        </label>
                      </>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        name="visaPay"
                        onChange={() => {
                          this.setState({
                            visaPay: this.state.visaPay == 1 ? 0 : 1,
                          });
                        }}
                        checked={this.state.visaPay == 1}
                      />
                    }
                  />
                </div>
              )}
            <AvField
              name="address"
              label={Strings["Region"] + " *"}
              placeholder={Strings["Enter Region"]}
              value={this.state.address}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            {this.state.pageView != 1 && (
              <Row>
                <Col md={6}>
                  <AvField
                    name="lat"
                    label={Strings["Location"] + " *"}
                    placeholder={Strings["Latitude"]}
                    value={this.state.lat}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </Col>
                <Col md={6} className="transparent-label">
                  <AvField
                    name="longitude"
                    label="label"
                    placeholder={Strings["longitude"]}
                    value={this.state.longitude}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </Col>
              </Row>
            )}
            {this.state.pageView != 1 &&
              (localStorage.getItem("IS_TAXDIST") == 1 ||
                (localStorage.getItem("USER_LEVEL") == 1 &&
                  this?.props?.empData?.isTaxDist == 1)) && (
                <AvField
                  name="taxNumber"
                  type="number"
                  label={Strings["Tax Number"]}
                  placeholder={Strings["Enter Tax Number"]}
                  value={this.state.taxNumber}
                  onChange={this.handleInputChange}
                />
              )}
            {(this.state.accountType == 8 ||
              this.state.accountType == 17 ||
              this.state.accountType == 18 ||
              this.state.pageView != 1) &&
              localStorage.getItem("ACCOUNT_TYPE") != 8 &&
              localStorage.getItem("USER_LEVEL") != 8 && (
                <AvField
                  disabled={this.state.cabEditClass == true ? false : true}
                  type="select"
                  name="priceCatId"
                  label={Strings["Pos Class"]}
                  value={this.state.priceCatId}
                  onChange={this.handleInputChange}
                  className="p-1 pl-3"
                  errorMessage={Strings["This field is required"]}
                >
                  <option
                    style={{
                      display: "none",
                    }}
                    value=""
                    selected="selected"
                  >
                    {Strings["Select Pos Class"]}
                  </option>
                  {this.state.classList.map((data) =>
                    this.setUpSelectOptions(data, "class")
                  )}
                </AvField>
              )}
            {(localStorage.getItem("PERM_TYPE") == 5 ||
              this.props.permType == 5 ||
              localStorage.getItem("OPERA_EMP") == 1) &&
              this.state.pageView != 1 && (
                <AvField
                  disabled={this.state.cabEditClass == true ? false : true}
                  type="select"
                  name="posParentId"
                  label={Strings["POS Manager"]}
                  value={this.state.posParentId}
                  onChange={this.handleInputChange}
                  className="p-1 pl-3"
                  errorMessage={Strings["This field is required"]}
                >
                  <option
                    style={{
                      display: "none",
                    }}
                    value=""
                    selected="selected"
                  >
                    {Strings["Select POS Manager"]}
                  </option>
                  {this.state.mangerPosList.map((data) =>
                    this.setUpSelectOptions(data, "mangerPos")
                  )}
                </AvField>
              )}
            {this.state.pageView != 1 && (
              <AvField
                name="posCode"
                validate={
                  {
                    // minLength: { value: 6, errorMessage: Strings['Please enter a 6-digit code'] },
                    // maxLength: { value: 6, errorMessage: Strings['Please enter a 6-digit code'] }
                  }
                }
                label={Strings["Pos Code"]}
                placeholder={Strings["Enter Pos Code"]}
                value={this.state.posCode}
                onChange={this.handleInputChange}
              />
            )}
            {this.state.pageView != 1 && (
              <div className="mt-1 mb-2">
                <label className="font-w-400">{Strings["Image (1)"]}</label>
                <span className={"fileInput-span col-md-12"}>
                  <PublishIcon />{" "}
                  <span className="pointer-cursor pt-3">
                    {Strings["Upload"]}
                  </span>
                  <input
                    type="file"
                    accept="image/*"
                    className="fileInput-width fileInput-opacity"
                    onChange={(e) => this.handleImgChange(e, 1)}
                    name="posImg"
                  ></input>
                </span>
                {this.state.progressUploadHeader != undefined &&
                  this.state.progressUploadHeader > 0 && (
                    <Progress
                      striped
                      value={this.state.progressUploadHeader}
                      className="mb-1 mt-1 progress-bg-color"
                    >
                      {this.state.progressUploadHeader} %
                    </Progress>
                  )}
                {this.state.displayErrorHeaderMsg == true && (
                  <div className="invalid-feedback d-block mb-1">
                    {Strings["Upload Image only"]}
                  </div>
                )}

                {this.state.imageUrl != null &&
                  this.state.imageUrl != undefined &&
                  this.state.imageUrl != "" &&
                  this.state.deleteImage1 == 0 && (
                    <div className="text-center mt-4">
                      <img
                        src={this.state.imageUrl}
                        className="img-fluid inv-category-img"
                      />
                      <div
                        className="task-action-file-details-delete-icon"
                        onClick={() => this.deleteUploadImage("1")}
                      >
                        <span>
                          <DeleteIcon /> {Strings["Delete"]}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            )}
            {this.state.pageView != 1 && (
              <div className="mt-3 mb-4">
                <label className="font-w-400">{Strings["Image (2)"]}</label>
                <span className={"fileInput-span col-md-12"}>
                  <PublishIcon />{" "}
                  <span className="pointer-cursor pt-3">
                    {Strings["Upload"]}
                  </span>
                  <input
                    type="file"
                    accept="image/*"
                    className="fileInput-width fileInput-opacity"
                    onChange={(e) => this.handleImgChange(e, 2)}
                    name="posImg2"
                  ></input>
                </span>
                {this.state.progressUploadHeader_2 != undefined &&
                  this.state.progressUploadHeader_2 > 0 && (
                    <Progress
                      striped
                      value={this.state.progressUploadHeader_2}
                      className="mb-1 mt-1 progress-bg-color"
                    >
                      {this.state.progressUploadHeader_2} %
                    </Progress>
                  )}
                {this.state.displayErrorHeaderMsg_2 == true && (
                  <div className="invalid-feedback d-block mb-1">
                    {Strings["Upload Image only"]}
                  </div>
                )}

                {this.state.imageUrlNew != null &&
                  this.state.imageUrlNew != undefined &&
                  this.state.imageUrlNew != "" &&
                  this.state.deleteImage2 == 0 && (
                    <div className="text-center mt-4">
                      <img
                        src={this.state.imageUrlNew}
                        className="img-fluid inv-category-img"
                      />
                      <div
                        className="task-action-file-details-delete-icon"
                        onClick={() => this.deleteUploadImage("2")}
                      >
                        <span>
                          <DeleteIcon /> {Strings["Delete"]}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            )}
            {this.state.pageView != 1 && (
              <div className="mt-3 mb-4">
                <label className="font-w-400">{Strings["Qr Image"]}</label>
                <span className={"fileInput-span col-md-12"}>
                  <PublishIcon />{" "}
                  <span className="pointer-cursor pt-3">
                    {Strings["Upload"]}
                  </span>
                  <input
                    type="file"
                    accept="image/*"
                    className="fileInput-width fileInput-opacity"
                    onChange={(e) => this.handleImgChange(e, 3)}
                    name="posQrImg"
                  ></input>
                </span>
                {this.state.progressUploadHeader_3 != undefined &&
                  this.state.progressUploadHeader_3 > 0 && (
                    <Progress
                      striped
                      value={this.state.progressUploadHeader_3}
                      className="mb-1 mt-1 progress-bg-color"
                    >
                      {this.state.progressUploadHeader_3} %
                    </Progress>
                  )}
                {this.state.displayErrorHeaderMsg_3 == true && (
                  <div className="invalid-feedback d-block mb-1">
                    {Strings["Upload Image only"]}
                  </div>
                )}

                {this.state.posQrImgURL != null &&
                  this.state.posQrImgURL != undefined &&
                  this.state.posQrImgURL != "" &&
                  this.state.deletePosQrImg == 0 && (
                    <div className="text-center mt-4">
                      <img
                        src={this.state.posQrImgURL}
                        className="img-fluid inv-category-img"
                      />
                      <div
                        className="task-action-file-details-delete-icon"
                        onClick={() => this.deleteUploadImage("3")}
                      >
                        <span>
                          <DeleteIcon /> {Strings["Delete"]}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            )}
            {/* <AvField
                            label={Strings['Allow Use Efawtercom']}
                            type="checkbox"
                            name="efwatercomAllow"
                            ref="default"
                            value={this.state.efwatercomAllow == 1 || this.state.efwatercomAllow == true ? true : false}
                            onChange={this.handleInputChange}
                            checked={this.state.efwatercomAllow == 1 || this.state.efwatercomAllow == true ? true : false}
                            tag={CustomInput}
                            className='custom-form-mb default mb-3'
                        /> */}

            <div className="text-right mt-4">
              <button type="submit" className="btn form-save-btn mr-1 ml-1">
                {" "}
                {Strings["Save"]}{" "}
              </button>
              <button
                type="button"
                className="btn form-cancel-btn m-l-10"
                onClick={this.props.handleCloseSideBar}
              >
                {Strings["Cancel"]}
              </button>
            </div>

            {this.state.submitError && (
              <div className="error-text-black">
                <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                {this.state.submitError}
              </div>
            )}
          </AvForm>
        </div>
      </div>
    );
  }
}
export default EmpForm;
