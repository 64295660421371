import React, { Component } from "react";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import CryptoJS from "crypto-js";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { translations } from "../../../translate.js";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;
const SECRET_KEY = "EZ_PAY_ALBARAA_ALHADDAR";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loginError: "",
      viewMode: 1, // 1 for login , 2 for code
      qrCode: "",
      qrCodeError: "",
      loginCount: 0,
      saveLogin: false,
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  getIp = async () => {
    try {
      const ipResponse = await fetch("https://api.ipify.org?format=json");
      const { ip } = await ipResponse.json();
      localStorage.setItem("DEVICE_IP", ip);
    } catch (error) {
      console.log("Error getting an IP :", error);
    }
  };
  componentDidMount() {
    this.getIp();
    const isSaved = localStorage.getItem("SAVED") === "1";
    if (isSaved) {
      const savedCredentials = localStorage.getItem("CREDENTIALS");
      if (savedCredentials) {
        try {
          const bytes = CryptoJS.AES.decrypt(savedCredentials, SECRET_KEY);
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          this.setState({
            username: decryptedData.username,
            password: decryptedData.password,
            saveLogin: true,
          });
        } catch (err) {
          console.error("Failed to decode credentials", err);
        }
      }
    }
  }

  handleValidSubmit(event) {
    if (this.state.viewMode == 1) {
      this.loginHandler();
    } else {
      this.checkAuth();
    }
  }

  checkAuth = async () => {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "checkAuth",
        email: this.state.username,
        authSecret: this.state.authSecret,
        lang: localStorage.getItem("LANG"),
        code: this.state.qrCode,
        loginCount: this.state.loginCount,
        ip: localStorage.getItem("DEVICE_IP"),
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        this.setState({ loading: 1 });
        if (typeof data !== "undefined") {
          console.log("checkAuth :", data);
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            localStorage.setItem("USER_BALANCE", "");
            window.location.href = "/logout";
          } else if (data["error"] == 0) {
            if (this.state.saveLogin) {
              const encryptedCredentials = CryptoJS.AES.encrypt(
                JSON.stringify({
                  username: this.state.username,
                  password: this.state.password,
                }),
                SECRET_KEY
              ).toString();
              localStorage.setItem("SAVED", "1");
              localStorage.setItem("CREDENTIALS", encryptedCredentials);
            } else {
              localStorage.setItem("SAVED", "0");
              localStorage.setItem("CREDENTIALS", "");
            }
            localStorage.setItem("USER_ID", data["userInfo"]["userId"]);
            localStorage.setItem("PERM_API_PAGES", data["userInfo"]["apiPerm"]);
            localStorage.setItem("PERM_DIST", data["userInfo"]["permDist"]);
            localStorage.setItem(
              "ACCOUNT_TYPE",
              data["userInfo"]["accountType"]
            );
            localStorage.setItem(
              "USER_PRICE_SHOW",
              data["userInfo"]["pricesShow"]
            );
            localStorage.setItem(
              "POS_DAYS_NUM",
              data["userInfo"]?.numDaysToViewOrders
            );
            // localStorage.setItem(
            //   "BILLS_DAYS_NUM",
            //   data["userInfo"]?.numDaysToViewBills
            // );
            
            localStorage.setItem("USER_LEVEL", data["userInfo"]["userLevel"]);
            localStorage.setItem("PERM_TYPE", data["userInfo"]["permType"]);
            localStorage.setItem("OPEN_BILLS", data["userInfo"]["openBills"]);
            localStorage.setItem("USER_TOKEN", data["token"]);
            localStorage.setItem("IS_TAXDIST", data["userInfo"]["isTaxDist"]);
            console.log(
              'data["userInfo"]["isTaxDist"]',
              data["userInfo"]["isTaxDist"]
            );
            // return;

            localStorage.setItem("VISA_PAY", data["userInfo"]["visaPay"]);

            localStorage.setItem(
              "EDT_BUY_PRICE",
              data["userInfo"]["editBuyPrice"]
            );
            localStorage.setItem("USER_EMAIL", data["userInfo"]["userEmail"]);
            localStorage.setItem("CURRENCY_ID", data["userInfo"]["currency"]);
            localStorage.setItem(
              "loginDate",
              new Date().setDate(new Date().getDate() + 1)
            );
            localStorage.setItem("USER_FIRST_NAME", data["userInfo"]["fname"]);
            localStorage.setItem("USER_LAST_NAME", data["userInfo"]["lname"]);
            localStorage.setItem(
              "USER_FULL_NAME",
              data["userInfo"]["fname"] + " " + data["userInfo"]["lname"]
            );
            localStorage.setItem("POS_CODE", data["userInfo"]?.posCode);
            if (
              localStorage.getItem("LANG") == undefined ||
              localStorage.getItem("LANG") == "" ||
              localStorage.getItem("LANG") == null
            ) {
              localStorage.setItem("LANG", 2);
            }
            localStorage.setItem("INV_MGR", 0);
            if (
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == 0) &&
              localStorage.getItem("USER_LEVEL") == 4
            ) {
              localStorage.setItem("INV_MGR", 1);
            }
            localStorage.setItem("POS_MGR_EMP", 0);
            if (
              localStorage.getItem("ACCOUNT_TYPE") == 8 &&
              localStorage.getItem("USER_LEVEL") == 8
            ) {
              localStorage.setItem("POS_MGR_EMP", 1);
            }
            if (
              localStorage.getItem("ACCOUNT_TYPE") == 17 &&
              localStorage.getItem("USER_LEVEL") == 8
            ) {
              localStorage.setItem("POS_MGR_EMP_NEW", 1);
            }
            localStorage.setItem("ACC_EMP", 0);
            if (
              localStorage.getItem("ACCOUNT_TYPE") == 2 &&
              localStorage.getItem("USER_LEVEL") == 3
            ) {
              localStorage.setItem("ACC_EMP", 1);
            }
            localStorage.setItem("OPERA_EMP", 0);
            if (
              localStorage.getItem("ACCOUNT_TYPE") == 1 &&
              localStorage.getItem("USER_LEVEL") == 3
            ) {
              localStorage.setItem("OPERA_EMP", 1);
            }

            localStorage.setItem("iS_MAIN_ADMIN", 0);
            if (
              (localStorage.getItem("ACCOUNT_TYPE") == "" ||
                localStorage.getItem("ACCOUNT_TYPE") == undefined ||
                localStorage.getItem("ACCOUNT_TYPE") == null) &&
              localStorage.getItem("USER_LEVEL") == 1
            ) {
              localStorage.setItem(
                "iS_MAIN_ADMIN",
                data["userInfo"]["is_main"]
              );
            }
            localStorage.setItem("PERM_TYPE_PARENT", 0);
            if (
              data["userInfo"]["parentPermType"] != undefined &&
              data["userInfo"]["parentPermType"] != null &&
              data["userInfo"]["parentPermType"] != ""
            ) {
              localStorage.setItem(
                "PERM_TYPE_PARENT",
                data["userInfo"]["parentPermType"]
              );
            }
            localStorage.setItem("USER_BALANCE", "");
            localStorage.setItem("USER_BALANCE_FROM", "");
            if (
              (localStorage.getItem("PERM_TYPE") == 2 ||
                localStorage.getItem("PERM_TYPE") == 3 ||
                localStorage.getItem("PERM_TYPE") == 5 ||
                localStorage.getItem("POS_MGR_EMP") == 1) &&
              data["userInfo"]["distBalanceAmountDistCurr"] != undefined &&
              data["userInfo"]["currencyTitle"] != undefined
            ) {
              localStorage.setItem(
                "USER_BALANCE",
                data["userInfo"]["distBalanceAmountDistCurr"] +
                  " " +
                  data["userInfo"]["currencyTitle"]
              );
              localStorage.setItem("USER_BALANCE_FROM", "localStorage");
            }
            // console.log( localStorage.getItem('ACCOUNT_TYPE'))
            // console.log( localStorage.getItem('USER_LEVEL'))
            localStorage.setItem(
              "SHOW_COUPONS",
              data["userInfo"]["appearCoupons"]
            );
            // setTimeout(() => {
            //   window.location = "/dashboard";
            // }, 500);
            // console.log(,'showOrderId');
            localStorage.setItem(
              "SHOW_ORDER_ID_POS",
              data["userInfo"]?.showOrderId
            );
            localStorage.setItem(
              "SHOW_ORDER_EXPORT",
              data["userInfo"]?.showExportOrder
            );
            localStorage.setItem("PRICE_CAT_ID", data["userInfo"]?.priceCatId);
            if (data["userInfo"]?.passExpired) {
              window.location = "/password/changePassword";
            } else {
              window.location = "/dashboard";
            }
          } else {
            this.setState({ qrCodeError: data["errorMsg"] });
          }
        }
      });
  };

  loginHandler() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "login",
        email: this.state.username,
        password: this.state.password,
        lang: localStorage.getItem("LANG"),
        loginCount: this.state.loginCount,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        this.setState({ loading: 1 });
        if (typeof data !== "undefined") {
          if (data["logout"] == 1) {
            // localStorage.setItem('USER_ID', '');
            // localStorage.setItem('USER_TOKEN', '');
            // window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ loginError: data["errorMsg"] });
          } else if (data["error"] == 2) {
            this.setState({
              loginError: data["alertMsg"],
              loginCount: data["loginCount"],
            });
          } else {
            this.setState({
              loginError: "",
              viewMode: 2,
              authSecret: data["authSecret"],
              qrCodeUrl: data["qrCodeUrl"],
            });
          }
        }
      });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <div className="login-form-view-padding">
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3 className="main-color-1">
                {this.state.viewMode == 1 && Strings["Login to your account"]}
                {this.state.viewMode == 2 && Strings["Confirm Code"]}
              </h3>
              <hr
                className={
                  this.state.viewMode == 1
                    ? "login-hr-view-1"
                    : "login-hr-view-2"
                }
              />
            </div>

            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
              className="login-form-input main-color-1"
            >
              {this.state.viewMode == 1 && (
                <div>
                  <AvField
                    type="email"
                    name="username"
                    label={Strings["Email"]}
                    placeholder={Strings["Enter your email"]}
                    value={this.state.username}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <AvField
                    type="password"
                    name="password"
                    label={Strings["Password"]}
                    placeholder={Strings["Enter password"]}
                    value={this.state.password}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <div className="form-group no-margin-from-control">
                    <FormControlLabel
                      label={
                        <>
                          <label className="font-w-400 ">
                            {Strings["Remember me"]}
                          </label>
                        </>
                      }
                      control={
                        <Checkbox
                          color="primary"
                          name="saveLogin"
                          onChange={() => {
                            this.setState({
                              saveLogin: this.state.saveLogin == 1 ? 0 : 1,
                            });
                          }}
                          checked={this.state.saveLogin == 1}
                        />
                      }
                    />
                  </div>
                </div>
              )}

              {this.state.viewMode == 2 && (
                <div>
                  {this.state.qrCodeUrl != "" &&
                    this.state.qrCodeUrl != null &&
                    this.state.qrCodeUrl != undefined && (
                      <div className="qr-code-img">
                        <img src={this.state.qrCodeUrl} />
                      </div>
                    )}
                  <AvField
                    type="text"
                    name="qrCode"
                    label={Strings["Qr Code"]}
                    placeholder={Strings["Enter Qr code"]}
                    value={this.state.qrCode}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                </div>
              )}

              <button
                type="submit"
                className="btn gradient-button-view gradient-button ml-1"
              >
                {this.state.viewMode == 1 && Strings["Login"]}
                {this.state.viewMode == 2 && Strings["Confirm"]}
              </button>

              <div>
                {this.state.loginError && (
                  <div className="error-text-black">
                    <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                    {this.state.loginError}
                  </div>
                )}
                {this.state.qrCodeError && (
                  <div className="error-text-black">
                    <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                    {this.state.qrCodeError}
                  </div>
                )}
              </div>
            </AvForm>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
