import React, { Component } from "react";
import { Row, Col, CustomInput, Progress } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { translations } from "../../../../translate.js";
import InfoIcon from "@material-ui/icons/Info";
import { atelierEstuaryDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

class CardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardClassTypeList: [],
      submitError: "",
      distId: this.props.distId ? this.props.distId : "",
      parentCardInfo: this.props.parentCardInfo,
      activeStatus:
        this.props.status == "add"
          ? 1
          : this.props.cardsCalssData["activeStatus"],
      cardClassId:
        this.props.status == "add" ? "" : this.props.cardsCalssData["id"],
      name: this.props.status == "add" ? "" : this.props.cardsCalssData["name"],
      name_en:
        this.props.status == "add" ? "" : this.props.cardsCalssData["name_en"],
      valueUSD:
        this.props.status == "add" ? "" : this.props.cardsCalssData["valueUSD"],
      value:
        this.props.status == "add" ? "" : this.props.cardsCalssData["value"],
      costValue:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["costValueCurr"],
      chargeFromApiDirect:
        this.props.status == "add" ? "0" : this.props.cardsCalssData["chargeFromApiDirect"],
      codeLengthAll:
        this.props.status == "add"
          ? [""]
          : this.props.cardsCalssData["codeLength"].length == 0
            ? [""]
            : this.props.cardsCalssData["codeLength"],
      // codeLength: (this.props.status == 'add') ? '' : this.props.cardsCalssData['codeLength'],
      cardLimit:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["cardLimit"],
      price:
        this.props.status == "add" ? "" : this.props.cardsCalssData["price"],
      sealValue:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["sealValue"],
      type: this.props.status == "add" ? 0 : this.props.cardsCalssData["type"],
      barCodeImg:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["barCodeImg"],
      cardClassImg:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["cardClassImg"],
      ean: this.props.status == "add" ? "" : this.props.cardsCalssData["ean"],
      termianlId:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["termianlId"],
      userName:
        this.props.status == "add" ? "" : this.props.cardsCalssData["userName"],
      password:
        this.props.status == "add" ? "" : this.props.cardsCalssData["password"],
      denominationId:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["denominationId"],
      pinCode:
        this.props.status == "add" ? "" : this.props.cardsCalssData["pinCode"],
      egifterProductId:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["egifterProductId"],
      egifterValue:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["egifterValue"],
      astroCountry:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["astroCountry"],
      astroCardCurrency:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["astroCardCurrency"],

      // nickName: (this.props.status == 'add') ? '' : this.props.cardsCalssData['nickName'],
      nickNameAll:
        this.props.status == "add"
          ? [""]
          : this.props.cardsCalssData["nickName"].length == 0
            ? [""]
            : this.props.cardsCalssData["nickName"],
      previousType:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["previousType"],
      classQtyForDay:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["classQtyForDay"],
      inventoryCardLimit:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["inventoryCardLimit"],
      maxPaymentValue:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["maxPaymentValue"],
      countCardRequest:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["countCardRequest"],
      catIsDirectCharge:
        this.props.catData == undefined ||
          this.props.catData.isDirectCharge == undefined
          ? ""
          : this.props.catData["isDirectCharge"],
      deleteImage1: 0,
      deleteImage2: 0,

      barCodeImgUrl:
        this.props.status == "add"
          ? ""
          : this.props.cardsCalssData["barCodeImgUrl"],
      imageUrl:
        this.props.status == "add" ? "" : this.props.cardsCalssData["imageUrl"],
      cardClassImg:
        this.props.status == "add" ? "" : this.props.cardsCalssData["image"],
      image1: 0,
      image2: 0,
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.cardsCalssData);
    // console.log(this.props.catData);
    this.getCardClassTypes();
  }

  getCardClassTypes() {
    fetch(window.API_PERM_USER, {
      method: "POST",
      body: JSON.stringify({
        type: "getCardClassTypes",
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        distId: this.state.distId,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else {
            this.setState({ cardClassTypeList: data });
          }
        }
      });
  }

  setUpSelectOptions = (data, type) => {
    if (type == "cardClassType") {
      return (
        <option value={data.apiKey} key={data.id}>
          {data.name}
        </option>
      );
    }
  };

  handleValidSubmit(event) {
    event.preventDefault();
    var data = new FormData();
    var cardClassImg =
      document.querySelector("input[name=cardClassImg]") != null
        ? document.querySelector("input[name=cardClassImg]").files[0]
        : "";
    var barCodeImg =
      document.querySelector("input[name=barCodeImg]") != null
        ? document.querySelector("input[name=barCodeImg]").files[0]
        : "";

    var deletedImages = 0;
    if (this.state.deleteImage1 == 1) {
      deletedImages = "1";
    } else if (this.state.deleteImage2 == 1) {
      deletedImages = "2";
    } else if (this.state.deleteImage1 == 1 && this.state.deleteImage2 == 1) {
      deletedImages = "all";
    }
    // console.log(cardClassImg);
    // console.log(barCodeImg);

    var uploadedImages = "";
    if (this.state.image1 == 1 && this.state.image2 == 1) {
      uploadedImages = "all";
    } else if (this.state.image1 == 1) {
      uploadedImages = "1";
    } else if (this.state.image2 == 1) {
      uploadedImages = "2";
    }

    data.append("data[]", cardClassImg);
    data.append("data[]", barCodeImg);
    data.append("type", "addCardClass");
    data.append("userId", localStorage.getItem("USER_ID"));
    data.append("token", localStorage.getItem("USER_TOKEN"));
    data.append("lang", localStorage.getItem("LANG"));
    data.append("distId", this.state.distId);

    data.append("cardId", this.state.parentCardInfo.cardId);
    data.append("name", this.state.name);
    data.append("chargeFromApiDirect", this.state.chargeFromApiDirect);

    data.append("name_en", this.state.name_en);
    data.append("activeStatus", this.state.activeStatus);
    data.append("cardClassId", this.state.cardClassId);
    data.append("cardCurrency", this.state.parentCardInfo.currency);
    data.append("valueUSD", this.state.valueUSD);
    data.append("value", this.state.value);
    data.append("costValue", this.state.costValue);
    data.append("codeLength", JSON.stringify(this.state.codeLengthAll));
    // data.append("codeLength", this.state.codeLength);
    data.append("cardLimit", this.state.cardLimit);
    data.append("price", this.state.price);
    data.append("sealValue", this.state.sealValue);
    data.append("cardClassType", this.state.type);
    data.append("ean", this.state.ean);
    data.append("termianlId", this.state.termianlId);
    data.append("userName", this.state.userName);
    data.append("password", this.state.password);
    data.append("denominationId", this.state.denominationId);
    data.append("pinCode", this.state.pinCode);
    data.append("egifterProductId", this.state.egifterProductId);
    data.append("egifterValue", this.state.egifterValue);
    data.append("astroCountry", this.state.astroCountry);
    data.append("astroCardCurrency", this.state.astroCardCurrency);
    data.append("uploadedImages", uploadedImages);
    data.append("deletedImages", deletedImages);
    data.append("previousType", this.state.previousType);
    if (
      localStorage.getItem("USER_LEVEL") == 1 ||
      localStorage.getItem("INV_MGR") == 1
    ) {
      data.append("nickName", JSON.stringify(this.state.nickNameAll));
      // data.append("nickName", this.state.nickName);
      data.append("classQtyForDay", this.state.classQtyForDay);
      data.append("inventoryCardLimit", this.state.inventoryCardLimit);
      data.append("countCardRequest", this.state.countCardRequest);
      data.append("maxPaymentValue", this.state.maxPaymentValue);
    }

    fetch(window.API_PERM_USER, {
      // mode: 'no-cors',
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then(function (response) {
        if (!response.ok) {
          // console.log(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          // console.log('===============================');
          // console.log(data);

          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] == 1) {
            this.setState({ submitError: data["errorMsg"] });
          } else {
            this.props.handleFormAction();
          }
        }
      });
  }
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      submitError: "",
    });
  };

  handleImgChange = (event, type) => {
    if (type == 1) {
      var fileReader = new FileReader();
      const scope = this;

      if (event.target.files[0].type.includes("image")) {
        this.setState({ displayErrorHeaderMsg: false });
        this.setState({ progressUploadHeader: 10 });
        fileReader.readAsBinaryString(event.target.files[0]);
        fileReader.onprogress = function (data) {
          if (data.lengthComputable) {
            var progress = parseInt((data.loaded / data.total) * 100, 10);
            scope.setState({ progressUploadHeader: progress });
          }
        };
      } else {
        this.setState({ displayErrorHeaderMsg: true });
      }

      this.setState({
        image1: 1,
      });
    } else if (type == 2) {
      var fileReader = new FileReader();
      const scope = this;

      if (event.target.files[0].type.includes("image")) {
        this.setState({ displayErrorHeaderMsg_2: false });
        this.setState({ progressUploadHeader_2: 10 });
        fileReader.readAsBinaryString(event.target.files[0]);
        fileReader.onprogress = function (data) {
          if (data.lengthComputable) {
            var progress = parseInt((data.loaded / data.total) * 100, 10);
            scope.setState({ progressUploadHeader_2: progress });
          }
        };
      } else {
        this.setState({ displayErrorHeaderMsg_2: true });
      }

      this.setState({
        image2: 1,
      });
    }
  };
  deleteUploadImage = (num) => {
    this.setState({ ["deleteImage" + num]: 1 });
  };
  handleInputChangeDynamic = (event, index, type = "nickNameAll") => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    var list =
      type == "nickNameAll" ? this.state.nickNameAll : this.state.codeLengthAll;
    list[index] = value;
    // console.log();
    this.setState({
      [type]: list,
    });
  };
  deleteField = (index, type = "nickNameAll") => {
    var list = [];
    if (type == "codeLengthAll") {
      list = this.state.codeLengthAll;
      if (index != -1) {
        list.splice(index, 1);
      }
    } else {
      list = this.state.nickNameAll;
      if (index != -1) {
        list.splice(index, 1);
      }
    }
    this.setState({
      [type]: list,
    });
  };
  addMoreFields = (type = "nickNameAll") => {
    var list =
      type == "nickNameAll" ? this.state.nickNameAll : this.state.codeLengthAll;
    list.push("");
    this.setState({
      [type]: list,
    });
  };
  buildNickNames() {
    var feilds = [];
    var nickName = "";
    if (this.state.nickNameAll.length > 0) {
      this.state.nickNameAll.map((rowData, indexData) => {
        nickName = "nickName" + indexData;
        feilds.push(
          <Row key={indexData}>
            {indexData > 0 ? (
              <>
                <Col lg={11} md={11} sm={11} xs={12} className="mt-2">
                  <AvField
                    name={nickName}
                    placeholder={Strings["Enter Alternative Name"]}
                    value={rowData}
                    onChange={(event) =>
                      this.handleInputChangeDynamic(
                        event,
                        indexData,
                        "nickNameAll"
                      )
                    }
                  />
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="text-center mt-3"
                  title={Strings["Delete"]}
                >
                  <HighlightOffIcon
                    className="delete-color gray"
                    size={"x-large"}
                    onClick={() => this.deleteField(indexData)}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col lg={11} md={11} sm={11} xs={12}>
                  <AvField
                    name={nickName}
                    label={Strings["Alternative Names"]}
                    placeholder={Strings["Enter Alternative Name"]}
                    value={rowData}
                    onChange={(event) =>
                      this.handleInputChangeDynamic(
                        event,
                        indexData,
                        "nickNameAll"
                      )
                    }
                  />
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="text-center p-t-30px"
                  title={Strings["Add"]}
                >
                  <AddCircleOutlineIcon
                    className="main-color-1 gray"
                    size={"x-large"}
                    onClick={() => this.addMoreFields()}
                  />
                </Col>
              </>
            )}
          </Row>
        );
      });
    }
    return feilds;
  }
  buildCodeLength() {
    var feilds = [];
    var codeLength = "";
    if (this.state.codeLengthAll.length > 0) {
      this.state.codeLengthAll.map((rowData, indexData) => {
        codeLength = "codeLength" + indexData;
        feilds.push(
          <Row key={indexData}>
            {indexData > 0 ? (
              <>
                <Col lg={11} md={11} sm={11} xs={12} className="mt-2">
                  <AvField
                    name={codeLength}
                    type="number"
                    min={0.1}
                    placeholder={Strings["Enter Number Of Codes"]}
                    value={rowData}
                    required
                    errorMessage={Strings["This field is required"]}
                    onChange={(event) =>
                      this.handleInputChangeDynamic(
                        event,
                        indexData,
                        "codeLengthAll"
                      )
                    }
                  />
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="text-center mt-3"
                  title={Strings["Delete"]}
                >
                  <HighlightOffIcon
                    className="delete-color gray"
                    size={"x-large"}
                    onClick={() => this.deleteField(indexData, "codeLengthAll")}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col lg={11} md={11} sm={11} xs={12}>
                  <AvField
                    name={codeLength}
                    type="number"
                    min={0.1}
                    label={Strings["Number Of Codes"] + " *"}
                    placeholder={Strings["Enter Number Of Codes"]}
                    value={rowData}
                    required
                    errorMessage={Strings["This field is required"]}
                    onChange={(event) =>
                      this.handleInputChangeDynamic(
                        event,
                        indexData,
                        "codeLengthAll"
                      )
                    }
                  />
                </Col>
                <Col
                  lg={1}
                  md={1}
                  sm={1}
                  xs={12}
                  className="text-center p-t-30px"
                  title={Strings["Add"]}
                >
                  <AddCircleOutlineIcon
                    className="main-color-1 gray"
                    size={"x-large"}
                    onClick={() => this.addMoreFields("codeLengthAll")}
                  />
                </Col>
              </>
            )}
          </Row>
        );
      });
    }
    return feilds;
  }
  render() {
    var apiKey = "";
    if (
      this.state.cardClassTypeList != undefined &&
      this.state.cardClassTypeList.length > 0 &&
      localStorage.getItem("USER_LEVEL") != 1 &&
      localStorage.getItem("INV_MGR") != 1
    ) {
      var type = this.state.type;
      var cardClassTypeList = this.state.cardClassTypeList;
      var index = 0;
      if (type != "" && type != null && type != undefined && type != 0) {
        index = cardClassTypeList.findIndex(
          (cardClassTypeList) => cardClassTypeList.id == type
        );

        if (index < 0) {
          index = 0;
        }
        apiKey = cardClassTypeList[index]["apiKey"];
      }
    }

    return (
      <div className="view-modal-style">
        <div className="sidebar-header-view">
          <Row>
            <Col xs={10} lg={10} md={10} sm={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {this.props.status == "add"
                  ? Strings["Add"]
                  : Strings["Edit"]}{" "}
                {Strings["Class"]}
              </div>
            </Col>
            <Col md={2} xs={2} lg={2} sm={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.handleCloseSideBar}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>

        <div className="sidebar-body-view">
          <AvForm
            onValidSubmit={this.handleValidSubmit}
            ref={(c) => (this.form = c)}
          >
            <AvField
              name="name"
              label={Strings["Class Name"] + " *"}
              placeholder={Strings["Enter Class Name"]}
              value={this.state.name}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            <AvField
              name="name_en"
              label={Strings["Class Name In English"] + " *"}
              placeholder={Strings["Enter Class Name In English"]}
              value={this.state.name_en}
              required
              onChange={this.handleInputChange}
              errorMessage={Strings["This field is required"]}
            />
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) && (
                <div className="view-card-class-form mb-4">
                  <div className="inter-Calls">
                    {this.buildNickNames()}
                    {/* <Row>
                                    <Col lg={11} md={11} sm={11} xs={12}>
                                        <AvField name="nickName" label={Strings['Alternative Name']} placeholder={Strings['Enter Alternative Name']} value={this.state.nickName} onChange={this.handleInputChange} />
                                    </Col>
                                    <Col lg={1} md={1} sm={1} xs={12} className="text-center p-t-30px" title={Strings['Add']}>
                                        <AddCircleOutlineIcon className="main-color-1 hover" size={'x-large'} />
                                    </Col>
                                </Row> */}
                  </div>
                  {/* // } */}
                  {/* {(localStorage.getItem('USER_LEVEL') == 1 || localStorage.getItem('INV_MGR') == 1) && */}
                  <div className="main-color-1 fs-12   mt-1">
                    <InfoIcon className="mr-1" />{" "}
                    {
                      Strings[
                      "They are used to raise cards to the inventory from more than one class"
                      ]
                    }
                  </div>
                </div>
              )}
            {this.state.parentCardInfo.getCardType != 3 &&
              this.state.parentCardInfo.getCardType != 4 && (
                <div>
                  <AvField
                    name="valueUSD"
                    type="number"
                    min={0.1}
                    label={Strings["Card Value"] + " *"}
                    placeholder={Strings["Enter Card Value"]}
                    value={this.state.valueUSD}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <AvField
                    name="value"
                    type="number"
                    min={0.1}
                    label={Strings["Card Class Value"] + " *"}
                    placeholder={Strings["Enter Card Class Value"]}
                    value={this.state.value}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  <AvField
                    name="costValue"
                    type="number"
                    min={0.1}
                    label={Strings["Cost Value"] + " *"}
                    placeholder={Strings["Enter Cost Value"]}
                    value={this.state.costValue}
                    required
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />
                  {/* <AvField name="codeLength" type='number' min={0.1} label={Strings['Number Of Codes'] + ' *'} placeholder={Strings['Enter Number Of Codes']} value={this.state.codeLength} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} /> */}
                  <div className="view-card-class-form inter-Calls mb-4">
                    {this.buildCodeLength()}
                  </div>
                  <AvField
                    name="cardLimit"
                    type="number"
                    min={0}
                    label={Strings["Minimum Number Of Cards"]}
                    placeholder={Strings["Enter Minimum Number Of Cards"]}
                    value={this.state.cardLimit}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />

                  {this.state.parentCardInfo.sellingPrice == 1 && (
                    <AvField
                      name="price"
                      type="number"
                      min={0}
                      label={Strings["Price"]}
                      placeholder={Strings["Enter Price"]}
                      value={this.state.price}
                      onChange={this.handleInputChange}
                      errorMessage={Strings["This field is required"]}
                    />
                  )}

                  <AvField
                    name="sealValue"
                    type="number"
                    min={0}
                    label={Strings["Selling Price"]}
                    placeholder={Strings["Enter Selling Price"]}
                    value={this.state.sealValue}
                    onChange={this.handleInputChange}
                    errorMessage={Strings["This field is required"]}
                  />

                  {this.state.parentCardInfo.getCardType == 5 && (
                    <AvField
                      name="type"
                      label={Strings["Type"]}
                      placeholder={Strings["Enter Type"]}
                      value={this.state.type}
                      onChange={this.handleInputChange}
                      errorMessage={Strings["This field is required"]}
                    />
                  )}
                  {/* {(localStorage.getItem('USER_LEVEL') != 1 && localStorage.getItem('INV_MGR') != 1 && this.state.parentCardInfo.getCardType != 5) &&
                                    <AvField type="select" name="type" label={Strings['Type'] + ' *'} value={this.state.type} onChange={this.handleInputChange} className="p-1 pl-3" errorMessage={Strings['This field is required']} >
                                        <option value="0" selected="selected">{Strings['Select type']}</option>
                                        {this.state.cardClassTypeList.map((data) => this.setUpSelectOptions(data, 'cardClassType'))}
                                    </AvField>
                                } */}

                  {/* {((apiKey == 1) || (apiKey == 10)) &&
                                    <div>
                                        <AvField name="ean" label={'EAN *'} placeholder={'Enter EAN'} value={this.state.ean} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                        <AvField name="termianlId" label={'Terminal ID *'} placeholder={'Enter Terminal ID'} value={this.state.termianlId} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                        <AvField name="userName" label={Strings['User Name']} placeholder={Strings['Enter User Name']} value={this.state.userName} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                        <AvField name="password" type='password' label={Strings['Password']} placeholder={Strings['Enter password']} value={this.state.userName} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                    </div>
                                } */}

                  {/* {apiKey == 2 &&
                                    <div>
                                        <AvField name="denominationId" type='number' label={'Denomination Id *'} placeholder={'Enter Denomination Id'} value={this.state.denominationId} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                        <AvField name="pinCode" label={'Pin Code *'} placeholder={'Enter Pin Code'} value={this.state.pinCode} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                        <AvField name="userName" label={Strings['User Name']} placeholder={Strings['Enter User Name']} value={this.state.userName} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                        <AvField name="password" type='password' label={Strings['Password']} placeholder={Strings['Enter password']} value={this.state.userName} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                    </div>
                                } */}

                  {/* {apiKey == 3 &&
                                    <div>
                                        <AvField name="egifterProductId" label={Strings['Product Id'] + ' *'} placeholder={Strings['Enter Product Id']} value={this.state.egifterProductId} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                        <AvField name="egifterValue" label={Strings['Value'] + ' *'} placeholder={Strings['Enter Value']} value={this.state.egifterValue} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                    </div>
                                } */}

                  {/* {((apiKey == 4) || (apiKey == 6) || (apiKey == 8) || (apiKey == 9) || (apiKey == 11)) &&
                                    <div>
                                        <AvField name="egifterProductId" label={Strings['Product Id'] + ' *'} placeholder={Strings['Enter Product Id']} value={this.state.egifterProductId} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                    </div>
                                } */}

                  {/* {apiKey == 5 &&
                                    <div>
                                        <AvField name="astroCountry" label={Strings['Country Code'] + ' *'} placeholder={Strings['Enter Country Code']} value={this.state.astroCountry} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                        <AvField name="astroCardCurrency" label={Strings['Card Currency'] + ' *'} placeholder={Strings['Enter Card Currency']} value={this.state.astroCardCurrency} required onChange={this.handleInputChange} errorMessage={Strings['This field is required']} />
                                    </div>
                                } */}
                </div>
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) && (
                <div className="inter-Calls">
                  <AvField
                    name="inventoryCardLimit"
                    label={Strings["Minimum Stock"]}
                    placeholder={Strings["Enter Minimum Stock"]}
                    value={this.state.inventoryCardLimit}
                    onChange={this.handleInputChange}
                  />
                </div>
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) && (
                <div className="main-color-1 fs-12  mb-4 mt-1">
                  <InfoIcon className="mr-1" />{" "}
                  {
                    Strings[
                    "When the stock reaches this number, it will be filled from the buffer"
                    ]
                  }
                </div>
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) && (
                <div className="inter-Calls">
                  <AvField
                    name="countCardRequest"
                    label={Strings["Number Of Cards Requested From Supplier"]}
                    placeholder={
                      Strings["Enter Number Of Cards Requested From Supplier"]
                    }
                    value={this.state.countCardRequest}
                    onChange={this.handleInputChange}
                  />
                </div>
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) && (
                <div className="main-color-1 fs-12  mb-4 mt-1">
                  <InfoIcon className="mr-1" />{" "}
                  {
                    Strings[
                    "The quantity will be requested from all API suppliers via Buffer"
                    ]
                  }
                </div>
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) &&
              this.state.catIsDirectCharge == 8 && (
                <div className="inter-Calls">
                  <AvField
                    name="maxPaymentValue"
                    label={Strings["Maximum Amount Of Payment"]}
                    placeholder={Strings["Enter Maximum Amount Of Payment"]}
                    value={this.state.maxPaymentValue}
                    onChange={this.handleInputChange}
                  />
                </div>
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) &&
              this.state.catIsDirectCharge == 8 && (
                <div className="main-color-1 fs-12  mb-4 mt-1">
                  <InfoIcon className="mr-1" />{" "}
                  {
                    Strings[
                    "The maximum value that a POS can request through this category"
                    ]
                  }
                </div>
              )}
            {(localStorage.getItem("USER_LEVEL") == 1 ||
              localStorage.getItem("INV_MGR") == 1) && (
                <AvField
                  name="classQtyForDay"
                  label={Strings["Quantity Per Day"]}
                  placeholder={Strings["Enter Quantity Per Day"]}
                  value={this.state.classQtyForDay}
                  onChange={this.handleInputChange}
                />
              )}

            {this.state.parentCardInfo.getCardType != 3 &&
              this.state.parentCardInfo.getCardType != 4 && (
                <div className="mt-1 mb-2">
                  <label className="font-w-400">{Strings["Class Icon"]}</label>
                  <span className={"fileInput-span col-md-12"}>
                    <PublishIcon />{" "}
                    <span className="pointer-cursor pt-3">
                      {Strings["Upload"]}
                    </span>
                    <input
                      type="file"
                      accept="image/*"
                      className="fileInput-width fileInput-opacity"
                      onChange={(e) => this.handleImgChange(e, 1)}
                      name="cardClassImg"
                    ></input>
                  </span>
                  {this.state.progressUploadHeader != undefined &&
                    this.state.progressUploadHeader > 0 && (
                      <Progress
                        striped
                        value={this.state.progressUploadHeader}
                        className="mb-1 mt-1 progress-bg-color"
                      >
                        {this.state.progressUploadHeader} %
                      </Progress>
                    )}
                  {this.state.displayErrorHeaderMsg == true && (
                    <div className="invalid-feedback d-block mb-1">
                      {Strings["Upload Image only"]}
                    </div>
                  )}

                  {this.state.imageUrl != null &&
                    this.state.imageUrl != undefined &&
                    this.state.imageUrl != "" &&
                    this.state.cardClassImg != null &&
                    this.state.cardClassImg != undefined &&
                    this.state.cardClassImg != "" &&
                    this.state.deleteImage1 == 0 && (
                      <div className="text-center mt-4">
                        <img
                          src={this.state.imageUrl}
                          className="img-fluid inv-category-img"
                        />
                        <a
                          href={this.state.imageUrl}
                          download
                          className="inv-category-img-title overflow-text-length"
                          target="_blank"
                        >
                          {this.state.cardClassImg}
                        </a>
                        <div
                          className="task-action-file-details-delete-icon"
                          onClick={() => this.deleteUploadImage("1")}
                        >
                          <span>
                            <DeleteIcon /> {Strings["Delete"]}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              )}
            {this.state.parentCardInfo.getCardType != 3 &&
              this.state.parentCardInfo.getCardType != 4 && (
                <div className="mt-3 mb-2">
                  <label className="font-w-400">{Strings["Barcode"]}</label>
                  <span className={"fileInput-span col-md-12"}>
                    <PublishIcon />{" "}
                    <span className="pointer-cursor pt-3">
                      {Strings["Upload"]}
                    </span>
                    <input
                      type="file"
                      accept="image/*"
                      className="fileInput-width fileInput-opacity"
                      onChange={(e) => this.handleImgChange(e, 2)}
                      name="barCodeImg"
                    ></input>
                  </span>
                  {this.state.progressUploadHeader_2 != undefined &&
                    this.state.progressUploadHeader_2 > 0 && (
                      <Progress
                        striped
                        value={this.state.progressUploadHeader_2}
                        className="mb-1 mt-1 progress-bg-color"
                      >
                        {this.state.progressUploadHeader_2} %
                      </Progress>
                    )}
                  {this.state.displayErrorHeaderMsg_2 == true && (
                    <div className="invalid-feedback d-block mb-1">
                      {Strings["Upload Image only"]}
                    </div>
                  )}

                  {this.state.barCodeImgUrl != null &&
                    this.state.barCodeImgUrl != undefined &&
                    this.state.barCodeImgUrl != "" &&
                    this.state.barCodeImg != null &&
                    this.state.barCodeImg != undefined &&
                    this.state.barCodeImg != "" &&
                    this.state.deleteImage2 == 0 && (
                      <div className="text-center mt-4">
                        <img
                          src={this.state.barCodeImgUrl}
                          className="img-fluid inv-category-img"
                        />
                        <a
                          href={this.state.barCodeImgUrl}
                          download
                          className="inv-category-img-title overflow-text-length"
                          target="_blank"
                        >
                          {this.state.barCodeImg}
                        </a>
                        <div
                          className="task-action-file-details-delete-icon"
                          onClick={() => this.deleteUploadImage("2")}
                        >
                          <span>
                            <DeleteIcon /> {Strings["Delete"]}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              )}
            {/* ##########  */}
            {this.state.parentCardInfo.getCardType != 3 &&
              this.state.parentCardInfo.getCardType != 4 && (
                < div className="main-color-1 fs-12 inv-card-class-img-note mt-4">


                  <div className="form-group mb-0">
                    <FormControlLabel
                      label={
                        <>
                          <label className="font-w-400">
                            {Strings["Charge from Api direct"]}
                          </label>
                        </>
                      }
                      control={
                        <Checkbox
                          color="primary"
                          name="chargeFromApiDirect"
                          // onBlur={handleBlur}
                          onChange={() => {
                            this.setState({
                              chargeFromApiDirect: this.state.chargeFromApiDirect == 1 ? 0 : 1,
                            });

                          }}
                          checked={this.state.chargeFromApiDirect == 1}
                        />
                      }
                    />
                  </div>
                  <InfoIcon className="mr-1" />{" "}
                  {
                    Strings[
                    "Make sure that the image width is not more than 200"
                    ]
                  }
                </div>
              )}

            <div className="text-right mt-4">
              <button type="submit" className="btn form-save-btn mr-1 ml-1">
                {" "}
                {Strings["Save"]}{" "}
              </button>
              <button
                type="button"
                className="btn form-cancel-btn m-l-10"
                onClick={this.props.handleCloseSideBar}
              >
                {Strings["Cancel"]}
              </button>
            </div>

            {this.state.submitError && (
              <div className="error-text-black">
                <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                {this.state.submitError}
              </div>
            )}
          </AvForm>
        </div >
      </div >
    );
  }
}
export default CardForm;
